import { MdOutlinePersonalInjury } from 'react-icons/md';
import { Green3 } from '../../base/colors';
import { StatePanel } from '../../components/dashboards/statePanel';
import { useMainStore } from '../../stores/mainStore';

export const ActivePatients = () => {
  const activePatients = useMainStore((state) => state.activePatients);
  return (
    <StatePanel
      color={Green3}
      value={activePatients}
      icon={'/icones/HospitalBed.png'}
      label={['Pacientes ativos']}
    ></StatePanel>
  );
};
