import { doDelete, doGet, doPost } from './httpHelper';

export const getPatients = async (
  cnpj,
  filter,
  page,
  pageSize,
  status,
  cache = false
) => {
  try {
    const resp = await doGet(
      `/getPatients?page=${page ?? 0}&pageSize=${pageSize ?? 10}${
        status ? '&status=' + status : ''
      }${
        filter !== null && filter !== undefined && filter !== ''
          ? '&filter=' + filter
          : ''
      }`,
      {
        cache: cache,
      }
    );
    if (resp.httpStatus !== 200) {
      //handle error
      console.error(resp.error);
      return {
        error: resp.error,
        totalItems: 0,
        data: [],
      };
    }
    resp.body.data = resp.body.data.map((x) => ({ ...x, equipments: [] }));
    return resp.body;
  } catch (e) {
    return {
      error: e.toString(),
      totalItems: 0,
      data: [],
    };
  }
};

export const getReport = async (cpf, page, pageSize, startDate, endDate) => {
  let url = `/manageReport?`;
  const params = [];
  if (cpf && cpf !== '') {
    params.push('cpf=' + cpf);
  }
  if (startDate && startDate !== '') {
    params.push('startDate=' + startDate);
  }
  if (endDate && endDate !== '') {
    params.push('endDate=' + endDate);
  }
  if (page && page !== '') {
    params.push('page=' + page);
  }
  if (pageSize && pageSize !== '') {
    params.push('pageSize=' + pageSize);
  }
  url = url + params.join('&');
  const resp = await doGet(url);
  return resp;
};

export const savePatient = async (patient) => {
  const resp = await doPost('/patient', patient);
  return resp;
};

export const deactivatePatient = async (cpf) => {
  const resp = await doPost(`/deactivatePatient?cpf=${cpf}`);
  return resp;
};

export const getBranch = async (city) => {
  const resp = await doGet(`/branchFromCity?city=${city}`, {
    cache: true,
    expireInSeconds: 3600,
  });
  return resp.body;
};

export const getPatientByCPF = async (cnpj, cpf) => {
  //TODO voltar com o Ativo
  const resp = await getPatients(null, cpf, '0', 1, 'Ativo');
  return resp.data[0];
};

export const getCEP = async (cep) => {
  const resp = await doGet(`/cep?cep=${cep}`, {
    cache: true,
    expireInSeconds: 7200,
  });
  return resp;
};
