import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { PrimaryTitleGray } from '../../base/colors';
import { InputText } from '../../base/Inputs';
import { Spinner } from '../../base/Spinners';
import { WideLayout } from '../../components/layout/wideLayout';
import { queryProducts } from '../../services/productService';
import { usePortalStore } from '../../store';
import { useOrderFlowStore } from '../../stores/newOrderFlowStore';
import { SubCategoryCard } from './subCategoryCard';
/**
 *
 * @returns
 */

export const OrderSelectSubCategory = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const currentUser = usePortalStore((state) => state.currentUser);
  const [subCategories, setSubCategories] = useState([]);
  const [searchText, setSearchText] = useState('');
  const selectedArea = useOrderFlowStore((state) => state.selectedArea);
  const selectedCategory = useOrderFlowStore((state) => state.selectedCategory);

  const selectedSubcategories = useOrderFlowStore(
    (state) => state.selectedSubcategories
  );
  const currentPatientBranch = useOrderFlowStore(
    (state) => state.currentPatientBranch
  );
  useEffect(() => {
    (async () => {
      const products = await queryProducts(
        null,
        null,
        currentPatientBranch.id,
        selectedCategory.id,
        null,
        null,
        'S',
        null,
        searchText,
        0,
        1000
      );
      const subCategoryMap = {};
      products.data
        .filter((x) => x.product_subcategory_id > 0)
        .forEach((product) => {
          if (!subCategoryMap[product.product_subcategory_id]) {
            subCategoryMap[product.product_subcategory_id] = {
              id: product.product_subcategory_id,
              description: product.product_subcategory.description,
              produtos: [],
            };
          }
          subCategoryMap[product.product_subcategory_id].produtos.push(product);
        });
      const arr = Object.values(subCategoryMap);
      setSubCategories([...arr]);
    })();
  }, [selectedCategory, currentPatientBranch, searchText]);
  return (
    <WideLayout
      outlet={
        <div style={{ padding: '10px' }}>
          <h3 style={{ color: PrimaryTitleGray, margin: '10px 15px' }}>
            Selecionar subcategoria
          </h3>
          {/* <div style={{marginLeft:"10px",boxSizing:"border-box",paddingBottom:"10px"}}>
            <InputText placeholder="Buscar por produtos" onChange={(e)=>setSearchText(e.target.value)}></InputText>
        </div> */}
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {subCategories.length === 0 ? (
              <div
                style={{
                  display: 'grid',
                  height: '70vh',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Spinner></Spinner>
              </div>
            ) : (
              subCategories.map((subcategory, idx) => (
                <SubCategoryCard
                  currentUser={currentUser}
                  subcategory={subcategory}
                  key={idx}
                ></SubCategoryCard>
              ))
            )}
          </div>
        </div>
      }
    ></WideLayout>
  );
};
