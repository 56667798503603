import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  GridBox,
  LargeTile,
  MediumTile,
  Row,
  SmallTile,
} from '../components/gridsystem/gridsystem';
import { WideLayout } from '../components/layout/wideLayout';
import { usePortalStore } from '../store';
import { useMainStore } from '../stores/mainStore';
import { ActivePatients } from '../usecase/main/activePatients';
import { AllocatedEquipments } from '../usecase/main/allocatedEquipments';
import { AllocatedEquipmentsChart } from '../usecase/main/allocatedEquipmentsChart';
import { MonthlyConsumedOxigen } from '../usecase/main/monthlyConsumedOxigen';
import { OrdersView } from '../usecase/main/orders';
import { OxygenConsumption } from '../usecase/main/oxygenConsumption';

const MainPanels = () => {
  return (
    <div
      style={{
        display: 'grid',
        rowGap: '20px',
        padding: '10px',
        boxSizing: 'border-box',
      }}
    >
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          gap: '10px',
        }}
      >
        <div style={{ width: '100%' }}>
          <OxygenConsumption></OxygenConsumption>
        </div>
        <div style={{ width: '100%' }}>
          <AllocatedEquipments></AllocatedEquipments>
        </div>
        <div style={{ width: '100%' }}>
          <ActivePatients></ActivePatients>
        </div>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat( auto-fit, minmax(500px, 1fr))',
          gap: '10px',
        }}
      >
        <div>
          <MonthlyConsumedOxigen></MonthlyConsumedOxigen>
        </div>
        <div>
          <AllocatedEquipmentsChart></AllocatedEquipmentsChart>
        </div>
      </div>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
        <div style={{ width: '100%' }}>
          <OrdersView></OrdersView>
        </div>
      </div>
    </div>
  );
};

const MobilePanels = () => {
  return (
    <GridBox>
      <Row>
        <SmallTile margin="10px">
          <OxygenConsumption></OxygenConsumption>
        </SmallTile>
      </Row>
      <Row>
        <SmallTile margin="10px">
          <AllocatedEquipments></AllocatedEquipments>
        </SmallTile>
      </Row>
      <Row>
        <SmallTile margin="10px">
          <ActivePatients></ActivePatients>
        </SmallTile>
      </Row>
      <Row>
        <MediumTile margin="10px">
          <MonthlyConsumedOxigen></MonthlyConsumedOxigen>
        </MediumTile>
      </Row>
      <Row>
        <MediumTile margin="10px">
          <AllocatedEquipmentsChart></AllocatedEquipmentsChart>
        </MediumTile>
      </Row>
      <Row>
        <LargeTile margin="10px">
          <OrdersView></OrdersView>
        </LargeTile>
      </Row>
    </GridBox>
  );
};

export const MainPage = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const currentUser = usePortalStore((state) => state.currentUser);

  const fetchActivePatients = useMainStore(
    (state) => state.fetchActivePatients
  );
  const fetchMonthlyOxigenConsumed = useMainStore(
    (state) => state.fetchMonthlyOxigenConsumed
  );
  const fetchAllocatedEquipmentsChart = useMainStore(
    (state) => state.fetchAllocatedEquipmentsChart
  );
  const fetchRecentOrders = useMainStore((state) => state.fetchRecentOrders);
  const consumedOxygenYearsToFilter = useMainStore(
    (state) => state.consumedOxygenYearsToFilter
  );
  useEffect(() => {
    fetchActivePatients(currentUser.cnpj);
    fetchMonthlyOxigenConsumed(currentUser.cnpj);
    fetchAllocatedEquipmentsChart(currentUser.cnpj);
    fetchRecentOrders();
  }, [consumedOxygenYearsToFilter]);

  return (
    <WideLayout
      outlet={isTabletOrMobile ? MobilePanels() : MainPanels()}
    ></WideLayout>
  );
};
