import { Alarm } from '../types/Alarms';

export class AlarmEnum {
  static enums = {
    color: {
      GREEN: 1,
      YELLOW: 2,
      RED: 3,
      PURPLE: 4,
      WHITE: 5,
      ORANGE: 6,
      FLASHING_RED: 7,
    },
  };

  static color = {
    isGreen: (alarm: Alarm) => {
      return alarm.id === this.enums.color.GREEN;
    },
    isYellow: (alarm: Alarm) => {
      return alarm.id === this.enums.color.YELLOW;
    },
    isRed: (alarm: Alarm) => {
      return alarm.id === this.enums.color.RED;
    },
    isPurple: (alarm: Alarm) => {
      console.log(alarm.id, this.enums.color.PURPLE);
      return alarm.id === this.enums.color.PURPLE;
    },
    isWhite: (alarm: Alarm) => {
      return alarm.id === this.enums.color.WHITE;
    },
    isOrange: (alarm: Alarm) => {
      return alarm.id === this.enums.color.ORANGE;
    },
    isFlashingRed: (alarm: Alarm) => {
      return alarm.id === this.enums.color.FLASHING_RED;
    },
  };
}
