import { BrowserRouter, Route, Routes as RRDRoutes } from 'react-router-dom';

import { EquipmentCollectionPage } from '../pages/collection/collection';
import { FinishCollectionOrder } from '../pages/collection/finishCollectionOrder';
import { CollectionSelectPatientsPage } from '../pages/collection/selectPatient';
import { ConfigurationPage } from '../pages/configuration/configuration';
import { KpiPage } from '../pages/kpi';
import { LoginPage } from '../pages/login';
import { MainPage } from '../pages/main';
import { ConfigureEquipment } from '../pages/newOrderFlow/configureEquipment';
import { FinishOrder } from '../pages/newOrderFlow/finish';
import { OrderSelectArea } from '../pages/newOrderFlow/orderSelectArea';
import { OrderSelectCategory } from '../pages/newOrderFlow/selectCategory';
import { SelectPatient } from '../pages/newOrderFlow/selectPatient';
import { OrderSelectSubCategory } from '../pages/newOrderFlow/selectSubCategory';
import { OrdersPage } from '../pages/orders';
import { PatientsPage } from '../pages/patients';
import { RechargeConfirmationPage } from '../pages/recharge/confirmation';
import { FinishRechargerOrder } from '../pages/recharge/finishRechargerOrder';
import { RechargeSelectPatientsPage } from '../pages/recharge/selectPatient';
import { SharedLinkPage } from '../pages/sharedLink';
import { TechnicalAssistancePage } from '../pages/technicalAssistance/assistance';
import { FinishAssistanceOrder } from '../pages/technicalAssistance/finishAssistanceOrder';
import { TechnicalAssistanceSelectPatientsPage } from '../pages/technicalAssistance/selectPatient';
import { PrivacyPage } from '../pages/privacyTerm';
import { PrivateRoute } from './PrivateRoute';

export const Routes = () => {
  return (
    <BrowserRouter>
      <RRDRoutes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/link" element={<SharedLinkPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/recovery" element={<LoginPage />} />
        <Route element={<PrivateRoute />}>
          <Route path="/order/newOrder" element={<OrderSelectArea />} />
        </Route>

        <Route element={<PrivateRoute />}>
          <Route path="/order/finish" element={<FinishOrder />} />
        </Route>

        <Route element={<PrivateRoute />}>
          <Route path="/configuration" element={<ConfigurationPage />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route
            path="/recharge/finishRecharge"
            element={<FinishRechargerOrder />}
          />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route
            path="/recharge/confirmRecharge"
            element={<RechargeConfirmationPage />}
          />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route
            path="/recharge/rechargePatient"
            element={<RechargeSelectPatientsPage />}
          />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route
            path="/collectEquipments/finishCollectEquipments"
            element={<FinishCollectionOrder />}
          />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route
            path="/collectEquipments/collectEquipments"
            element={<EquipmentCollectionPage />}
          />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route
            path="/collectEquipments/collectFromPatient"
            element={<CollectionSelectPatientsPage />}
          />
        </Route>

        <Route element={<PrivateRoute />}>
          <Route
            path="/technicalAssistance/finishAssistanceOrder"
            element={<FinishAssistanceOrder />}
          />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route
            path="/technicalAssistance/assistance"
            element={<TechnicalAssistancePage />}
          />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route
            path="/technicalAssistance/assistanceToPatient"
            element={<TechnicalAssistanceSelectPatientsPage />}
          />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path="/order/selectPatient" element={<SelectPatient />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route
            path="/order/selectSubCategory"
            element={<OrderSelectSubCategory />}
          />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route
            path="/order/configureEquipment"
            element={<ConfigureEquipment />}
          />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route
            path="/order/selectCategory"
            element={<OrderSelectCategory />}
          />
        </Route>

        <Route element={<PrivateRoute />}>
          <Route path="/privacyTerm" element={<PrivacyPage />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path="/main" element={<MainPage />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path="/patients" element={<PatientsPage />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path="/report" element={<KpiPage />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path="/orders" element={<OrdersPage />} />
        </Route>
      </RRDRoutes>
    </BrowserRouter>
  );
};
