import React from 'react';
import {
  TooltipProps as MUITooltipProps,
  Tooltip as MUITooltip,
  Box,
} from '@mui/material';

export const Tooltip: React.FC<MUITooltipProps> = ({ title, sx, children }) => {
  return (
    <MUITooltip title={title} placement="top" arrow sx={sx}>
      <Box component="span" sx={sx}>
        {children}
      </Box>
    </MUITooltip>
  );
};
