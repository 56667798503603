import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Tab, TabItem } from '../../base/tab';
import { TitledPanel } from '../../components/dashboards/titledPanel/titledPanel';
import { LargeTile } from '../../components/gridsystem/gridsystem';
import { WideLayout } from '../../components/layout/wideLayout';
import { Panel } from '../../components/panels/panel';
import { usePortalStore } from '../../store';
import { UserMngtConfig } from './userMngtConfig';

const MainPanels = () => {
  const [currentConfigPage, setCurrentConfigPage] = useState(0);
  const configPages = [];
  configPages[0] = <UserMngtConfig />;
  return (
    <LargeTile margin={'15px'}>
      <h3>Configurações</h3>
      <Panel>
        <Tab>
          <TabItem>Gerenciamento de Usuários</TabItem>
        </Tab>
        <div style={{ padding: '10px' }}>{configPages[currentConfigPage]}</div>
      </Panel>
    </LargeTile>
  );
};
export const ConfigurationPage = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const currentUser = usePortalStore((state) => state.currentUser);
  return (
    <WideLayout
      outlet={
        !currentUser.isAdmin ? (
          <></>
        ) : isTabletOrMobile ? (
          MainPanels()
        ) : (
          MainPanels()
        )
      }
    ></WideLayout>
  );
};
