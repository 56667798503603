import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { JumboButton } from '../../base/Inputs';
import { InputText, PrimaryButton, SecondaryButton } from '../../base/Inputs';
import { WideLayout } from '../../components/layout/wideLayout';
import { usePortalStore } from '../../store';
import { useCollectionStore } from '../../stores/collectionStore';
import { usePatientsStore } from '../../stores/patientsStore';
import { PatientsList } from '../../usecase/patients/list';
import { unformatCPF } from '../../utils/utils';

const MainPanels = () => {
  const setSelectedPatient = useCollectionStore(
    (state) => state.setSelectedPatient
  );
  const selectedPatient = useCollectionStore((state) => state.selectedPatient);
  const reset = useCollectionStore((state) => state.reset);
  const patients = usePatientsStore((state) => state.patients);
  const header = ['Nome', 'CPF', 'Empresa', 'CNPJ', 'Endereço'];
  const fields = ['name', 'cpf', 'company', 'cnpj', 'address'];
  const navigate = useNavigate();

  useEffect(() => {
    reset();
  }, []);

  const onRowSelected = (patient) => {
    const p = patients.filter(
      (x) => unformatCPF(x.cpf) === unformatCPF(patient[1])
    )[0];
    setSelectedPatient(p);
  };
  const continueToCategory = () => {
    if (selectedPatient !== null) {
      navigate('/collectEquipments/collectEquipments');
    }
  };
  return (
    <>
      <PatientsList
        title={'Novo pedido'}
        onlyActives={true}
        newClientAction={
          <PrimaryButton onClick={continueToCategory}>Prosseguir</PrimaryButton>
        }
        height={`${window.innerHeight - 450}px`}
        subtitle={''}
        readOnly={true}
        header={header}
        fields={fields}
        onRowSelected={onRowSelected}
      ></PatientsList>
    </>
  );
};

export const CollectionSelectPatientsPage = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const currentUser = usePortalStore((state) => state.currentUser);

  return (
    <WideLayout
      outlet={isTabletOrMobile ? MainPanels() : MainPanels()}
    ></WideLayout>
  );
};
