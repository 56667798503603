import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from '../../base/Inputs';
import { WideLayout } from '../../components/layout/wideLayout';
import { getBranch } from '../../services/patientsService';
import {
  getCategories,
  getProductPriority,
} from '../../services/productService';
import { usePortalStore } from '../../store';
import { useOrderFlowStore } from '../../stores/newOrderFlowStore';
import { usePatientsStore } from '../../stores/patientsStore';
import { PatientsList } from '../../usecase/patients/list';

const Outlet = ({ onRowSelection, continueToCategory, blockingLoad }) => {
  return (
    <PatientsList
      onlyActives={true}
      title={'Novo pedido'}
      newClientAction={
        <PrimaryButton
          disabled={blockingLoad}
          style={{ width: 'auto', padding: '0 30px' }}
          onClick={continueToCategory}
        >
          {blockingLoad > 0
            ? blockingLoad === 1
              ? 'Carregando'
              : 'Continuar para categoria'
            : 'Continuar para categoria'}
        </PrimaryButton>
      }
      height={window.innerHeight - 400 + 'px'}
      subtitle={' '}
      readOnly={true}
      header={['Nome', 'CPF', 'Empresa', 'CNPJ', 'Endereço']}
      fields={['name', 'cpf', 'company', 'cnpj', 'address']}
      onRowSelected={onRowSelection}
    ></PatientsList>
  );
};
export const SelectPatient = () => {
  const [blockingLoad, setBlockingLoad] = useState(2);
  const patients = usePatientsStore((state) => state.patients);
  const [currPatient, setCurrPatient] = useState({});
  const setSelectedPatient = useOrderFlowStore(
    (state) => state.setSelectedPatient
  );
  const setPatientBranch = useOrderFlowStore((state) => state.setPatientBranch);
  const setProductPriority = useOrderFlowStore(
    (state) => state.setProductPriority
  );
  const setAvailableCategories = useOrderFlowStore(
    (state) => state.setAvailableCategories
  );
  const setCurrentAlertError = usePortalStore(
    (state) => state.setCurrentAlertError
  );
  const navigate = useNavigate();

  const onRowSelection = (row, idx) => {
    setCurrPatient(patients[idx]);
    setBlockingLoad(0);
  };

  const continueToCategory = async () => {
    if (blockingLoad > 0) {
      return;
    }
    try {
      setBlockingLoad(1);
      const promises = await Promise.all([
        getCategories(null, 0, 1000),
        getBranch(currPatient.city),
      ]);
      const categories = promises[0].data;
      const branch = promises[1];
      if (branch.message) {
        //Nao foi possivel identificar a filial
        setCurrentAlertError(
          `Não foi possível encontrar filial para atender: ${currPatient.city}`
        );
        return;
      }
      if (branch.indexOf && branch.indexOf('message') > 0) {
        //Nao foi possivel identificar a filial
        setCurrentAlertError(
          `Não foi possível encontrar filial para atender: ${currPatient.city}`
        );
        return;
      }
      if (branch.id) {
        setPatientBranch(branch);
      }
      const priority = await getProductPriority(branch.id);
      const priorityMap = priority.reduce((acc, value) => {
        if (!acc[value.product_master]) {
          acc[value.product_master] = {};
        }
        JSON.parse(value.available_branches).forEach((branch) => {
          if (!acc[value.product_master][branch]) {
            acc[value.product_master][branch] = {
              priority: value.priority,
              use: value.product_substitute,
            };
          } else if (
            value.priority < acc[value.product_master][branch].priority
          ) {
            acc[value.product_master][branch] = {
              priority: value.priority,
              use: value.product_substitute,
            };
          }
        });
        return acc;
      }, {});
      setProductPriority(priorityMap);

      const orderedCategories = [];
      //Aviso: a ordem vem da api mas esse bloco força uma ordem específica
      orderedCategories[0] = categories.filter(
        (x) => x.description.toLowerCase().indexOf('oxige') >= 0
      )[0];
      orderedCategories[1] = categories.filter(
        (x) => x.description.toLowerCase().indexOf('domici') >= 0
      )[0];
      orderedCategories[2] = categories.filter(
        (x) => x.description.toLowerCase().indexOf('sono') >= 0
      )[0];
      orderedCategories[3] = categories.filter(
        (x) => x.description.toLowerCase().indexOf('metros') >= 0
      )[0];
      orderedCategories[4] = categories.filter(
        (x) => x.description.toLowerCase().indexOf('pirador') >= 0
      )[0];
      orderedCategories[5] = categories.filter(
        (x) => x.description.toLowerCase().indexOf('ispositi') >= 0
      )[0];
      setAvailableCategories(orderedCategories);
      setSelectedPatient(currPatient);
      navigate('/order/selectCategory');
    } catch (e) {
      console.error(e.toString());
    } finally {
      setBlockingLoad(0);
    }
  };

  return (
    <WideLayout
      outlet={Outlet({ onRowSelection, continueToCategory, blockingLoad })}
    ></WideLayout>
  );
};
