import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { PrimaryTitleGray } from '../../base/colors';
import { InputText } from '../../base/Inputs';
import { GreenLabel, RedLabel, YellowLabel } from '../../base/labels';
import { ErrorMessage } from '../../base/message';
import { Table } from '../../base/table';
import { TitledPanel } from '../../components/dashboards/titledPanel/titledPanel';
import { LargeTile } from '../../components/gridsystem/gridsystem';
import { BasicPagination } from '../../components/pagination/basic';
import { usePortalStore } from '../../store';
import { usePatientsStore } from '../../stores/patientsStore';
import { formatCPF } from '../../utils/utils';
import { MdOutlinePersonAddDisabled, MdOutlinePersonAdd } from 'react-icons/md';
import { Visibility as VisibilityIcon } from '@mui/icons-material';
import { Tooltip } from '../../components/common/Tooltip';
import { LineThrough } from '../../components/common/LineThrough';
import { PatientComplementIcon } from '../../components/patients/PatientClassificationIcon';
import { PatientName } from '../../components/patients/PatientName';

export const PatientsList = ({
  title,
  onlyActives,
  height,
  subtitle,
  showDeletePopup,
  showEditPopup,
  readOnly,
  header,
  fields,
  onRowSelected,
  newClientAction,
}) => {
  const patients = usePatientsStore((state) => state.patients);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const currentUser = usePortalStore((state) => state.currentUser);
  const error = usePatientsStore((state) => state.error);
  const fetchPatients = usePatientsStore((state) => state.fetchPatients);
  const searchTerm = usePatientsStore((state) => state.searchTerm);
  const setSearchTerm = usePatientsStore((state) => state.setSearchTerm);
  const [autoFilter, setAutoFilter] = useState('');
  const fetchTotalItems = usePatientsStore((state) => state.fetchTotalItems);
  const clearPatientsList = usePatientsStore(
    (state) => state.clearPatientsList
  );
  let timeoutId = 0;

  useEffect(() => {
    clearPatientsList();
  }, []);

  useEffect(() => {
    (async () => {
      await fetchPatients(
        currentUser.cnpj,
        autoFilter,
        null,
        null,
        onlyActives ? 'Ativo' : null
      );
    })();
  }, [autoFilter]);

  useEffect(() => {
    setAutoFilter(searchTerm);
  }, [searchTerm]);

  const runAutoComplete = (e) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      setAutoFilter(e);
    }, 300);
  };

  const paginate = (e) => {
    (async () => {
      await fetchPatients(
        currentUser.cnpj,
        autoFilter,
        e.page,
        e.pageSize,
        onlyActives ? 'Ativo' : null
      );
    })();
  };

  const isPatientInactive = (status) => status?.toLowerCase() === 'inativo';

  return (
    <LargeTile margin={'15px'}>
      <h3 style={{ color: PrimaryTitleGray, margin: '10px 0 15px 0' }}>
        {title}
      </h3>
      <TitledPanel
        action={newClientAction ? newClientAction : <></>}
        title="Pacientes"
        subtitle={subtitle != '' ? subtitle : ''}
        outlet={
          <div style={{ display: 'block', position: 'relative' }}>
            <InputText
              placeholder="Buscar paciente por nome, CPF ou número SAP."
              style={{ width: isTabletOrMobile ? '100%' : '50%' }}
              onKeyUp={(e) => {
                runAutoComplete(e.target.value);
              }}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              value={searchTerm}
            ></InputText>

            <div
              style={{
                height: height ? height : '300px',
                width: '100%',
                marginTop: '20px',
                overflow: 'auto',
              }}
            >
              {error ? (
                <ErrorMessage>{`Não foi possível recuperar os registros de pacientes: ${error}`}</ErrorMessage>
              ) : (
                <Table
                  onRowSelection={onRowSelected}
                  header={header}
                  data={patients.map((p, idx) => {
                    const columns = [];
                    if (!readOnly) {
                      columns.push(
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <Tooltip
                            title={
                              isPatientInactive(p.status)
                                ? 'Ativar'
                                : 'Inativar'
                            }
                          >
                            {isPatientInactive(p.status) ? (
                              <MdOutlinePersonAdd
                                className="hoverButton"
                                style={{ cursor: 'pointer', fontSize: '18px' }}
                                onClick={() => showDeletePopup(p.cpf)}
                              />
                            ) : (
                              <MdOutlinePersonAddDisabled
                                className="hoverButton"
                                style={{ cursor: 'pointer', fontSize: '18px' }}
                                onClick={() => showDeletePopup(p.cpf)}
                              />
                            )}
                          </Tooltip>
                          <span> </span>
                          {(currentUser.isWhiteMartins ||
                            currentUser.isAdmin) && (
                            <Tooltip title="Visualizar">
                              <VisibilityIcon
                                className="hoverButton"
                                style={{
                                  marginLeft: '20px',
                                  cursor: 'pointer',
                                  fontSize: '18px',
                                }}
                                onClick={() => {
                                  showEditPopup(idx);
                                }}
                              ></VisibilityIcon>
                            </Tooltip>
                          )}
                        </div>
                      );
                    }
                    fields.forEach((f) => {
                      if (f === 'status' && p.status) {
                        columns.push(
                          p.status?.toLowerCase() === 'ativo' ? (
                            <GreenLabel
                              style={{
                                width: '80px',
                                textTransform: 'capitalize',
                              }}
                            >
                              {p.status}
                            </GreenLabel>
                          ) : p.status?.toLowerCase() === 'inativo' ? (
                            <RedLabel
                              style={{
                                width: '80px',
                                textTransform: 'capitalize',
                              }}
                            >
                              {p.status}
                            </RedLabel>
                          ) : (
                            <YellowLabel
                              style={{
                                width: '80px',
                                textTransform: 'capitalize',
                              }}
                            >
                              {p.status}
                            </YellowLabel>
                          )
                        );
                      } else if (f === 'cpf' && p.cpf) {
                        columns.push(formatCPF(p.cpf));
                      } else if (f === 'name') {
                        const name = p[f];
                        const element = (
                          <div
                            style={{
                              display: 'flex',
                              margin: 'auto 5px',
                            }}
                          >
                            {p.onPaymentList === true ||
                            (!currentUser.isAdmin &&
                              !currentUser.isWhiteMartins) ? (
                              <div>{name}</div>
                            ) : (
                              <LineThrough>{name}</LineThrough>
                            )}
                            {currentUser.isAdmin ||
                            currentUser.isWhiteMartins ? (
                              <div
                                style={{
                                  display: 'flex',
                                  marginLeft: '5px',
                                }}
                              >
                                {p.additionalInformation?.map((i, idx) => (
                                  <PatientComplementIcon
                                    onPaymentList={p.onPaymentList}
                                    statusComplementId={i}
                                    key={idx}
                                  ></PatientComplementIcon>
                                ))}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        );
                        columns.push(
                          <PatientName
                            name={p.name}
                            additionalInformation={p.additionalInformation?.map(
                              (i) => i.id
                            )}
                            onPaymentList={p.onPaymentList}
                            canSeeFullContent={
                              currentUser.isWhiteMartins || currentUser.isAdmin
                            }
                          ></PatientName>
                        );
                      } else {
                        columns.push(p[f]);
                      }
                    });
                    return columns;
                  })}
                ></Table>
              )}
            </div>
            <div style={{ marginTop: '10px' }}>
              <BasicPagination
                qtyItems={fetchTotalItems}
                onChange={(e) => paginate(e)}
              ></BasicPagination>
            </div>
          </div>
        }
      ></TitledPanel>
    </LargeTile>
  );
};
