import React from 'react';
import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  Checkbox,
  ListItemText,
} from '@mui/material';
import { SelectProps } from '@mui/material/Select';
import { ExpandMore } from '@mui/icons-material';
import { Select } from './styles';
import { HelperText } from '../HelperText';

type MultipleSelectProps = {
  items: MultipleSelectItems[];
  errorHelperText?: string;
} & SelectProps;

export type MultipleSelectItems = {
  label: string;
  value: string;
};

export const MultipleSelect: React.FC<MultipleSelectProps> = ({
  sx,
  label,
  items,
  value,
  disabled: disabledProp,
  errorHelperText,
  ...rest
}) => {
  const id = `${label}-multiple-select`;
  const disabled = items.length === 0 || disabledProp;
  const hasError = errorHelperText !== undefined;

  return (
    <FormControl fullWidth size="small" sx={sx} error={hasError}>
      <InputLabel id={id} disabled={disabled}>
        {label}
      </InputLabel>
      <Select
        labelId={id}
        multiple
        value={value}
        IconComponent={ExpandMore}
        input={<OutlinedInput label={label} />}
        disabled={disabled}
        renderValue={(selected) => {
          return (selected as string[])
            .map((val) => {
              const selectedItem = items.find((i) => i.value === val);
              if (selectedItem) return selectedItem.label;
              return '';
            })
            .filter((i) => i !== '')
            .join(', ');
        }}
        {...rest}
      >
        {[...items, { label: '', value: '' }].map((item) => (
          <MenuItem
            key={item.value}
            value={item.value}
            style={{ display: item.value === '' ? 'none' : 'flex' }}
          >
            <Checkbox checked={(value as string[]).indexOf(item.value) > -1} />
            <ListItemText primary={item.label} />
          </MenuItem>
        ))}
      </Select>
      <HelperText message={errorHelperText} />
    </FormControl>
  );
};
