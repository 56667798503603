export class StorageHelper {
  getStorage() {
    const storagePlace =
      localStorage.getItem('keepConnected') === 'true'
        ? localStorage
        : sessionStorage;

    return storagePlace;
  }
}
