import React, { useState } from 'react';
import styled from 'styled-components';
import { Green3, PrimaryTitleGray } from './colors';
import { AiOutlinePlusSquare, AiOutlineMinusSquare } from 'react-icons/ai';

const TableWidget = styled.table`
  width: 100%;
  text-align: left;
`;

const RowWidget = styled.tr`
  font-size: 12px;
  color: ${PrimaryTitleGray};
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const RowWidgetExp = styled.tr`
  font-size: 12px;
  color: ${PrimaryTitleGray};
`;

export const Table = ({ height, expandable, header, data, onRowSelection }) => {
  const [currentSelectedRowIdx, setCurrentSelectedRowIdx] = useState(-1);
  const filterExpandable = (colName) => {
    if (!expandable) return true;
    return colName !== 'expandPanel' && colName !== 'showExpandPanelIcon';
  };

  const onRowSelected = (row, idx) => {
    setCurrentSelectedRowIdx(idx);
    onRowSelection(row, idx);
  };
  const checkBox = !header[0].hasOwnProperty('props')
    ? false
    : !header[0].props.hasOwnProperty('children')
    ? false
    : !header[0].props.children.hasOwnProperty('props')
    ? false
    : !header[0].props.children.props.hasOwnProperty('type')
    ? false
    : header[0].props.children.props.type === 'checkbox'
    ? true
    : false;
  const isSmall = (h) => {
    let result = false;
    try {
      const className = `${h.props.className}`;
      return className.toLowerCase() === 'small';
    } catch (e) {
      return result;
    }
  };
  return (
    <TableWidget style={{ height: height ? height : 'auto' }}>
      <thead className="table-style">
        <RowWidget
          style={{ backgroundColor: '#f2f2f2', color: PrimaryTitleGray }}
        >
          {header.map((h, idx) =>
            (checkBox && idx === 0) || isSmall(h) ? (
              <th style={{ boxSizing: 'border-box', width: '0%' }} key={idx}>
                {h}
              </th>
            ) : (
              <th style={{ boxSizing: 'border-box' }} key={idx}>
                {h}
              </th>
            )
          )}
        </RowWidget>
      </thead>
      <tbody className="table-style">
        {data.map((row, idx) =>
          onRowSelection ? (
            <RowWidget
              key={idx}
              style={{
                cursor: 'pointer',
                backgroundColor: currentSelectedRowIdx == idx ? Green3 : '',
                color: currentSelectedRowIdx == idx ? 'white' : '',
              }}
              onClick={() => {
                onRowSelected(row, idx);
              }}
            >
              {Object.keys(row)
                .filter(filterExpandable)
                .filter((x) => row[x] !== null)
                .map((f, i) => (
                  <td
                    style={{ padding: '5px', boxSizing: 'border-box' }}
                    key={i}
                  >
                    {row[f]}
                  </td>
                ))}
            </RowWidget>
          ) : (
            <RowWidget key={idx} data-key={idx}>
              {Object.keys(row)
                .filter(filterExpandable)
                .filter((x) => row[x] !== null)
                .map((f, i) => (
                  <td
                    style={{ padding: '5px', boxSizing: 'border-box' }}
                    key={i}
                  >
                    {row[f] === undefined || typeof row[f] !== 'string'
                      ? row[f]
                      : row[f].type !== undefined
                      ? row[f]
                      : row[f].split('\n').map((p, i) => (
                          <div key={i}>
                            <span>{p}</span>
                            <br />
                          </div>
                        ))}
                  </td>
                ))}
            </RowWidget>
          )
        )}
      </tbody>
    </TableWidget>
  );
};

export const ExpandableTable = ({ height, expandable, header, data }) => {
  const filterExpandable = (colName) => {
    if (!expandable) return true;
    return (
      colName !== 'expandPanel' &&
      colName !== 'showExpandPanelIcon' &&
      colName !== 'addExpandedRow' &&
      colName !== 'removeExpandedRow'
    );
  };
  const [expandVisible, setExpandVisible] = useState(data.map((x) => false));
  const rows = [];
  data.forEach((el) => {
    rows.push(el);
    rows.push({ panel: el.expandPanel, expandablePanel: true });
  });
  const toggleExpandPanel = (idx, row) => {
    if (!expandVisible[idx]) row.addExpandedRow();
    else row.removeExpandedRow();

    expandVisible[idx] = !expandVisible[idx];
    setExpandVisible([...expandVisible]);
  };

  let i = 0;

  return (
    <TableWidget style={{ height: height ? height : 'auto' }}>
      <thead className="table-style">
        <RowWidgetExp
          style={{ backgroundColor: '#f2f2f2', color: PrimaryTitleGray }}
        >
          {header.map((h, idx) =>
            h === '' ? (
              <th style={{ boxSizing: 'border-box', width: '0%' }} key={idx}>
                {h}
              </th>
            ) : (
              <th style={{ boxSizing: 'border-box' }} key={idx}>
                {h}
              </th>
            )
          )}
          {expandable && <th></th>}
        </RowWidgetExp>
      </thead>
      <tbody className="table-style">
        {rows.map((row, idx) =>
          row.expandablePanel ? (
            <RowWidgetExp
              key={idx}
              style={{ display: expandVisible[idx] ? '' : 'none' }}
            >
              <td
                colSpan={header.length + 1}
                style={{ paddingLeft: '10px', boxSizing: 'border-box' }}
              >
                {row.panel}
              </td>
            </RowWidgetExp>
          ) : (
            <RowWidgetExp
              key={idx}
              data-key={idx}
              style={{ backgroundColor: idx % 4 === 0 ? '' : '#f2f2f2' }}
            >
              {Object.keys(row)
                .filter(filterExpandable)
                .map((f, i) =>
                  row[f] !== row.sinalLuminoso ? (
                    <td
                      style={{ padding: '10px', boxSizing: 'border-box' }}
                      key={i}
                    >
                      {row[f]}
                    </td>
                  ) : (
                    <td
                      style={{ padding: '0px', boxSizing: 'border-box' }}
                      key={i}
                    >
                      {row[f]}
                    </td>
                  )
                )}
              <td>
                {expandVisible[idx + 1] ? (
                  <AiOutlineMinusSquare
                    onClick={() => toggleExpandPanel(idx + 1, row)}
                    style={{
                      fontSize: '18px',
                      cursor: 'pointer',
                      display: row.showExpandPanelIcon === false ? 'none' : '',
                    }}
                  ></AiOutlineMinusSquare>
                ) : (
                  <AiOutlinePlusSquare
                    onClick={() => toggleExpandPanel(idx + 1, row)}
                    style={{
                      fontSize: '18px',
                      cursor: 'pointer',
                      display: row.showExpandPanelIcon === false ? 'none' : '',
                    }}
                  ></AiOutlinePlusSquare>
                )}
              </td>
            </RowWidgetExp>
          )
        )}
      </tbody>
    </TableWidget>
  );
};

export const ControlledExpandableTable = ({
  expandable,
  header,
  data,
  expandVisible,
}) => {
  const filterExpandable = (colName) => {
    if (!expandable) return true;
    return colName !== 'expandPanel';
  };
  const rows = [];
  data.forEach((el) => {
    rows.push(el);
    rows.push({ panel: el.expandPanel, expandablePanel: true });
  });
  let i = 0;
  return (
    <TableWidget>
      <thead className="table-style">
        <RowWidgetExp
          style={{ backgroundColor: '#f2f2f2', color: PrimaryTitleGray }}
        >
          {header.map((h, idx) => (
            <th
              style={{ paddingLeft: '10px', boxSizing: 'border-box' }}
              key={idx}
            >
              {h}
            </th>
          ))}
        </RowWidgetExp>
      </thead>
      <tbody className="table-style">
        {rows.map((row, idx) =>
          row.expandablePanel ? (
            <RowWidgetExp
              key={idx}
              style={{ display: expandVisible[idx] ? '' : 'none' }}
            >
              <td
                colSpan={header.length + 1}
                style={{ paddingLeft: '10px', boxSizing: 'border-box' }}
              >
                {row.panel}
              </td>
            </RowWidgetExp>
          ) : (
            <RowWidgetExp
              key={idx}
              data-key={idx}
              style={{ backgroundColor: idx % 4 === 0 ? '' : '#f2f2f2' }}
            >
              {Object.keys(row)
                .filter(filterExpandable)
                .map((f, i) => (
                  <td
                    style={{ padding: '10px', boxSizing: 'border-box' }}
                    key={i}
                  >
                    {row[f]}
                  </td>
                ))}
            </RowWidgetExp>
          )
        )}
      </tbody>
    </TableWidget>
  );
};
