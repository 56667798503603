import { Api } from '../api';

export class UserDataSource {
  async getOperators() {
    const result = (await new Api().fetch('/users/operators')) as Array<{
      name: string;
    }>;
    return result;
  }
}
