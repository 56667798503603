import { useState } from 'react';
import { Calendar } from 'react-calendar';
import { PrimaryButton } from '../../base/Inputs';
import { formatDate } from '../../utils/utils';
const mapDays = {
  0: 'Dom',
  1: 'Seg',
  2: 'Ter',
  3: 'Qua',
  4: 'Qui',
  5: 'Sex',
  6: 'Sab',
};
export const DeliveryConfiguration = ({
  selectedPatient,
  windowAttendance,
  orderType,
  onFinish,
  fastDeliveryLabel,
  scheduleDeliveryLabel,
  _hasFastDelivery,
  savingOrder,
}) => {
  const [deliveryType, setDeliveryType] = useState(null);
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [deliveryTurn, setDeliveryTurn] = useState({
    manha: false,
    tarde: false,
    noite: false,
  });
  const hasFastDelivery = () =>
    orderType !== 'Implantação' ||
    (windowAttendance.D0.toLowerCase() === 's' &&
      windowAttendance.windows
        .filter((j) => j.Day === mapDays[new Date().getDay()])[0]
        .Active?.toLowerCase() !== 'n') ||
    _hasFastDelivery;
  const checkDateAvailability = ({ date, view }) => {
    if (view === 'month') {
      const currentWindow = windowAttendance.windows.filter(
        (j) => j.Day === mapDays[date.getDay()]
      )[0];
      if (currentWindow?.Active?.toLowerCase() !== 's') {
        return true;
      }
      if (
        deliveryType === 'entrega_agendada' &&
        formatDate(date) === formatDate(new Date())
      ) {
        return true;
      }
    }
    return false;
  };
  const formatTurn = () => {
    let turnos = [];
    if (
      deliveryTurn['manha'] &&
      deliveryTurn['tarde'] &&
      deliveryTurn['noite']
    ) {
      return '';
    }
    if (deliveryTurn['manha']) {
      turnos.push('manhã');
    }
    if (deliveryTurn['tarde']) {
      turnos.push('tarde');
    }
    if (deliveryTurn['noite']) {
      turnos.push('noite');
    }
    if (turnos.length > 0) {
      return ' no período da ' + turnos.join(' ou ');
    }
    return '';
  };
  return (
    <div style={{ display: 'block', width: '100%' }}>
      <div>
        <h4 style={{ margin: '0 0 5px', padding: '0', fontWeight: '700' }}>
          Endereço
        </h4>
        <ul
          style={{
            listStyle: 'none',
            marginTop: '0px',
            marginBottom: '10px',
            padding: '10px',
            background: 'rgb(247, 247, 247)',
            borderRadius: '11px',
          }}
        >
          <li style={{ margin: '0px', padding: '0px' }}>
            <label style={{ fontWeight: '500', fontSize: '14px' }}>
              {selectedPatient.name}
            </label>
          </li>
          <li style={{ margin: '0px', padding: '0px' }}>
            <label style={{ fontWeight: '300', fontSize: '14px' }}>
              {selectedPatient.address} - {selectedPatient.number}
            </label>
          </li>
          <li style={{ margin: '0px', padding: '0px' }}>
            <label style={{ fontWeight: '300', fontSize: '14px' }}>
              {selectedPatient.city} - {selectedPatient.uf}
            </label>
          </li>
          <li style={{ margin: '0px', padding: '0px' }}>
            <label style={{ fontWeight: '300', fontSize: '14px' }}>
              {selectedPatient.cep}
            </label>
          </li>
        </ul>
      </div>

      <div>
        <h4 style={{ margin: '0 0 5px', padding: '0', fontWeight: '700' }}>
          Agendamento para {orderType.toLowerCase()}
        </h4>
        <div style={{ position: 'relative' }}>
          {windowAttendance !== null ? (
            <>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '0px',
                  marginBottom: '10px',
                  padding: '10px',
                  background: 'rgb(247, 247, 247)',
                  borderRadius: '11px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: '15px',
                  }}
                >
                  <input
                    disabled={!hasFastDelivery()}
                    onChange={(e) => {
                      setDeliveryType(e.target.value);
                      if (new Date().getHours() + 1 > 20) {
                        setDeliveryDate(
                          new Date(Date.now() + 1000 * 60 * 60 * 24)
                        );
                      } else {
                        setDeliveryDate(new Date(Date.now()));
                      }
                    }}
                    type="radio"
                    value="entrega_imediata"
                    name="entrega"
                  />
                  <span style={{ fontSize: '14px', color: 'black' }}>
                    {fastDeliveryLabel}
                  </span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: '15px',
                  }}
                >
                  <input
                    onChange={(e) => {
                      setDeliveryType(e.target.value);
                    }}
                    type="radio"
                    value="entrega_agendada"
                    name="entrega"
                  />
                  <span style={{ fontSize: '14px', color: 'black' }}>
                    {scheduleDeliveryLabel}
                  </span>
                </div>
              </div>
              {deliveryType === 'entrega_agendada' ? (
                <div>
                  <h4
                    style={{
                      margin: '0 0 5px',
                      padding: '0',
                      fontWeight: '700',
                    }}
                  >
                    Período
                  </h4>
                  <div
                    style={{
                      display: 'flex',
                      marginTop: '0px',
                      marginBottom: '10px',
                      padding: '10px',
                      background: 'rgb(247, 247, 247)',
                      borderRadius: '11px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        marginRight: '15px',
                      }}
                    >
                      <input
                        onChange={(e) =>
                          setDeliveryTurn({
                            ...deliveryTurn,
                            manha: e.target.checked,
                          })
                        }
                        type="checkbox"
                        value="manha"
                      />
                      <span style={{ fontSize: '14px', color: 'black' }}>
                        Manhã
                      </span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '15px',
                      }}
                    >
                      <input
                        onChange={(e) =>
                          setDeliveryTurn({
                            ...deliveryTurn,
                            tarde: e.target.checked,
                          })
                        }
                        type="checkbox"
                        value="tarde"
                      />
                      <span style={{ fontSize: '14px', color: 'black' }}>
                        Tarde
                      </span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '15px',
                      }}
                    >
                      <input
                        onChange={(e) =>
                          setDeliveryTurn({
                            ...deliveryTurn,
                            noite: e.target.checked,
                          })
                        }
                        type="checkbox"
                        value="noite"
                      />
                      <span style={{ fontSize: '14px', color: 'black' }}>
                        Noite
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div
                style={
                  deliveryType !== 'entrega_agendada'
                    ? {
                        pointerEvents: 'none',
                        marginTop: '15px',
                        opacity: '0.25',
                      }
                    : { marginTop: '15px' }
                }
              >
                <Calendar
                  onChange={(e) => setDeliveryDate(e)}
                  locale="pt"
                  minDate={new Date()}
                  tileDisabled={({ date, view }) =>
                    checkDateAvailability({ date, view })
                  }
                />
                {/* <DateRange selectRange={false} onSelect={(e)=>setDeliveryDate(e)} minDate={new Date()} checkDateAvailability={({date,view})=>checkDateAvailability({date,view})}></DateRange> */}
              </div>
              <div>
                {deliveryDate !== null ? (
                  <>
                    {/* <h4 style={{color:"black"}}>Finalizar</h4> */}
                    <p
                      style={{
                        marginTop: '10px',
                        marginBottom: '10px',
                        padding: '10px',
                        background: 'rgb(247, 247, 247)',
                        borderRadius: '11px',
                      }}
                    >
                      Pedido agendado para{' '}
                      <span style={{ fontWeight: '700' }}>
                        {formatDate(deliveryDate)}
                      </span>{' '}
                      <span style={{ fontWeight: '700' }}>{formatTurn()}</span>
                    </p>
                    <div style={{ marginTop: '20px', textAlign: 'right' }}>
                      <PrimaryButton
                        onClick={() => onFinish(deliveryDate, deliveryTurn)}
                        disabled={savingOrder}
                      >
                        Finalizar
                      </PrimaryButton>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
