import { CurrentUser } from '../types/User';
import { StorageHelper } from './StorageHelper';

export class UserHelper {
  static getCurrentUser(): CurrentUser | undefined {
    const storagePlace = new StorageHelper().getStorage();
    const storageUser = storagePlace.getItem('portalHomeCareAuth');
    if (storageUser) {
      return JSON.parse(storageUser);
    }
  }
}
