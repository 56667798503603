import { PrimaryButton } from '../../base/Inputs';
import { SimplePopup } from '../popup';
import { BiErrorCircle } from 'react-icons/bi';
import { ErrorRed } from '../../base/colors';

type ErrorModalProps = {
  title: string;
  message: string;
  showErrorModal: boolean;
  setShowErrorModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ErrorModal: React.FC<ErrorModalProps> = ({
  title = 'Atenção!',
  message = 'Erro interno contacte o administrador do sistema',
  showErrorModal,
  setShowErrorModal,
}) => {
  return showErrorModal ? (
    <SimplePopup
      zIndex={10000}
      width={600}
      height={null}
      outlet={
        <div>
          <div className="error-message">
            <h3 className="erro-text">
              <BiErrorCircle
                style={{
                  color: ErrorRed,
                  fontWeight: '500',
                  fontSize: '32px',
                }}
              />
              {title}
            </h3>
          </div>
          <div>
            <p style={{ textAlign: 'center' }}>{message}</p>
          </div>
          <div style={{ marginTop: '20px', textAlign: 'center' }}>
            <PrimaryButton onClick={() => setShowErrorModal(false)}>
              Ok
            </PrimaryButton>
          </div>
        </div>
      }
    ></SimplePopup>
  ) : (
    <></>
  );
};
