import styled from 'styled-components';
import { DarkGreen, ErrorRed } from './colors';

export const GreenLabel = styled.div`
  box-sizing: border-box;
  width: 100px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  background-color: #b2e0c4;
  text-align: center;
  font-weight: 600;
  color: ${DarkGreen};
`;

export const YellowLabel = styled.div`
  box-sizing: border-box;
  width: 100px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 600;
  border-radius: 5px;
  text-align: center;
  background-color: #f9edb2;
  color: #524602;
`;
export const RedLabel = styled.div`
  box-sizing: border-box;
  width: 100px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 600;
  border-radius: 5px;
  text-align: center;
  background-color: ${ErrorRed};
`;
