import { useMediaQuery } from 'react-responsive';
import { BackgroundColor } from '../../base/colors';
import { ReadOnlyMainHeader } from '../header/header';

export const PublicLayout = (props) => {
  let style = { backgroundColor: BackgroundColor, height: '100vh' };
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' });
  if (!isTabletOrMobile) {
    style.width = '100%';
    style.minHeight = '100%';
    style.margin = 'auto auto';
  }
  return (
    <div style={style}>
      <ReadOnlyMainHeader></ReadOnlyMainHeader>
      <div
        style={{
          overflow: 'hidden',
          position: 'relative',
          minHeight: '100%',
          width: '100%',
        }}
      >
        {props.outlet ? props.outlet : <></>}
      </div>
    </div>
  );
};
