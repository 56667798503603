import React from 'react';
import {
  Checkbox as MUICheckbox,
  FormControlLabel,
  FormGroup,
  FormGroupProps,
} from '@mui/material';

type CheckboxProps = {
  items: CheckboxItems[];
  handleCheck: (event: React.ChangeEvent<HTMLInputElement>) => void;
} & FormGroupProps;

export type CheckboxItems = {
  label: string;
  value: string;
  checked: boolean;
};

export const Checkbox: React.FC<CheckboxProps> = ({
  items,
  handleCheck,
  sx,
  ...rest
}) => {
  return (
    <FormGroup sx={sx} {...rest}>
      {items.map((item, idx) => (
        <FormControlLabel
          key={idx}
          control={
            <MUICheckbox
              checked={item.checked}
              value={item.value}
              onChange={handleCheck}
            />
          }
          label={item.label}
        />
      ))}
    </FormGroup>
  );
};
