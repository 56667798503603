import { Panel } from '../../panels/panel';
import {
  LabelTile,
  ImageTile,
  IconTile,
  ValueTile,
  PanelBody,
  PanelContent,
} from './styles';

type StatePanelProps = {
  label: string[];
  color: string;
  icon: string;
  value: any;
  isCurrency?: boolean;
  unit?: string;
  showNote?: boolean;
};

export const StatePanel = ({
  label,
  color,
  icon,
  value,
  isCurrency,
  unit,
  showNote,
}: StatePanelProps) => {
  const statePanelLabel =
    typeof label !== 'string'
      ? label.map((x: string, idx: number) => <div key={idx}>{x}</div>)
      : label;

  return (
    <Panel style={{ height: '100%' }}>
      <PanelContent>
        <LabelTile color={color}>{statePanelLabel}</LabelTile>
        <PanelBody>
          {typeof icon === 'string' ? (
            <ImageTile color={color}>
              <img src={icon} width="48" />
            </ImageTile>
          ) : (
            <IconTile color={color}>{icon}</IconTile>
          )}
          <ValueTile style={{}} color={color}>
            {Number.isFinite(value)
              ? value.toLocaleString(
                  'pt-BR',
                  isCurrency
                    ? { style: 'currency', currency: 'BRL' }
                    : undefined
                )
              : ''}
            {unit ? unit : ''}
          </ValueTile>
        </PanelBody>
        {showNote && (
          <small
            style={{
              display: 'block',
              marginTop: '10px',
              fontSize: '12px',
              color: '#666',
            }}
          >
            Trata-se da soma do valor do período selecionado abaixo
          </small>
        )}
      </PanelContent>
    </Panel>
  );
};
