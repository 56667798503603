import React from 'react';
import { FormControl, InputLabel, MenuItem, SelectProps } from '@mui/material';
import { Select } from './styles';
import { ExpandMore } from '@mui/icons-material';
import { HelperText } from '../HelperText';

type ComboBoxProps = {
  items: ComboBoxItems[];
  errorHelperText?: string;
} & SelectProps;

export type ComboBoxItems = {
  label: string;
  value: string;
};

export const ComboBox: React.FC<ComboBoxProps> = ({
  sx,
  value,
  items,
  label,
  disabled: disabledProp,
  errorHelperText,
  ...rest
}) => {
  const id = `${label}-combobox`;
  const disabled = items.length === 0 || disabledProp;
  const hasError = errorHelperText !== undefined;

  return (
    <FormControl fullWidth size="small" sx={sx} error={hasError}>
      <InputLabel id={id} disabled={disabled}>
        {label}
      </InputLabel>
      <Select
        labelId={id}
        label={label}
        value={value}
        IconComponent={ExpandMore}
        disabled={disabled}
        {...rest}
      >
        {[...items, { label: '', value: '' }].map((item, idx) => (
          <MenuItem
            key={idx}
            value={item.value}
            style={{ display: item.value === '' ? 'none' : 'flex' }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
      <HelperText message={errorHelperText} />
    </FormControl>
  );
};
