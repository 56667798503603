import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  InputText,
  PrimaryButton,
  SecondaryButton,
  Select,
} from '../../base/Inputs';
import { ErrorMessage } from '../../base/message';
import { createUser, editUser } from '../../services/userMgntService';

export const UserForm = ({
  id,
  name,
  email,
  cnpj,
  companyName,
  isSpecialist,
  isGeneralist,
  isManager,
  isWhiteMartins,
  isAdmin,
  onAction,
  isEditing,
}) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  const [_name, setName] = useState(name ?? '');
  const [_email, setEmail] = useState(email ?? '');
  const [_cnpj, setCNPJ] = useState(cnpj ?? '');
  const [_companyName, setCompanyName] = useState(companyName ?? '');
  const [_isSpecialist, setIsSpecialist] = useState(isSpecialist ?? '0');
  const [_isGeneralist, setIsGeneralist] = useState(isGeneralist ?? '0');
  const [_isManager, setIsManager] = useState(isManager ?? '0');
  const [_isWhiteMartins, setIsWhiteMartins] = useState(isWhiteMartins ?? '0');
  const [_isAdmin, setIsAdmin] = useState(isAdmin ?? '0');
  const [roleLevel, setRoleLevel] = useState({
    isSpecialist: isSpecialist,
    isGeneralist: isGeneralist,
    isManager: isManager,
    isWhiteMartins: isWhiteMartins,
    isAdmin: isAdmin,
  });
  const [message, setMessage] = useState('');
  const setRole = (value) => {
    Object.keys(roleLevel).forEach((c) => {
      roleLevel[c] = '0';
    });
    roleLevel[value] = '1';
    setRoleLevel({ ...roleLevel });
  };
  const saveUser = async () => {
    const user = {
      id: id,
      name: _name,
      email: _email,
      cnpj: _cnpj,
      companyName: _companyName,
      isSpecialist: roleLevel.isSpecialist ?? '0',
      isGeneralist: roleLevel.isGeneralist ?? '0',
      isManager: roleLevel.isManager ?? '0',
      isWhiteMartins: roleLevel.isWhiteMartins ?? '0',
      isAdmin: roleLevel.isAdmin ?? '0',
    };
    if (
      _email === '' ||
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(_email) === false
    ) {
      setMessage('Você precisa informar um e-mail válido');
      return;
    }
    if (_name === '') {
      setMessage('Você precisa informar um nome válido');
      return;
    }
    if (_cnpj === '' || _cnpj.length !== 14 || /^\d+$/.test(_cnpj) === false) {
      setMessage('Você precisa informar um CNPJ válido');
      return;
    }
    if (isEditing) {
      //editar
      await editUser(user);
    } else {
      //criar
      await createUser(user);
    }
    if (typeof onAction === 'function') {
      onAction();
    }
  };
  return (
    <>
      {message != '' ? <ErrorMessage>{message}</ErrorMessage> : <></>}
      <div
        style={{
          display: 'block',
          flexFlow: isTabletOrMobile ? 'column' : 'wrap',
        }}
      >
        <div style={{ flex: '3', padding: '5px' }}>
          <InputText
            value={_name}
            placeholder="Nome"
            style={{ width: '360px' }}
            onChange={(e) => setName(e.target.value)}
          ></InputText>
        </div>
        <div style={{ flex: '1', padding: '5px' }}>
          <InputText
            disabled={isEditing}
            value={_email}
            placeholder="Email"
            style={{ width: '360px' }}
            onChange={(e) => setEmail(e.target.value)}
          ></InputText>
        </div>
        <div style={{ flexBasis: '100%', height: '0' }}></div>
        <div style={{ flex: '1', padding: '5px' }}>
          <InputText
            maxLength={14}
            value={_cnpj}
            placeholder="CNPJ"
            style={{ width: '360px' }}
            onChange={(e) => setCNPJ(e.target.value)}
          ></InputText>
        </div>

        <div style={{ flex: '1', padding: '5px' }}>
          <InputText
            value={_companyName}
            placeholder="Empresa"
            style={{ width: '360px' }}
            onChange={(e) => setCompanyName(e.target.value)}
          ></InputText>
        </div>
        <div style={{ flexBasis: '100%' }}>
          <h3 style={{ paddingLeft: '5px' }}>Perfil</h3>
        </div>
        <div style={{ display: 'flex', justifyContent: 'start' }}>
          <Select
            onChange={(e) => setRole(e.target.value)}
            value={
              Object.keys(roleLevel).filter(
                (x) => roleLevel[x] !== '0' && roleLevel[x] !== undefined
              )[0]
            }
          >
            <option value="isSpecialist">Especialista</option>
            <option value="isGeneralist">Geral</option>
            <option value="isManager">Gerente</option>
            <option value="isWhiteMartins">White Martins</option>
            <option value="isAdmin">Admin</option>
          </Select>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          marginTop: '30px',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <SecondaryButton style={{ width: '140px' }} onClick={onAction}>
            Cancelar
          </SecondaryButton>
        </div>
        <div>
          <PrimaryButton style={{ width: '140px' }} onClick={() => saveUser()}>
            Salvar
          </PrimaryButton>
        </div>
      </div>
    </>
  );
};
