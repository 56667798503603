import { useState } from 'react';
import { ReadOnlyInputText } from './Inputs';
import { BsSearch } from 'react-icons/bs';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { VscCalendar } from 'react-icons/vsc';
import { PrimaryTextColor, TextColor } from './colors';
import styled from 'styled-components';

const CustomSelectDiv = styled.div`
  position: absolute;
  background-color: white;
  box-sizing: border-box;
  border: solid 1px #efefef;
  width: 100%;
  font-size: 14px;
  border-radius: 10px;
  overflow-y: auto;
  max-height: 300px;
  margin-top: 5px;
  z-index: 2;
  color: ${(props) => props.fontColor};
`;

const CustomSelectItem = styled.li`
  box-sizing: border-box;
  padding: 10px;
  &:hover {
    background-color: ${(props) => props.bgColor};
    color: white;
    cursor: pointer;
  }
`;
const leftIcons = {
  search: (
    <BsSearch
      style={{ position: 'absolute', top: '12px', left: '12px' }}
    ></BsSearch>
  ),
  calendar: (
    <VscCalendar
      style={{ position: 'absolute', top: '12px', left: '12px' }}
    ></VscCalendar>
  ),
};
export const CustomSelect = ({
  style,
  values,
  onSelect,
  leftIcon,
  defaultValue,
}) => {
  const [value, setValue] = useState(defaultValue ?? { name: '', value: null });
  const [listValues, setListValues] = useState(values);
  const [openValues, setOpenValues] = useState(false);
  let timeout = 0;
  const onChange = (value) => {
    setValue(value);
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      if (value === '') {
        setListValues([...values]);
      } else {
        const filteredValues = values.filter(
          (x) => x.name.toLowerCase().indexOf(value.toLowerCase()) >= 0
        );
        setListValues([...filteredValues]);
        setOpenValues(true);
      }
    }, 1);
  };
  const reset = () => {
    setListValues(values);
  };
  const selectValue = (value) => {
    setValue({ ...value });
    setOpenValues(false);
    if (typeof onSelect === 'function') {
      onSelect(value);
    }
  };
  return (
    <div style={{ ...style, position: 'relative', boxSizing: 'border-box' }}>
      {leftIcons[leftIcon]}
      <MdKeyboardArrowDown
        onClick={() => setOpenValues(!openValues)}
        style={{
          position: 'absolute',
          top: '7px',
          right: '7px',
          color: PrimaryTextColor,
          fontSize: '26px',
          cursor: 'pointer',
        }}
      ></MdKeyboardArrowDown>
      <ReadOnlyInputText
        disabled
        onChange={(e) => onChange(e.target.value)}
        value={value.name}
        style={{
          color: PrimaryTextColor,
          cursor: 'pointer',
          width: '100%',
          boxSizing: 'border-box',
          border: 'solid 1px #c9c9c9',
          padding: '5px',
          textIndent: '0px',
          paddingLeft: '35px',
          paddingRight: '45px',
        }}
      ></ReadOnlyInputText>
      {openValues && listValues.length > 0 ? (
        <CustomSelectDiv onMouseLeave={reset} fontColor={PrimaryTextColor}>
          <ul style={{ listStyle: 'none', padding: '0px', margin: '0px' }}>
            {listValues.map((p, idx) => (
              <CustomSelectItem
                onClick={() => selectValue(p)}
                bgColor={TextColor}
                key={idx}
              >
                {p.name}
              </CustomSelectItem>
            ))}
          </ul>
        </CustomSelectDiv>
      ) : openValues ? (
        <CustomSelectDiv onMouseLeave={reset}>
          <div style={{ boxSizing: 'border-box', padding: '10px' }}>
            Nenhuma opção encontrada
          </div>
        </CustomSelectDiv>
      ) : (
        <></>
      )}
    </div>
  );
};
