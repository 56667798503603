import React from 'react';
import { Modal } from '../common/Modal';
import { Box, Button, Typography } from '@mui/material';
import { ComboBox } from '../common/Combobox';
import { DatePicker } from '../common/DatePicker';
import { SapNumberInput } from '../SapNumberInput';
import {
  Body,
  Header,
  Title,
  HighlightedText,
  StatusBox,
  DeliveryAndClassificationBox,
  DeliveryBox,
  ClassificationBox,
  SubTitle,
  SapDeliveryWindowAndTriageBox,
  DeliveryWindowRadioButtonBox,
  ResponsibleOperatorText,
} from './styles';
import { Checkbox } from '../common/Checkbox';
import { MultipleSelect } from '../common/MultipleSelect';
import { useOrders } from '../../features/useOrders';
import { TriageField } from '../triage';
import { Tooltip } from '../common/Tooltip';
import { UserHelper } from '../../helpers/UserHelper';
import { CurrentUser } from '../../types/User';

type EditOrderModalProps = {
  open: boolean;
  handleClose: () => void;
  order_id?: number;
  refreshOrdersTable: () => void;
};

export const EditOrderModal: React.FC<EditOrderModalProps> = ({
  open,
  handleClose,
  order_id,
  refreshOrdersTable,
}) => {
  const {
    order,
    setOrder,
    errorValidationMessage,
    visibleSelectItems,
    handleChangeStatus,
    handleChangeStatusComplement,
    handleChangeDeliveryOperation,
    handleChangeServiceEvent,
    handleChangeRetreatReason,
    handleCheckDeliveryWindow,
    handleChangeDistributionCenter,
    
    fetchCurrentOrder,
    getMultiSelectData,
    handleSave,
    handleClickTakeResponsibility,
  } = useOrders({
    refreshOrdersTable,
    handleClose,
    order_id,
  });

  const [currentUser, setCurrentUser] = React.useState<
    CurrentUser | undefined
  >();

  React.useEffect(() => {
    setCurrentUser(UserHelper.getCurrentUser());
    getMultiSelectData();
    fetchCurrentOrder(order_id);
  }, [open]);

  return (
    <Modal open={open} handleClose={handleClose}>
      <Header>
        <Title variant="h4">Edição de Pedido</Title>
        {order.responsible_operator?.cognito_id === currentUser?.username ? (
          <ResponsibleOperatorText variant="body1">
            Você é o atual responsável por esse pedido
          </ResponsibleOperatorText>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleClickTakeResponsibility()}
          >
            Assumir responsabilidade
          </Button>
        )}
      </Header>
      <Body>
        <Box>
          <Typography variant="body1">
            <HighlightedText>Pedido:</HighlightedText>{' '}
            {order?.request_number_temp}
          </Typography>
          <Typography variant="body1">
            <HighlightedText>Operador Responsável:</HighlightedText>{' '}
            {order?.responsible_operator?.name ?? '?'}
          </Typography>
          <Typography variant="body1">
            <HighlightedText>Tipo de Pedido:</HighlightedText>{' '}
            {order?.request_type}
          </Typography>
        </Box>
        <StatusBox>
          <ComboBox
            label="Status"
            value={order.status_id}
            onChange={handleChangeStatus}
            items={visibleSelectItems.status}
            sx={{ width: '20.5%' }}
          />
          <ComboBox
            label="Complemento do Status"
            value={order.status_complement_id}
            onChange={handleChangeStatusComplement}
            items={visibleSelectItems.statusComplements}
            errorHelperText={errorValidationMessage.statusComplement}
            sx={{ width: '40.5%' }}
          />
          <ComboBox
            label="Filial Distribuidora"
            value={order.distribution_center}
            onChange={handleChangeDistributionCenter}
            items={visibleSelectItems.distributionCenters}
            errorHelperText={errorValidationMessage.distributionCenters}
            disabled={false}
            sx={{ width: '20.5%' }}
          />
        </StatusBox>

        <DeliveryAndClassificationBox>
          <DeliveryBox>
            <SubTitle variant="h5">Entrega</SubTitle>

            <DatePicker
              label="Data do pedido"
              handleChange={(date) =>
                setOrder({ ...order, delivery_forecast: date })
              }
              value={order.delivery_forecast}
            />
          </DeliveryBox>

          <ClassificationBox>
            <SubTitle variant="h5">Classificação do Pedido</SubTitle>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <MultipleSelect
                label="Operações de entrega"
                value={order.delivery_operations}
                onChange={handleChangeDeliveryOperation}
                items={visibleSelectItems.deliveryOperations}
                sx={{ width: '30%' }}
              />

              <MultipleSelect
                label="Eventos do atendimento"
                value={order.service_events}
                onChange={handleChangeServiceEvent}
                items={visibleSelectItems.serviceEvents}
                sx={{ width: '30%' }}
              />

              <Tooltip
                title={
                  visibleSelectItems.retreatReasons.length === 0
                    ? 'Opção disponível apenas para pedidos de recolhimento'
                    : undefined
                }
                sx={{ width: '30%' }}
              >
                <ComboBox
                  label="Motivos do recolhimento"
                  value={order.retreat_reasons}
                  onChange={handleChangeRetreatReason}
                  items={visibleSelectItems.retreatReasons}
                  errorHelperText={errorValidationMessage.retreatReason}
                />
              </Tooltip>
            </Box>
          </ClassificationBox>
        </DeliveryAndClassificationBox>

        <SapDeliveryWindowAndTriageBox>
          <DeliveryWindowRadioButtonBox>
            <Checkbox
              row
              items={order.delivery_window}
              handleCheck={handleCheckDeliveryWindow}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '15px',
              }}
            />
            <SapNumberInput
              numbers={order.request_number}
              setNumbers={(newNumbers: string[]) =>
                setOrder({ ...order, request_number: newNumbers })
              }
            />
          </DeliveryWindowRadioButtonBox>
          {currentUser?.isWhiteMartins || currentUser?.isAdmin ? (
            <Box sx={{ width: '78%' }}>
              <TriageField
                orderId={order_id}
                inputField={true}
                showTitle={true}
                open={open}
              ></TriageField>
            </Box>
          ) : (
            <></>
          )}
        </SapDeliveryWindowAndTriageBox>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="contained" onClick={handleSave}>
            Salvar
          </Button>
        </Box>
      </Body>
    </Modal>
  );
};
