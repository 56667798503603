import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

type ModalBodyStyleProps = {
  mobile?: string;
  width?: string;
};

export const ModalBody = styled(Box)<ModalBodyStyleProps>(
  ({ theme, mobile, width }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: width ?? (mobile ? '90%' : '70%'),
    maxHeight: '90%',
    background: theme.colors.background.primary,
    padding: '20px',
    borderRadius: '10px',
    outline: 'none',
    overflowY: 'scroll',

    '&::-webkit-scrollbar': {
      display: 'none',
    },

    '& h2': {
      textAlign: 'center',
    },
  })
);
