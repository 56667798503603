enum DistributionCenter {
  Empty = 'none',
  Diadema = '2421',
  Guarulhos = '2403',
  Vinhedo = '2312',
  Santos = 'U290',
  Sorocaba = '2380',
  Piracicaba = '2803',
  SJC = 'U313',
  MogiDasGuacu = 'U305',
  MogiDasCruzes = 'U965',
}

export const predefinedDistributionCenters = [
  { label: ' - ', value: DistributionCenter.Empty },
  { label: 'Diadema', value: DistributionCenter.Diadema },
  { label: 'Guarulhos', value: DistributionCenter.Guarulhos },
  { label: 'Vinhedo', value: DistributionCenter.Vinhedo },
  { label: 'Santos', value: DistributionCenter.Santos },
  { label: 'Sorocaba', value: DistributionCenter.Sorocaba },
  { label: 'Piracicaba', value: DistributionCenter.Piracicaba },
  { label: 'São José dos Campos', value: DistributionCenter.SJC },
  { label: 'Mogi das Guaçu', value: DistributionCenter.MogiDasGuacu },
  { label: 'Mogi das Cruzes', value: DistributionCenter.MogiDasCruzes },
];

export function getDistributionCenterName(code: string): string {
  const center = predefinedDistributionCenters.find(dc => dc.value === code);
  return center ? center.label : '';
}

export function getDistributionCenterCode(name: string): string {
  const center = predefinedDistributionCenters.find(dc => dc.label === name);
  return center ? center.value : '';
}