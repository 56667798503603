import { useMediaQuery } from 'react-responsive';
import { Backdrop } from '../../base/backdrop';
import { PrimaryBackground } from '../../base/colors';

export const SimplePopup = ({ width, height, outlet, zIndex }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  return (
    <>
      {isTabletOrMobile ? (
        <Backdrop style={{ zIndex }}>
          <div
            style={{
              padding: '5px',
              boxSizing: 'border-box',
              width: `100%`,
              backgroundColor: PrimaryBackground,
              marginTop: `0px`,
              overflow: 'scroll',
              height: `${height}px`,
            }}
          >
            {outlet}
          </div>
        </Backdrop>
      ) : (
        <Backdrop style={{ zIndex }}>
          <div
            style={{
              overflowX: 'hidden',
              padding: '15px',
              boxSizing: 'border-box',
              width: `${width}px`,
              height: `${height}px`,
              backgroundColor: PrimaryBackground,
              borderRadius: '15px',
              marginLeft: `calc(50% - ${width / 2}px)`,
              marginTop: `100px`,
            }}
          >
            {outlet}
          </div>
        </Backdrop>
      )}
    </>
  );
};
