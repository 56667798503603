import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { GiHamburgerMenu } from 'react-icons/gi';
import { CgProfile } from 'react-icons/cg';
import { useState } from 'react';
import {
  BoldTextColor,
  PrimaryBackground,
  PrimaryTextColor,
  ShadowColor,
} from '../../base/colors';
import { usePortalStore } from '../../store';
import { LoginService } from '../../services/auth';
// import { Notifications } from './Notifications';

export const MainHeaderBase = styled.div`
  background: rgb(8, 77, 1);
  background: linear-gradient(
    90deg,
    rgba(8, 77, 1, 1) 0%,
    rgba(8, 77, 1, 1) 47%,
    rgba(49, 131, 0, 1) 100%
  );
  display: flex;
  height: 60px;
  position: relative;
`;

const LogoTile = styled.div`
  width: 220px;
  height: auto;
  position: relative;
  background-image: url(/icones/logo.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;
const MenuButtonTile = styled.div`
  color: white;
  text-align: center;
  line-height: 60px;
  min-width: 60px;
  height: 60px;
  position: relative;
  cursor: pointer;
  font-size: 24px;
`;

const GlobalSearchTile = styled.div`
  width: 100%;
  height: 60px;
  position: relative;
`;

const SpacerTile = styled.div`
  width: 100%;
  height: 60px;
  position: relative;
`;

const ProfileTile = styled.div`
  color: white;
  text-align: center;
  height: 60px;
  position: relative;
  cursor: pointer;
  align-items: center;
  display: flex;
  padding: 0 20px;
`;

const ProfilePopupTile = styled.div`
  background-color: ${PrimaryBackground};
  min-width: 280px;
  position: absolute;
  cursor: auto;
  left: -240px;
  border-radius: 15px;
  box-shadow: 0px 0px 10px ${ShadowColor};
  display: block;
  padding: 20px;
  box-sizing: border-box;
  z-index: 2;
`;

const MenuButton = () => {
  const setMenuOpenState = usePortalStore((state) => state.setMenuOpenState);
  const menuOpenState = usePortalStore((state) => state.ui.menuOpenState);
  return (
    <MenuButtonTile>
      <GiHamburgerMenu
        onClick={() => {
          setMenuOpenState(!menuOpenState);
        }}
      ></GiHamburgerMenu>
    </MenuButtonTile>
  );
};

const ProfileButton = () => {
  const [showPopup, setShowPopup] = useState(false);
  const currentUser = usePortalStore((state) => state.currentUser);
  const closeTimeout = () => {
    setTimeout(() => {
      setShowPopup(false);
    }, 5000);
  };
  const signout = async () => {
    await LoginService.signOut();
  };
  return (
    <ProfileTile>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ fontSize: '16px', marginRight: '5px', marginTop: '' }}>
          Perfil
        </div>
        <div>
          <CgProfile
            style={{ fontSize: '28px' }}
            onClick={() => {
              setShowPopup(!showPopup);
              if (!showPopup) {
                closeTimeout();
              }
            }}
          ></CgProfile>

          {showPopup ? (
            <ProfilePopupTile>
              <div style={{ color: BoldTextColor, fontWeight: 'bold' }}>
                {currentUser.companyName}
              </div>
              <div
                style={{
                  color: PrimaryTextColor,
                  fontWeight: '300',
                  marginTop: '15px',
                }}
              >
                Email:{currentUser.email}
              </div>
              <div
                style={{
                  color: PrimaryTextColor,
                  fontWeight: '300',
                  marginTop: '15px',
                }}
              >
                CNPJ:{currentUser.cnpj}
              </div>
              <hr style={{ marginTop: '20px', marginBottom: '20px' }}></hr>

              <div
                style={{
                  color: BoldTextColor,
                  fontWeight: '600',
                  marginTop: '15px',
                  cursor: 'pointer',
                }}
                onClick={signout}
              >
                Sair
              </div>
            </ProfilePopupTile>
          ) : (
            <></>
          )}
        </div>
      </div>
    </ProfileTile>
  );
};

export const MainHeader = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  return (
    <>
      <MainHeaderBase>
        {!isTabletOrMobile ? (
          <>
            <LogoTile></LogoTile>
            <MenuButton></MenuButton>
            <GlobalSearchTile></GlobalSearchTile>
            {/* <Notifications></Notifications> */}
            <ProfileButton></ProfileButton>
          </>
        ) : (
          <>
            <LogoTile></LogoTile>
            <MenuButton></MenuButton>
            <SpacerTile></SpacerTile>
            {/* <Notifications></Notifications> */}
            <ProfileButton></ProfileButton>
          </>
        )}
      </MainHeaderBase>
    </>
  );
};

export const ReadOnlyMainHeader = () => {
  return (
    <MainHeaderBase>
      <LogoTile></LogoTile>
    </MainHeaderBase>
  );
};
