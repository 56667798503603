import React from 'react';
import { PatientsForm } from '../../usecase/patients/form';
import { FiEdit2 } from 'react-icons/fi';
import { usePortalStore } from '../../store';
import { PrimaryTitleGray } from '../../base/colors';
import { Modal } from '../common/Modal';
import { Box, Button } from '@mui/material';
import { Switch, FormControlLabel } from '@mui/material';
import { usePatientsStore } from '../../stores/patientsStore';
import { IconButton, SvgIcon } from '@mui/material';
import { Tooltip } from '../common/Tooltip';
import { colors } from '../../themes/core/colors';

type EditPatientPopupProps = {
  open: boolean;
  handleClose: () => void;
  handleSave: () => void;
};

export const EditPatientModal: React.FC<EditPatientPopupProps> = ({
  open,
  handleClose,
  handleSave,
}) => {
  const currentUser = usePortalStore((state: any) => state.currentUser);
  const newPat = usePatientsStore((state) => state.newPat);

  const [editModeOn, setEditModeOn] = React.useState(false);

  const onPaymentList = usePatientsStore((state) => state.onPaymentList);
  const setOnPaymentList = usePatientsStore((state) => state.setOnPaymentList);
  const updateOnPaymentList = async (e: any) => {
    setOnPaymentList(e.target.checked);
  };

  React.useEffect(() => {
    setEditModeOn(newPat ? true : false);
  }, [open]);

  return (
    <Modal open={open} handleClose={handleClose} width="650">
      <div
        style={{
          display: 'flex',
          marginBottom: '20px',
          boxSizing: 'border-box',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                fontSize: '18px',
                color: PrimaryTitleGray,
                fontWeight: 700,
              }}
            >
              Paciente
            </div>
            {(currentUser.isWhiteMartins || currentUser.isAdmin) && !newPat && (
              <Tooltip title="Editar">
                <IconButton
                  sx={{ marginLeft: '20px' }}
                  onClick={() => setEditModeOn(!editModeOn)}
                  color="primary"
                >
                  <SvgIcon fontSize="medium" htmlColor={colors.text.primary}>
                    <FiEdit2 className="hoverButton"></FiEdit2>
                  </SvgIcon>
                </IconButton>
              </Tooltip>
            )}
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Switch
                  checked={onPaymentList}
                  onChange={updateOnPaymentList}
                ></Switch>
              }
              label="Em lista de pagamento"
              labelPlacement="start"
              sx={{
                margin: 'auto',
                visibility: editModeOn || newPat ? 'visible' : 'hidden',
              }}
            ></FormControlLabel>
          </Box>
        </Box>
      </div>
      <PatientsForm disabled={!editModeOn}></PatientsForm>
      <div
        style={{
          display: 'flex',
          marginTop: '30px',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <Button variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
        </div>
        {(editModeOn || newPat) && (
          <div>
            <Button variant="contained" onClick={handleSave}>
              Salvar
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};
