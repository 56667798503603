import { Auth, API } from 'aws-amplify';
export const doRequest = async (
  method,
  headers,
  url,
  body,
  bypassCache = true
) => {
  let resp = null;
  const parts = url.split('?');
  const params = parts[1];
  const queryStringParameters = {};
  if (params) {
    const parameters = params.split('&');
    parameters.forEach((p) => {
      const pp = p.split('=');
      queryStringParameters[pp[0]] = pp[1];
    });
  }

  try {
    if (method === 'GET') {
      resp = await API.get(
        'portalApi',
        `${process.env.REACT_APP_ENDPOINT_PREFIX}${parts[0]}`,
        {
          queryStringParameters: queryStringParameters,
          response: true,
        }
      );
    } else if (method === 'POST') {
      resp = await API.post(
        'portalApi',
        `${process.env.REACT_APP_ENDPOINT_PREFIX}${parts[0]}`,
        {
          queryStringParameters: queryStringParameters,
          response: false,
          body: body,
        }
      );
    } else if (method === 'PUT') {
      resp = await API.put(
        'portalApi',
        `${process.env.REACT_APP_ENDPOINT_PREFIX}${parts[0]}`,
        {
          queryStringParameters: queryStringParameters,
          response: true,
          body: body,
        }
      );
    } else if (method === 'DELETE') {
      resp = await API.del(
        'portalApi',
        `${process.env.REACT_APP_ENDPOINT_PREFIX}${parts[0]}`,
        {
          queryStringParameters: queryStringParameters,
          response: true,
        }
      );
    }
    const respJ = await resp.data;
    if (!respJ) {
      return resp;
    }
    return respJ;
  } catch (e) {
    console.error(e);
    if (e.response && e.response.status) {
      return {
        httpStatus: e.response.status,
        body: e.response.data,
      };
    }
    throw e;
  }
};

export const doGet = async (url, options) => {
  if (options?.cache) {
    const strCache = sessionStorage.getItem(url);
    if (strCache) {
      const record = JSON.parse(strCache);
      if (
        record.timestamp >= new Date().getTime() &&
        record.record.httpStatus === 200
      ) {
        return record.record;
      }
    }
  }
  const resp = await doRequest('GET', {}, url);
  if (options?.cache && resp.httpStatus === 200) {
    sessionStorage.setItem(
      url,
      JSON.stringify({
        timestamp:
          new Date().getTime() + 1000 * (options.expireInSeconds ?? 30),
        record: resp,
      })
    );
  }
  return resp;
};

export const doPost = (url, data) => {
  return doRequest('POST', { 'Content-Type': 'application/json' }, url, data);
};

export const doDelete = (url, data) => {
  return doRequest('DELETE', {}, url, data);
};

export const doPut = (url, data) => {
  return doRequest('PUT', {}, url, data);
};
