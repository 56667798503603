export const PrimaryBackground = '#fdfdfd';
export const ErrorRed = '#ff070094';
export const BackgroundColor = '#ececec';
export const TextColor = '#b6b6b6';
export const DarkGreen = '#084d01';
export const BrighterGreen = '#318300';
export const BlueText = '#00a0e1';
export const BlueTextDark = '#005591';
export const PrimaryTextColor = '#919191';
export const BoldTextColor = '#565656';
export const ShadowColor = '#d3d3d3';
export const Green2 = '#63c11d';
export const Green3 = '#318300';
export const LimeGreen = '#becd00';
export const Green4 = '#58a61e';
export const PrimaryTitleGray = '#000000';
export const GreenAlarm = '#34ee16';
export const YellowAlarm = '#F7f204';
export const RedAlarm = '#F72604';
export const PurpleAlarm = '#9e04f7';
export const WhiteAlarm = '#F9f5f9';
export const OrangeAlarm = '#ffa500';
