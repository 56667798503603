import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

export const Header = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '5px',
}));

export const Body = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
}));

export const Title = styled(Typography)(() => ({
  fontWeight: '700',
}));

export const SubTitle = styled(Typography)(() => ({
  marginBottom: '12px',
  fontWeight: '700',
}));

export const HighlightedText = styled('span')(({ theme }) => ({
  fontWeight: '700',
  color: theme.colors.main,
}));

export const StatusBox = styled(Box)(() => ({
  display: 'flex',
  gap: '30px',
  width: '100%',
}));

export const DeliveryAndClassificationBox = styled(Box)(() => ({
  display: 'flex',
  gap: '30px',
}));

export const DeliveryBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '26.5%',
}));

export const ClassificationBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

export const SapDeliveryWindowAndTriageBox = styled(Box)(() => ({
  display: 'flex',
  gap: '30px',
}));

export const DeliveryWindowRadioButtonBox = styled(Box)(() => ({
  display: 'flex',
  width: '21%',
  flexDirection: 'column',
}));

export const TriageBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  background: '#E9E9E9',
  height: '200px',
  borderRadius: '10px',
  padding: '10px',
}));

export const ResponsibleOperatorText = styled(Typography)(({ theme }) => ({
  fontStyle: 'italic',
  marginBottom: '15px',
  color: theme.colors.main,
}));
