import React from 'react';
import { IconButton } from '@mui/material';
import { TextInput } from '../common/TextInput';
import {
  AddCircle as AddCircleIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material';
import {
  AddIconWrapper,
  SapNumberInputContainer,
  TextFieldAndDeleteWrapper,
  TextFieldScrollableWrapper,
} from './styles';

type SapNumberInputProps = {
  numbers: string[];
  setNumbers: (newNumbers: string[]) => void;
};

export const SapNumberInput: React.FC<SapNumberInputProps> = ({
  numbers,
  setNumbers,
}) => {
  return (
    <SapNumberInputContainer>
      <TextFieldScrollableWrapper>
        {numbers?.map((number, idx) => (
          <TextFieldAndDeleteWrapper key={idx}>
            <TextInput
              label="Nº Pedido SAP"
              value={number}
              handleChange={(e) => {
                const newNumbers = [...numbers];
                newNumbers[idx] = e.target.value;
                setNumbers(newNumbers);
              }}
              inputProps={{ maxLength: 8 }}
            />
            <IconButton
              onClick={() => {
                const indexOfNumber = numbers.indexOf(number);
                const filteredNumbers = numbers.filter(
                  (_, i) => i !== indexOfNumber
                );
                setNumbers(filteredNumbers);
              }}
            >
              <CancelIcon />
            </IconButton>
          </TextFieldAndDeleteWrapper>
        ))}
        <AddIconWrapper>
          <IconButton onClick={() => setNumbers([...numbers, ''])}>
            <AddCircleIcon />
          </IconButton>
        </AddIconWrapper>
      </TextFieldScrollableWrapper>
    </SapNumberInputContainer>
  );
};
