import { useEffect, useState } from 'react';
import { BiErrorCircle } from 'react-icons/bi';
import { CgShoppingCart } from 'react-icons/cg';
import { MdOutlineArrowForwardIos } from 'react-icons/md';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  BackgroundColor,
  BrighterGreen,
  ErrorRed,
  PrimaryTitleGray,
  DarkGreen,
} from '../../base/colors';
import { PrimaryButton } from '../../base/Inputs';
import { ORDER_FLOW_STEPS, usePortalStore } from '../../store';
import { usePatientsStore } from '../../stores/patientsStore';
import { MainHeader } from '../header/header';
import { Menu } from '../menu/menu';
import { SimplePopup } from '../popup';
import { Alert, Slide, Snackbar } from '@mui/material';
import { useSelector } from 'react-redux';
import { useSnackbar } from '../../hooks/useSnackbar';

const Disclaimer = () => {
  return (
    <div
      style={{
        position: 'fixed',
        bottom: '0px',
        left: '0px',
        right: '0px',
        height: '70px',
        zIndex: '2000',
        backgroundColor: 'white',
        boxSizing: 'border-box',
        border: `1px solid ${DarkGreen}`,
      }}
    >
      <p style={{ margin: '25px', fontWeight: 'bold' }}>
        Os dados utilizados pela companhia nesta plataforma são fictícios e
        meramente ilustrativos. A White Martins atua em conformidade com os
        termos da Lei de Geral de Proteção de Dados (Lei 13.709 de 14 de agosto
        de 2018) e se compromete com a privacidade dos dados de seus clientes e
        parceiros.
      </p>
    </div>
  );
};
export const WideLayout = (props) => {
  const snackbarState = useSelector((state) => state.snackbar);
  const snackbar = useSnackbar();
  let style = { backgroundColor: BackgroundColor, height: '100vh' };
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  let [searchParams, setSearchParams] = useSearchParams();
  const breadcrumb = usePortalStore((state) => state.breadcrumb);
  const setSelectedCategory = usePortalStore(
    (state) => state.setSelectedCategory
  );
  const currentAlertError = usePortalStore((state) => state.currentAlertError);
  const setCurrentAlertError = usePortalStore(
    (state) => state.setCurrentAlertError
  );
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const setBreadcrumb = usePortalStore((state) => state.setBreadcrumb);
  const ui = usePortalStore((state) => state.ui);
  if (!isTabletOrMobile) {
    style.width = '100%';
    style.margin = 'auto auto';
  }
  const location = useLocation();

  const isOnOrderFlow = () => location.pathname.indexOf('/order/') === 0;
  const isOnAssistanceFlow = () =>
    location.pathname.indexOf('/technicalAssistance/') === 0;
  const isOnCollectFlow = () =>
    location.pathname.indexOf('/collectEquipments/') === 0;
  const isOnRechargeFlow = () => location.pathname.indexOf('/recharge/') === 0;
  const setMenuOpenState = usePortalStore((state) => state.setMenuOpenState);
  const clearPatientsList = usePatientsStore(
    (state) => state.clearPatientsList
  );
  const shouldShowBreadcrumb = () =>
    isOnOrderFlow() ||
    isOnAssistanceFlow() ||
    isOnCollectFlow() ||
    isOnRechargeFlow();
  useEffect(() => {
    if (shouldShowBreadcrumb()) {
      if (location.pathname.endsWith('/newOrder')) {
        setBreadcrumb(ORDER_FLOW_STEPS.SELECT_AREA);
      } else if (location.pathname.endsWith('/selectPatient')) {
        clearPatientsList();
        setBreadcrumb(ORDER_FLOW_STEPS.SELECT_PATIENT);
      } else if (location.pathname.endsWith('/selectCategory')) {
        setBreadcrumb(ORDER_FLOW_STEPS.SELECT_CATEGORY);
      } else if (location.pathname.indexOf('/selectSubCategory') > 0) {
        const category = searchParams.get('category');
        const categoryId = searchParams.get('categoryId');
        setSelectedCategory(category, categoryId);
        setBreadcrumb(ORDER_FLOW_STEPS.SELECT_SUBCATEGORY);
      } else if (location.pathname.endsWith('/configureEquipment')) {
        setBreadcrumb(ORDER_FLOW_STEPS.CONFIGURE_EQUIPMENT);
      } else if (location.pathname.endsWith('/finish')) {
        setBreadcrumb(ORDER_FLOW_STEPS.FINISH_ORDER);
      } else if (location.pathname.endsWith('/assistanceToPatient')) {
        clearPatientsList();
        setBreadcrumb(ORDER_FLOW_STEPS.SELECT_PATIENT_ASSISTANCE);
      } else if (location.pathname.endsWith('/assistance')) {
        setBreadcrumb(ORDER_FLOW_STEPS.EQUIPMENT_ASSISTANCE);
      } else if (location.pathname.endsWith('/finishAssistanceOrder')) {
        setBreadcrumb(ORDER_FLOW_STEPS.FINISH_ASSISTANCE_ORDER);
      } else if (location.pathname.endsWith('/collectFromPatient')) {
        clearPatientsList();
        setBreadcrumb(ORDER_FLOW_STEPS.SELECT_PATIENT_COLLECTION);
      } else if (location.pathname.endsWith('/collectEquipments')) {
        setBreadcrumb(ORDER_FLOW_STEPS.COLLECT_EQUIPMENT);
      } else if (location.pathname.endsWith('/finishCollectEquipments')) {
        setBreadcrumb(ORDER_FLOW_STEPS.FINISH_COLLECT_ORDER);
      } else if (location.pathname.endsWith('/rechargePatient')) {
        clearPatientsList();
        setBreadcrumb(ORDER_FLOW_STEPS.SELECT_PATIENT_RECHARGE);
      } else if (location.pathname.endsWith('/finishRecharge')) {
        setBreadcrumb(ORDER_FLOW_STEPS.FINISH_RECHARGE_ORDER);
      }
    }
  }, []);
  const navigate = useNavigate();
  return (
    <div style={style}>
      {showDisclaimer ? <Disclaimer></Disclaimer> : <></>}
      {currentAlertError !== '' ? (
        <SimplePopup
          zIndex={10000}
          width={600}
          outlet={
            <div>
              <div className="error-message">
                <h3 className="erro-text">
                  <BiErrorCircle
                    style={{
                      color: ErrorRed,
                      fontWeight: '500',
                      fontSize: '32px',
                    }}
                  />
                  Atenção!
                </h3>
              </div>
              <div>
                <p style={{ textAlign: 'center' }}>{currentAlertError}</p>
              </div>
              <div style={{ marginTop: '20px', textAlign: 'center' }}>
                <PrimaryButton onClick={() => setCurrentAlertError('')}>
                  Ok
                </PrimaryButton>
              </div>
            </div>
          }
        ></SimplePopup>
      ) : (
        <></>
      )}
      <MainHeader></MainHeader>
      <div
        className="content-main"
        style={{
          display: 'block',
          overflow: 'hidden',
          position: 'relative',
          height: 'calc(100vh - 60px)',
        }}
      >
        <Menu></Menu>
        {!isTabletOrMobile ? (
          <div
            className="content-stage"
            style={{
              marginLeft: ui.menuOpenState ? '200px' : '0px',
              background: '#ececec',
              flex: '1',
              height: 'calc(100vh - 60px)',
            }}
          >
            {shouldShowBreadcrumb() ? (
              <div
                className="breadcrumb"
                style={{
                  width: '100%',
                  boxSizing: 'border-box',
                  padding: '10px',
                  fontSize: '16px',
                  lineHeight: '16px',
                }}
              >
                {breadcrumb.map((x, idx) => (
                  <div
                    className="breadcrumb-value"
                    key={idx}
                    style={{ display: 'inline-flex' }}
                  >
                    {idx > 0 ? (
                      <div
                        style={{ paddingLeft: '10px', paddingRight: '10px' }}
                      >
                        <MdOutlineArrowForwardIos></MdOutlineArrowForwardIos>{' '}
                      </div>
                    ) : (
                      <></>
                    )}
                    {idx === 0 ? (
                      <div className="icon-class">
                        <CgShoppingCart></CgShoppingCart>{' '}
                      </div>
                    ) : (
                      <></>
                    )}
                    <div
                      className="breadcrumb-selected"
                      onClick={() => navigate(x.link)}
                      style={{
                        cursor: 'pointer',
                        color:
                          idx === breadcrumb.length - 1
                            ? BrighterGreen
                            : PrimaryTitleGray,
                        fontWeight: idx === breadcrumb.length - 1 ? 'bold' : '',
                      }}
                    >
                      {x.label}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <></>
            )}
            <div
              className={
                showDisclaimer
                  ? 'content-stage-disclaimer'
                  : 'content-stage-default'
              }
              style={{
                width: '100%',
                position: 'relative',
                overflow: 'auto',
                height: '100%',
              }}
            >
              {props.outlet ? props.outlet : <></>}
            </div>
          </div>
        ) : (
          <>
            {shouldShowBreadcrumb() ? (
              <div
                style={{
                  width: '100%',
                  boxSizing: 'border-box',
                  padding: '10px',
                  color: PrimaryTitleGray,
                  fontSize: '16px',
                  lineHeight: '16px',
                }}
              >
                {breadcrumb.map((x, idx) => (
                  <div key={idx} style={{ display: 'inline-flex' }}>
                    {idx > 0 ? (
                      <div
                        style={{ paddingLeft: '10px', paddingRight: '10px' }}
                      >
                        <MdOutlineArrowForwardIos></MdOutlineArrowForwardIos>{' '}
                      </div>
                    ) : (
                      <></>
                    )}
                    {idx === 0 ? (
                      <div className="icon-class">
                        <CgShoppingCart></CgShoppingCart>{' '}
                      </div>
                    ) : (
                      <></>
                    )}
                    <div
                      onClick={() => navigate(x.link)}
                      style={{
                        fontSize: '18px!important',
                        color:
                          idx === breadcrumb.length - 1
                            ? BrighterGreen
                            : PrimaryTitleGray,
                        fontWeight: idx === breadcrumb.length - 1 ? 'bold' : '',
                      }}
                    >
                      {x.label}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <></>
            )}
            <div
              style={{
                width: '100%',
                position: 'relative',
                overflow: 'auto',
                height: showDisclaimer ? 'calc(100vh - 160px)' : '100%',
              }}
            >
              {props.outlet ? props.outlet : <></>}
            </div>
          </>
        )}
      </div>

      <Snackbar
        open={snackbarState.open}
        autoHideDuration={1000}
        TransitionComponent={Slide}
        onClose={() => snackbar.hide()}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => snackbar.hide()}
          severity={snackbarState.type}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarState.message}
        </Alert>
      </Snackbar>
    </div>
  );
};
