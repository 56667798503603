import React from 'react';
import { useAlarms } from '../../../features/useAlarms';
import { StyledAlarmLamp } from './styles';
import { Alarm } from '../../../types/Alarms';

type AlarmLampProps = {
  alarm: Alarm;
};

export const AlarmLamp: React.FC<AlarmLampProps> = ({ alarm }) => {
  const { color, setAlarmColor, isFlashing } = useAlarms({
    alarm: alarm,
  });

  React.useEffect(() => {
    setAlarmColor();
  }, [alarm]);

  return <StyledAlarmLamp color={color} is_flashing={isFlashing.toString()} />;
};
