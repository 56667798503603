import { IconPNG } from '../common/IconPNG';
type ExchangeIconProps = {
  width?: string;
  height?: string;
};

export function ExchangeIcon({ width, height }: ExchangeIconProps) {
  const path = '/icones/exchange.png';
  return (
    <IconPNG
      src={path}
      alt="cylinder logo"
      height={height}
      width={width}
    ></IconPNG>
  );
}
