import styled from 'styled-components';
import { ShadowColor } from '../../base/colors';

export const Panel = styled.div`
  background-color: white;
  border-radius: 8px;
  box-sizing: border-box;
  width: ${(props) => (props.width ? props.width : '100%')};
  box-shadow: 2px 10px 8px ${ShadowColor};
  overflow: ${(props) => (props.overflow ? props.overflow : 'hidden')};
`;
//max-width:${props => props.maxWidth ? props.maxWidth : `${window.innerWidth-15}px`};
