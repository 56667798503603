import { TypographyOptions } from '@mui/material/styles/createTypography';

const fontFamily = 'Roboto, sans-serif';

export const typography: TypographyOptions = {
  // Heading 1 (h1)
  h1: {
    margin: 0,
    fontSize: '3rem',
    fontFamily: fontFamily,
  },
  // Heading 2 (h2)
  h2: {
    margin: 0,
    fontSize: '2.5rem',
    fontFamily: fontFamily,
  },
  // Heading 3 (h3)
  h3: {
    margin: 0,
    fontSize: '2rem',
    fontFamily: fontFamily,
  },
  // Heading 4 (h4)
  h4: {
    margin: 0,
    padding: 0,
    fontSize: '1.5rem',
    fontFamily: fontFamily,
  },
  // Heading 5 (h5)
  h5: {
    margin: 0,
    fontSize: '1rem',
    fontFamily: fontFamily,
  },
  // Heading 6 (h6)
  h6: {
    margin: 0,
    fontSize: '0.875rem',
    fontFamily: fontFamily,
  },
  // Text Link (a)
  caption: {
    margin: 0,
    fontSize: '0.875rem',
    fontFamily: fontFamily,
  },
  // Variant between h5 and h4
  subtitle1: {
    margin: 0,
    fontSize: '1.2rem',
    fontFamily: fontFamily,
  },
  button: {
    fontSize: '0.875rem',
    textTransform: 'none',
  },
  // Label (label)
  subtitle2: {
    margin: 0,
    fontSize: '0.875rem',
    fontFamily: fontFamily,
  },
  // Body Text (p)
  body1: {
    fontSize: '0.875rem',
    padding: 0,
    margin: 0,
    fontFamily: fontFamily,
  },
  // Small Text (span)
  body2: {
    margin: 0,
    fontSize: '0.8rem',
    fontFamily: fontFamily,
  },
};
