import { useEffect, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import { useMediaQuery } from 'react-responsive';
import { PrimaryTitleGray } from '../../base/colors';
import { TextAreaInput } from '../../base/Inputs';
import { Table } from '../../base/table';
import { ImageSlider } from '../../components/image/slider';
import { saveAssistanceOrder } from '../../services/assistanceService';
import { getProductsImages } from '../../services/productService';
import { usePortalStore } from '../../store';
import { useTechnicalAssistanceStore } from '../../stores/technicalAssistanceStore';
import { DefaultFinishOrder } from '../../usecase/orders/defaultFinishOrder';
import { getBase64Img, genericOrderCode } from '../../utils/utils';

export const FinishAssistanceOrder = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const currentUser = usePortalStore((state) => state.currentUser);
  const setCurrentAlertError = usePortalStore(
    (state) => state.setCurrentAlertError
  );
  const selectedEquipments = useTechnicalAssistanceStore(
    (state) => state.selectedEquipments
  );
  const selectedPatient = useTechnicalAssistanceStore(
    (state) => state.selectedPatient
  );
  const typedSearch = useTechnicalAssistanceStore((state) => state.typedSearch);
  const equipmentList = useTechnicalAssistanceStore(
    (state) => state.equipmentList
  );
  const selectedSearch = useTechnicalAssistanceStore(
    (state) => state.selectedSearch
  );
  const [techDetails, setTechDetails] = useState('');
  const [image, setImage] = useState([]);

  useEffect(() => {
    console.log('selectedEquipments:', selectedEquipments);
    (async () => {
      const codes = Object.keys(selectedEquipments).map((x) => x.toString());
      const imgs = await getProductsImages(codes);
      setImage([...imgs]);
    })();
  }, [selectedEquipments]);

  const finishOrder = async (deliveryDate, deliveryTurn) => {
    const saveTypedSearch = {};
    Object.keys(selectedEquipments).forEach((eqId) => {
      if (selectedSearch[eqId] && typedSearch[eqId]) {
        saveTypedSearch[eqId] = typedSearch[eqId];
      }
    });
    if (Object.keys(typedSearch).length === 0 && techDetails === '') {
      setCurrentAlertError(
        'Por favor, insira ao menos uma informação para continuar com o pedido.'
      );
      return null;
    }
    const orderNumber = await saveAssistanceOrder(
      currentUser,
      selectedPatient.cnpj,
      selectedPatient,
      selectedEquipments,
      deliveryDate,
      techDetails,
      deliveryTurn,
      saveTypedSearch,
      selectedSearch
    );
    console.log('selectedEquipmentsselectedEquipments:', selectedEquipments);
    return orderNumber;
  };

  const getSelectedEquipments = () => {
    const eq = [];
    if (selectedEquipments[genericOrderCode]) {
      eq.push({
        id: [genericOrderCode],
        name: 'Validação e Processamento de Pedidos',
      });
      console.log('EQ FLUX ANTIGO:', eq);
      return eq;
    }

    Object.keys(selectedEquipments).forEach((k) => {
      if (selectedEquipments[k]) {
        const equipment = equipmentList.filter(
          (x) => x.id === parseInt(k, 10) || x.id === k
        )[0];
        console.log('eq_fluxoNormal:', equipment);
        eq.push(equipment);
      }
    });

    return eq;
  };

  const topTile = () => {
    return (
      <div style={{ display: isTabletOrMobile ? 'block' : 'inline-flex' }}>
        <div>
          <ImageSlider images={image.map((x) => getBase64Img(x))}></ImageSlider>
        </div>
        <div
          style={{ flex: '1', marginLeft: isTabletOrMobile ? '0px' : '40px' }}
        >
          <div>
            {getSelectedEquipments().map((eq, idx) => (
              <div key={idx} style={{ marginBottom: '10px' }}>
                <h4
                  style={{
                    marginTop: '0px',
                    color: 'black',
                    textTransform: 'uppercase',
                    fontSize: '18px',
                    fontWeight: '500',
                    borderBottom: 'solid 1px #a7a6a6',
                  }}
                >
                  {eq.name} {eq.model}
                </h4>
                {typedSearch && typedSearch[eq.id] ? (
                  <h5>Buscas: {typedSearch[eq.id].join(', ')}</h5>
                ) : (
                  <></>
                )}
                {selectedSearch[eq.id] ? (
                  <Table
                    header={['Problema', 'Causa', 'Solução']}
                    data={(selectedSearch[eq.id] ?? [])
                      .map((x) => {
                        const c = { ...x };
                        delete c.genId;
                        return c;
                      })
                      .flat()}
                  ></Table>
                ) : (
                  <></>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const bottomTile = () => {
    return (
      <div style={{ width: '100%' }}>
        <h4
          style={{
            color: PrimaryTitleGray,
            margin: '0px',
            marginBottom: '10px',
          }}
        >
          Detalhar a ocorrência da solicitação
        </h4>
        <TextAreaInput
          onChange={(e) => setTechDetails(e.target.value)}
          style={{ minWidth: '100%', minHeight: '120px', margin: '0px' }}
        ></TextAreaInput>
      </div>
    );
  };

  return (
    <DefaultFinishOrder
      selectedPatient={selectedPatient}
      topTileRender={topTile}
      bottomTileRender={bottomTile}
      orderType={'Assistência técnica'}
      onFinish={finishOrder}
      fastDeliveryLabel={'Assistência imediata'}
      scheduleDeliveryLabel={'Assistência agendada'}
    ></DefaultFinishOrder>
  );
};
