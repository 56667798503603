import { useEffect, useState } from 'react';
import {
  MdMoreHoriz,
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from 'react-icons/md';
import styled from 'styled-components';
import { Green4 } from '../../base/colors';
import { Select } from '../../base/Inputs';

const PageButtons = styled.button`
  padding: 10px;
  margin: 2px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 600;
  background-color: white;
  border-radius: 10px;
  color: ${(props) => Green4};
  border: 1px solid ${(props) => Green4};
`;

export const BasicPagination = ({ style, qtyItems, onChange }) => {
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const paginate = (_pageSize) => {
    setCurrentPage(0);
    setPageSize(parseInt(_pageSize, 10));
    if (typeof onChange === 'function') {
      onChange({
        pageSize: _pageSize,
        page: '0',
      });
    }
  };

  const pages = Array.from({
    length:
      qtyItems % pageSize === 0 ? qtyItems / pageSize : qtyItems / pageSize + 1,
  }).map((x, i) => i);
  const navigatePage = (page) => {
    if (page < 0) {
      return;
    }
    if (page > pages.length + 1) {
      return;
    }
    setCurrentPage(parseInt(page, 10));
    if (typeof onChange === 'function') {
      onChange({
        pageSize: pageSize.toString(),
        page: page.toString(),
      });
    }
  };
  const pagesOverLimit = pages.length > 5;
  const lastIdx = pages.length - 1;
  const last = pages[lastIdx];
  const first = pages[0];
  let endingPaging = lastIdx;
  if (pagesOverLimit) {
    if (currentPage + 4 < lastIdx) {
      endingPaging = currentPage + 2;
    }
  }
  const startPaging = endingPaging - 5 >= 0 ? endingPaging - 5 : 0;
  const lastIncluded =
    pages.slice(startPaging, endingPaging + 1).filter((x) => x === last)
      .length > 0;
  const firstIncluded =
    pages.slice(startPaging, endingPaging + 1).filter((x) => x === first)
      .length > 0;
  return (
    <div className="pagination" style={{ ...style }}>
      <div className="pagination-content">
        <div className="selection">
          <span>Itens por página</span>
          <Select onChange={(e) => paginate(e.target.value)} value={pageSize}>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="300">300</option>
          </Select>
        </div>
        <div className="numbers">
          {pages.length > 0 && currentPage > 0 ? (
            <MdOutlineKeyboardArrowLeft
              style={{ fontSize: '40px', color: Green4, cursor: 'pointer' }}
              onClick={() => navigatePage(currentPage - 1)}
            ></MdOutlineKeyboardArrowLeft>
          ) : (
            <MdOutlineKeyboardArrowLeft
              style={{ visibility: 'hidden', fontSize: '40px' }}
            ></MdOutlineKeyboardArrowLeft>
          )}
          {pagesOverLimit && !firstIncluded ? (
            0 === currentPage ? (
              <PageButtons
                onClick={() => navigatePage(last)}
                style={{ color: 'white', backgroundColor: Green4 }}
                key={0}
              >
                {1}
              </PageButtons>
            ) : (
              <PageButtons
                style={{ cursor: 'pointer' }}
                onClick={() => navigatePage(parseInt(0))}
                key={0}
              >
                {1}
              </PageButtons>
            )
          ) : (
            <></>
          )}
          {pagesOverLimit && !firstIncluded ? (
            <MdMoreHoriz></MdMoreHoriz>
          ) : (
            <></>
          )}
          {pages.slice(startPaging, endingPaging + 1).map((p, idx) =>
            p == currentPage ? (
              <PageButtons
                onClick={() => navigatePage(parseInt(p))}
                style={{ color: 'white', backgroundColor: Green4 }}
                key={idx}
              >
                {p + 1}
              </PageButtons>
            ) : (
              <PageButtons
                style={{ cursor: 'pointer' }}
                onClick={() => navigatePage(parseInt(p))}
                key={idx}
              >
                {p + 1}
              </PageButtons>
            )
          )}

          {pagesOverLimit && !lastIncluded ? (
            <MdMoreHoriz></MdMoreHoriz>
          ) : (
            <></>
          )}
          {pagesOverLimit && !lastIncluded ? (
            lastIdx == currentPage ? (
              <PageButtons
                onClick={() => navigatePage(last)}
                style={{ color: 'white', backgroundColor: Green4 }}
                key={lastIdx}
              >
                {last + 1}
              </PageButtons>
            ) : (
              <PageButtons
                style={{ cursor: 'pointer' }}
                onClick={() => navigatePage(parseInt(last))}
                key={lastIdx}
              >
                {last + 1}
              </PageButtons>
            )
          ) : (
            <></>
          )}

          {!lastIncluded && pages.length > 0 ? (
            <MdOutlineKeyboardArrowRight
              style={{ fontSize: '40px', color: Green4, cursor: 'pointer' }}
              onClick={() => navigatePage(currentPage + 1)}
            ></MdOutlineKeyboardArrowRight>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
