import styled from 'styled-components';
import { BlueText, BlueTextDark } from '../../base/colors';
import { useKPIStore } from '../../stores/kpiStore';

const OxyLabel = styled.p`
  text-align: center;
  width: 100%;
  margin: 0px;
`;

export const OxygenConsumption = () => {
  const oxygenConsumed = useKPIStore((state) => state.oxygenConsumed);

  const calculateTotal = () => {
    return parseFloat(oxygenConsumed.toFixed(2)).toLocaleString('pt-BR');
  };
  return (
    <OxyLabel>
      <span style={{ fontSize: '60px', fontWeight: 700, color: '#318300' }}>
        {calculateTotal()}
      </span>
      <span style={{ fontSize: '32px', color: '#318300', fontWeight: 600 }}>
        m³
      </span>
    </OxyLabel>
  );
};
