import { doGet, doPost } from './httpHelper';

export const getDocument = async (searchString) => {
  const resp = await fetch(
    `${process.env.REACT_APP_API_DOCUMENT_URL}${searchString}`
  );
  const respJ = await resp.json();
  return respJ;
};

export const saveDocument = async (document) => {
  const resp = await doPost('/saveDocument', document);
  return resp;
};
