import { useEffect, useState } from 'react';
import { AiOutlineCheck, AiOutlineDelete } from 'react-icons/ai';
import { FiEdit2 } from 'react-icons/fi';
import styled from 'styled-components';
import { PrimaryTitleGray } from '../../base/colors';
import { PrimaryButton, SecondaryButton } from '../../base/Inputs';
import { GreenLabel, YellowLabel } from '../../base/labels';
import { Table } from '../../base/table';
import { SimplePopup } from '../../components/popup';
import { delUser, listUsers } from '../../services/userMgntService';
import { cognitoStatusMapping } from '../../utils/utils';
import { UserForm } from './form';
const ToolBar = styled.div``;
export const UserMngtConfig = () => {
  const [users, setUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [shouldShowEditPopup, setShouldShowEditPopup] = useState(false);
  const [shouldShowDeletePopup, setShouldShowDeletePopup] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [emailDelete, setEmailDelete] = useState('');

  const fetchUsers = async () => {
    const u = await listUsers();
    setUsers(u);
    setCurrentUser({});
  };

  useEffect(() => {
    fetchUsers();
  }, []);
  const showDeletePopup = (idx) => {
    const emailToDelete = users[idx].email;
    setEmailDelete(emailToDelete);
    setShouldShowDeletePopup(true);
  };
  const showEditPopup = (idx) => {
    setCurrentUser({});
    const user = users[idx];
    const parsed = {
      id: user.id,
      name: user.name,
      email: user.email,
      cnpj: user['custom:cnpj'],
      companyName: user['custom:companyName'],
      isSpecialist: user['custom:isSpecialist'],
      isGeneralist: user['custom:isGeneralist'],
      isManager: user['custom:isManager'],
      isWhiteMartins: user['custom:isWhiteMartins'],
      isAdmin: user['custom:isAdmin'],
      email_verified: user.email_verified,
      status: user.status,
      enabled: user.enabled,
    };
    setCurrentUser(parsed);
    setIsEditing(true);
    setShouldShowEditPopup(true);
  };

  const onAction = () => {
    setShouldShowEditPopup(false);
    setIsEditing(false);
    fetchUsers();
  };

  const deleteUser = async () => {
    await delUser(emailDelete);
    setShouldShowDeletePopup(false);
    setIsEditing(false);
    fetchUsers();
  };

  const DeleteUserPopup = () => (
    <SimplePopup
      width={400}
      outlet={
        <div style={{ display: 'block' }}>
          <p style={{ textAlign: 'center', fontWeight: '500' }}>
            Tem certeza que deseja excluir este usuário? "{emailDelete}"
          </p>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <SecondaryButton
              style={{ width: '100px' }}
              onClick={() => setShouldShowDeletePopup(false)}
            >
              Não
            </SecondaryButton>
            <PrimaryButton style={{ width: '100px' }} onClick={deleteUser}>
              Sim
            </PrimaryButton>
          </div>
        </div>
      }
    ></SimplePopup>
  );

  const EditUserPopup = () => (
    <SimplePopup
      width={800}
      outlet={
        <div>
          <div
            style={{
              display: 'flex',
              marginBottom: '20px',
              boxSizing: 'border-box',
            }}
          >
            <div style={{ flex: 1 }}>
              <div
                style={{
                  fontSize: '18px',
                  color: PrimaryTitleGray,
                  fontWeight: 700,
                }}
              >
                Registro de Usuário
              </div>
            </div>
          </div>
          <UserForm
            isEditing={isEditing}
            {...currentUser}
            onAction={onAction}
          ></UserForm>
        </div>
      }
    ></SimplePopup>
  );

  return (
    <div>
      {shouldShowEditPopup ? EditUserPopup() : <></>}
      {shouldShowDeletePopup ? DeleteUserPopup() : <></>}
      <ToolBar
        style={{
          marginTop: '-22px',
          marginBottom: '5px',
          padding: '5px 0',
          borderTop: 'solid 1px #b9b9b9',
          display: 'flex',
          justifyContent: 'end',
        }}
      >
        <div style={{ width: '150px' }}>
          <PrimaryButton
            onClick={() => {
              setIsEditing(false);
              setCurrentUser({});
              setShouldShowEditPopup(true);
            }}
          >
            Novo usuário
          </PrimaryButton>
        </div>
      </ToolBar>
      <div style={{ overflow: 'auto' }}>
        <Table
          header={[
            'Ações',
            'Nome',
            'Email',
            'CNPJ',
            'Empresa',
            'Especialista',
            'Geral',
            'Gerente',
            'White Martins',
            'Admin',
            'Email Verificado',
            'Status',
            'Habilitado',
          ]}
          data={users.map((user, idx) => {
            return {
              opt: (
                <div style={{ width: '60px' }}>
                  <AiOutlineDelete
                    className="hoverButton"
                    style={{ cursor: 'pointer', fontSize: '18px' }}
                    onClick={() => showDeletePopup(idx)}
                  ></AiOutlineDelete>
                  &nbsp;
                  <FiEdit2
                    className="hoverButton"
                    style={{
                      marginLeft: '20px',
                      cursor: 'pointer',
                      fontSize: '18px',
                    }}
                    onClick={() => showEditPopup(idx)}
                  ></FiEdit2>
                </div>
              ),
              name: user.name,
              email: user.email,
              cnpj: user['custom:cnpj'],
              companyName: user['custom:companyName'],
              isSpecialist:
                user['custom:isSpecialist'] === '1' ? (
                  <AiOutlineCheck></AiOutlineCheck>
                ) : (
                  ''
                ),
              isGeneralist:
                user['custom:isGeneralist'] === '1' ? (
                  <AiOutlineCheck></AiOutlineCheck>
                ) : (
                  ''
                ),
              isManager:
                user['custom:isManager'] === '1' ? (
                  <AiOutlineCheck></AiOutlineCheck>
                ) : (
                  ''
                ),
              isWhiteMartins:
                user['custom:isWhiteMartins'] === '1' ? (
                  <AiOutlineCheck></AiOutlineCheck>
                ) : (
                  ''
                ),
              isAdmin:
                user['custom:isAdmin'] === '1' ? (
                  <AiOutlineCheck></AiOutlineCheck>
                ) : (
                  ''
                ),
              email_verified: user.email_verified ? 'Sim' : 'Não',
              status: cognitoStatusMapping(user.status),
              enabled: user.enabled ? (
                <GreenLabel style={{ textTransform: 'capitalize' }}>
                  Habilitado
                </GreenLabel>
              ) : (
                <YellowLabel style={{ textTransform: 'capitalize' }}>
                  Desabilitado
                </YellowLabel>
              ),
            };
          })}
        ></Table>
      </div>
    </div>
  );
};
