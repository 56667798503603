import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers';
import { styled } from '@mui/material/styles';

export const StyledDatePicker = styled(MUIDatePicker)(({ theme }) => ({
  background: theme.palette.common.white,
  borderRadius: '8px',

  '& fieldset': {
    border: `2px solid ${theme.colors.input.border}`,
    borderRadius: '8px',
  },

  '& svg': {
    color: theme.colors.input.svg,
  },
}));
