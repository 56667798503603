import React from 'react';
import { Box } from '@mui/material';
import { PatientComplementIcon } from '../PatientClassificationIcon';
import { LineThrough } from '../../common/LineThrough';

type PatientNameProps = {
  name: string;
  additionalInformation: Array<number> | undefined;
  onPaymentList: boolean | undefined;
  canSeeFullContent: boolean | undefined;
};

export const PatientName: React.FC<PatientNameProps> = ({
  name,
  additionalInformation,
  onPaymentList = true,
  canSeeFullContent = false,
}) => {
  const [information, setInformation] = React.useState<Array<number>>();
  const [fullContent, setFullContent] = React.useState<boolean>();

  React.useEffect(() => {
    setInformation(
      additionalInformation ? additionalInformation.sort() : undefined
    );
    setFullContent(canSeeFullContent ? canSeeFullContent : false);
  }, [additionalInformation, onPaymentList, canSeeFullContent]);

  return (
    <Box>
      <span>
        {fullContent && !onPaymentList ? (
          <span>
            <LineThrough>{name}</LineThrough>
          </span>
        ) : (
          <span>{name}</span>
        )}
      </span>
      <span style={{ marginLeft: '5px', width: 'fit-content' }}>
        {fullContent &&
          information?.map((i, idx) => (
            <PatientComplementIcon
              statusComplementId={i}
              key={idx}
              onPaymentList={onPaymentList}
            ></PatientComplementIcon>
          ))}
      </span>
    </Box>
  );
};
