import { getDeliveryLabel } from '../../utils/utils';
import { GreenLabel, RedLabel } from '../../base/labels';
import { OrderTableData } from '../../types/Order';
import { OrderEnums } from '../../enums/OrderEnums';

export const Activity = (order: OrderTableData, action: any) => {
  const orderEnums = new OrderEnums();

  const activitiesLabels = [
    orderEnums.enums.statusComplement.DELIVERED_WITH_ORIENTATIONS,
    orderEnums.enums.statusComplement.CANCELLED,
    orderEnums.enums.statusComplement.EXTERNAL_EQUIPMENTS,
  ];

  const nonDeliveryLabelsComplements = [
    orderEnums.enums.statusComplement.CANCELLED,
    orderEnums.enums.statusComplement.EXTERNAL_EQUIPMENTS,
  ];

  return (
    <div>
      {activitiesLabels.includes(order.status_complement_id) ? (
        <>
          {nonDeliveryLabelsComplements.includes(order.status_complement_id) ? (
            <RedLabel
              style={{
                textTransform: 'capitalize',
                textAlign: 'center',
                width: 'auto',
              }}
            >
              {order.status_complement}
            </RedLabel>
          ) : (
            <GreenLabel
              style={{
                textTransform: 'capitalize',
                textAlign: 'center',
                width: 'auto',
              }}
            >
              {order.status_complement}
            </GreenLabel>
          )}
        </>
      ) : (
        <></>
      )}
      {new OrderEnums().status.isUnsuccessful(order.status.id.toString()) &&
      nonDeliveryLabelsComplements.includes(order.status_complement_id) ? (
        <></>
      ) : (
        <>{getDeliveryLabel(order, action)}</>
      )}
    </div>
  );
};
