import { formatDateToISO, getDeliveryLabel } from '../utils/utils';
import { getBallastPerCategory } from './ballastService';
import { getRequests } from './ordersService';
import { getPatients } from './patientsService';
import { getSales } from './salesService';
import { OrdersDataSource } from '../datasources/OrdersDataSource';
import { PatientDataSource } from '../datasources/PatientDataSource';

export const getActivatePatients = async (cnpj) => {
  const patients = await getPatients(cnpj, null, 0, 1, 'Ativo');
  return patients.rows;
};

export const getMonthlyOxigenConsumed = async (cnpj, initialDate, endDate) => {
  let page = 0;
  let sales = await getSales(null, page, 1000, initialDate, endDate);
  let salesData = sales.data;
  while (salesData.length < sales.rows) {
    page++;
    sales = await getSales(null, page, 1000, initialDate, endDate);
    salesData = salesData.concat(sales.data);
  }
  let total = 0.0;
  const mapSales = {};
  const months = [
    '',
    'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez',
  ];
  salesData.forEach((sale) => {
    if (!mapSales[`${months[sale.month]}/${sale.year}`]) {
      mapSales[`${months[sale.month]}/${sale.year}`] = 0.0;
    }
    mapSales[`${months[sale.month]}/${sale.year}`] += parseFloat(
      sale.vol_total
    );
  });
  return { months: Object.keys(mapSales), data: Object.values(mapSales) };
};

export const getAllocatedEquipmentsChart = async (
  cnpj,
  initialDate,
  endDate
) => {
  const result = await getBallastPerCategory();
  const mapCategory = {};
  result.data.forEach((d) => {
    d.categories.forEach((cat) => {
      if (!mapCategory[cat.category]) {
        mapCategory[cat.category] = cat.total;
      } else {
        mapCategory[cat.category] += cat.total;
      }
    });
  });
  return mapCategory;
};

export const getRecentOrders = async (requestType) => {
  const orders = await new OrdersDataSource().getAll({
    page: 0,
    page_size: 50,
    type: requestType,
  });

  const promisesData = orders.data.map(async (order) => ({
    numeroPedido: order.number ? order.number : '',
    numeroSAP: order.sap_numbers.join(', '),
    type: order.type,
    name: order.customer.name ?? '',
    orderReceiptUrl: [],
    cpf: order.customer.cpf,
    createdAt: order.created_at,
    status: order.status,
    activity: getDeliveryLabel(order),
    customerAdditionalInformation: order.customer.additionalInformation,
  }));
  const data = Promise.all(promisesData);
  return data;
};
