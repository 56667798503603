import styled from 'styled-components';

export const IconTile = styled.span`
  font-size: 48px;
  vertical-align: middle;
  border-radius: 50px;
  color: white;
  margin-right: 10px;
  background-color: ${(props) => props.color};
`;

export const ImageTile = styled.span`
  vertical-align: middle;
  margin-right: 10px;
`;
export const LabelTile = styled.div`
  display: inline-block;
  font-size: 16px;
  color: ${(props) => props.color};
  font-weight: 500;
  vertical-align: middle;
  margin-bottom: 10px;
  font-weight: bold;
`;

export const ValueTile = styled.span`
  font-size: 40px;
  line-height: 40px;
  font-weight: bolder;
  float: right;
  color: ${(props) => props.color};
`;

export const PanelContent = styled.div`
  display: block;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
`;

export const PanelBody = styled.div`
  display: flex;
`;
