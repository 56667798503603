/**
 * Colors used in the application.
 * If updated, changes will be reflected to types in "theme.colors".
 */
export const colors = {
  main: '#318300',
  highlight: '#318300',

  background: {
    primary: '#fff',
    //primary: '#f0f0f0',
  },

  text: {
    primary: '#535353',
    secondary: '#6c6c6c',
    tertiary: '#9c9c9c',
  },

  input: {
    svg: '#9c9c9c',
    border: '#b6b6b6',
    disabled: '#e3e3e3',
  },

  message: {
    text: '#ffffff',
    secondary: '#9e9e9e',
  },

  success: {
    background: '#b2e0c4',
    text: '#084d01',
  },
};
