import React from 'react';

type IconPNGProps = {
  src: string;
  alt?: string;
  width?: string;
  height?: string;
};

export const IconPNG: React.FC<IconPNGProps> = ({
  src,
  alt,
  width,
  height,
}) => {
  return (
    <img
      src={src}
      alt={alt}
      width={width ?? 'auto'}
      height={height ?? 'auto'}
    />
  );
};
