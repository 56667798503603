import styled from 'styled-components';
import {
  BrighterGreen,
  DarkGreen,
  LimeGreen,
  PrimaryTextColor,
  PrimaryTitleGray,
  ShadowColor,
  TextColor,
} from './colors';

export const InputText = styled.input`
  line-height: 30px;
  border: 2px solid ${TextColor};
  border-radius: 8px;
  text-indent: 10px;
  box-sizing: border-box;
`;

export const ReadOnlyInputText = styled.input`
  line-height: 30px;
  border: 2px solid ${TextColor};
  border-radius: 8px;
  text-indent: 10px;
  :disabled {
    background-color: white;
  }
`;

export const TextAreaInput = styled.textarea`
  border: 2px solid ${TextColor};
  border-radius: 8px;
  text-indent: 10px;
  box-sizing: border-box;
  margin-right: 2px;
  margin-left: 5px;
  font-family: sans-serif;
`;

export const PrimaryButton = styled.button`
  color: white;
  font-size: 14px;
  font-weight: bold;
  font-family: LindeDaxRegWeb;
  width: auto;
  padding-right: 15px;
  padding-left: 15px;
  height: 30px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  background-color: #318300;
  &:disabled {
    opacity: 0.2;
    cursor: default;
  }
  &:hover {
    background: #2a580e;
  }
`;

export const JumboButton = styled.button`
  color: white;
  font-weight: bolder;
  font-size: 18px;
  width: 100%;
  height: 50px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  background-image: linear-gradient(to right, ${LimeGreen}, ${LimeGreen});
  &:disabled {
    opacity: 0.2;
    cursor: default;
  }
`;

export const LighterGreenButton = styled.button`
  color: white;
  font-weight: bolder;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  background-color: #318300;
  text-align: center;
  display: flex;
  align-itens: center;
  &:disabled {
    opacity: 0.2;
    cursor: default;
  }
  &:hover {
    background: #2a580e;
  }
`;

export const SecondaryButton = styled.button`
  color: ${DarkGreen};
  font-size: 14px;
  font-weight: bold;
  font-family: LindeDaxRegWeb;
  width: auto;
  padding-right: 15px;
  padding-left: 15px;
  height: 30px;
  border: 2px solid ${BrighterGreen};
  border-radius: 6px;
  cursor: pointer;
  background-color: transparent;
  &:disabled {
    opacity: 0.2;
    cursor: default;
  }
  &:hover {
    background: #2a580e;
    color: #fff;
  }
`;

export const Select = styled.select`
  box-sizing: border-box;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 15px;
  text-align: left;
  padding-right: 15px;
  border-radius: 8px;
  text-indent: 10px;
  border: 2px solid #b6b6b6;
  box-shadow: none;
  min-width: 100px;
`;
