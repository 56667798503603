import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import React from 'react';
import { Line } from 'react-chartjs-2';
import { useMediaQuery } from 'react-responsive';
import { DarkGreen } from '../../base/colors';
import { DateRange } from '../../base/dateRange';
import { TitledPanel } from '../../components/dashboards/titledPanel/titledPanel';
import { usePortalStore } from '../../store';
import { useMainStore } from '../../stores/mainStore';
import { formatDate, formatDateMMddYYYY } from '../../utils/utils';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const options = {
  responsive: true,
  interaction: {
    mode: 'index',
  },
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
      beginAtZero: true,
    },
  },
  plugins: {
    legend: {
      display: false,
    },

    title: {
      display: false,
    },
    filler: {
      propagate: true,
    },
  },
};

const YearSelectAction = () => {
  const setConsumedOxygenFilter = useMainStore(
    (state) => state.setConsumedOxygenFilter
  );
  const onChangeYear = (e) => {
    const initialDate = e[0];
    const endDate = e[1];
    setConsumedOxygenFilter([initialDate, endDate]);
  };
  return (
    <DateRange
      maxDate={new Date()}
      selectRange={true}
      onSelect={onChangeYear}
      maxDetail={'year'}
      minDetail={'year'}
    ></DateRange>
  );
};

const OxigenChart = () => {
  const consumedOxygenData = useMainStore((state) => state.consumedOxygenData);
  const consumedOxygenThreshold = useMainStore(
    (state) => state.consumedOxygenThreshold
  );
  const consumedOxygenXLabel = useMainStore(
    (state) => state.consumedOxygenXLabel
  );
  const data = {
    labels: consumedOxygenXLabel,
    datasets: [
      {
        data: consumedOxygenData,
        borderColor: DarkGreen,
        pointRadius: 3,
        borderWidth: 3,
        backgroundColor: DarkGreen,
        fill: {
          target: 'origin',
          above: 'rgba(247, 247, 247,0.2)', // Area will be red above the origin
        },
      },
      {
        data: consumedOxygenData.map((x) => consumedOxygenThreshold),
        borderColor: 'rgb(53, 162, 235)',
        pointRadius: 0,
        borderWidth: 2,
        borderDash: [5, 2],
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };
  return (
    <div style={{ height: '150px', overflow: 'hidden', position: 'relative' }}>
      <Line options={options} data={data} />
    </div>
  );
};

export const MonthlyConsumedOxigen = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const menuOpenState = usePortalStore((state) => state.ui.menuOpenState);
  return (
    <TitledPanel
      title={`Consumo mensal de oxigênio`}
      action={YearSelectAction()}
      outlet={OxigenChart()}
    ></TitledPanel>
  );
};
