import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { PrimaryButton, SecondaryButton } from '../base/Inputs';
import { GridBox, Row } from '../components/gridsystem/gridsystem';
import { WideLayout } from '../components/layout/wideLayout';
import { SimplePopup } from '../components/popup';
import { usePortalStore } from '../store';
import { usePatientsStore } from '../stores/patientsStore';
import { PatientsList } from '../usecase/patients/list';
import { EditPatientModal } from '../components/EditPatientModal';
import { CircularProgress } from '@mui/material';

const OutletHistory = ({ showDeletePopup, showEditPopup, newPatientForm }) => {
  //const header = ["","Nome","E-mail", "CPF", "Celular", "Data de Nasci.", "Responsável", "Cel. Responsável", "Data de Inclusão","Status"]
  //const fields = ["name","email","cpf","cellphone","birthDate","responsibleName","responsiblePhoneNumber","createdAt","status"];
  const header = [
    <span className="small" key={'0'}>
      Ações de gerenciamento
    </span>,
    'Nome',
    'E-mail',
    'Empresa',
    'CNPJ',
    'CPF',
    'Telefone/Celular',
    'Status',
  ];
  const fields = [
    'name',
    'email',
    'company',
    'cnpj',
    'cpf',
    'cellphone',
    'status',
  ];
  return (
    <PatientsList
      title={'Relação de pacientes'}
      height={`${window.innerHeight - 350}px`}
      showDeletePopup={showDeletePopup}
      showEditPopup={showEditPopup}
      readOnly={false}
      header={header}
      fields={fields}
      newClientAction={ActionFilters(newPatientForm)}
    ></PatientsList>
  );
};

const ActionFilters = (openPopupStateChange) => {
  return (
    <PrimaryButton
      style={{ width: '150px' }}
      onClick={() => openPopupStateChange(true)}
    >
      Novo paciente
    </PrimaryButton>
  );
};

const OrderRequestPopup = ({ closePopup }) => (
  <SimplePopup
    width={400}
    height={200}
    outlet={
      <div style={{ display: 'block' }}>
        <p style={{ textAlign: 'center', fontWeight: '500' }}>
          Gostaria de incluir uma nova solicitação para o paciente cadastrado?
        </p>
        <SecondaryButton style={{ marginTop: '10px' }} onClick={closePopup}>
          Não
        </SecondaryButton>
        <PrimaryButton>Sim</PrimaryButton>
      </div>
    }
  ></SimplePopup>
);

const DeletePatientPopup = ({ closePopup, confirmAction }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <SimplePopup
      width={400}
      outlet={
        <div
          className="modal-mobile"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <p style={{ textAlign: 'center', fontWeight: '500' }}>
            Tem certeza que deseja ativar/inativar este paciente?
          </p>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <SecondaryButton
              style={{ width: '100px' }}
              onClick={() => closePopup()}
            >
              Não
            </SecondaryButton>
            <PrimaryButton
              style={{ width: '100px' }}
              disabled={isLoading}
              onClick={async () => {
                setIsLoading(true);
                await confirmAction();
                setIsLoading(false);
              }}
            >
              {isLoading && (
                <CircularProgress
                  size={11}
                  sx={{ color: 'white', marginRight: '6px' }}
                />
              )}
              Sim
            </PrimaryButton>
          </div>
        </div>
      }
    ></SimplePopup>
  );
};

const MainPanels = () => {
  const [orderRequestPopup, setOrderRequestPopup] = useState(false);
  const [deletePatientPopup, setDeletePatientPopup] = useState(false);
  const [editPatientPopup, setEditPatientPopup] = useState(false);
  const [isNewPatient, setIsNewPatient] = useState(false);
  const searchTerm = usePatientsStore((state) => state.searchTerm);
  const setSearchTerm = usePatientsStore((state) => state.setSearchTerm);
  const currentUser = usePortalStore((state) => state.currentUser);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const fetchPatients = usePatientsStore((state) => state.fetchPatients);
  const patients = usePatientsStore((state) => state.patients);
  const setId = usePatientsStore((state) => state.setId);
  const setName = usePatientsStore((state) => state.setName);
  const setEmail = usePatientsStore((state) => state.setEmail);
  const setSap = usePatientsStore((state) => state.setSap);
  const setCpf = usePatientsStore((state) => state.setCpf);
  const setCNPJ = usePatientsStore((state) => state.setCNPJ);
  const setCEP = usePatientsStore((state) => state.setCEP);
  const setCity = usePatientsStore((state) => state.setCity);
  const setUF = usePatientsStore((state) => state.setUF);
  const setAddress = usePatientsStore((state) => state.setAddress);
  const setAddressLine2 = usePatientsStore((state) => state.setAddressLine2);
  const setNumber = usePatientsStore((state) => state.setNumber);
  const setStatus = usePatientsStore((state) => state.setStatus);
  const setNewPat = usePatientsStore((state) => state.setNewPat);
  const setCellphone = usePatientsStore((state) => state.setCellphone);
  const insertPatient = usePatientsStore((state) => state.insertPatient);
  const deactivatePatient = usePatientsStore(
    (state) => state.deactivatePatient
  );
  const setCurrentSelectPatientCPF = usePatientsStore(
    (state) => state.setCurrentSelectPatientCPF
  );
  const setCurrentAlertError = usePortalStore(
    (state) => state.setCurrentAlertError
  );

  const setOnPaymentList = usePatientsStore((state) => state.setOnPaymentList);
  const setAdditionalInformation = usePatientsStore(
    (state) => state.setAdditionalInformation
  );

  const fillPatientToEdit = (idx) => {
    const current = patients[idx];
    if (!current) {
      return;
    }
    setId(idx);
    setName(current.name);
    setEmail(current.email);
    setSap(current.sap);
    setCpf(current.cpf);
    setCNPJ(current.cnpj);
    setCellphone(current.cellphone);
    setCEP(current.cep);
    setCity(current.city);
    setAddress(current.address);
    setAddressLine2(current.addressLine2);
    setUF(current.uf);
    setNumber(current.number);
    setEditPatientPopup(true);
    setNewPat(false);
    setStatus(current.status);
    setOnPaymentList(current.onPaymentList);
    setAdditionalInformation(
      current.additionalInformation.map((i) => i.id.toString())
    );
  };

  const newPatientForm = () => {
    setId('');
    setName('');
    setEmail('');
    setSap('');
    setCpf('');
    setCNPJ('');
    setCellphone('');
    setUF('');
    setCEP('');
    setCity('');
    setAddress('');
    setAddressLine2('');
    setNumber('');
    setEditPatientPopup(true);
    setNewPat(true);
    setIsNewPatient(true);
    setOnPaymentList(true);
    setAdditionalInformation([]);
  };

  const deletePatientAction = async () => {
    const resp = await deactivatePatient();
    if (resp.httpStatus !== 200) {
      setDeletePatientPopup(false);
      setCurrentAlertError(
        resp.body.message ?? 'Não foi possível completar a operação'
      );
      return;
    }

    if (resp.ballastBlock || resp.requestsBlock) {
      setDeletePatientPopup(false);
      setCurrentAlertError(
        'Não foi possível inativar o paciente verique se o paciente possui pedidos em aberto ou equipamento alocados.'
      );
      return;
    }

    await fetchPatients();
    resetPatientFields();
    setDeletePatientPopup(false);
  };

  const resetPatientFields = () => {
    setId(-1);
    setName('');
    setEmail('');
    setSap('');
    setCpf('');
    setCNPJ('');
    setCellphone('');
    setUF('');
    setNumber('');
    setCEP('');
    setCity('');
    setAddress('');
    setAddressLine2('');
  };

  const updatePatient = async () => {
    const result = await insertPatient();
    if (result.httpStatus === 200) {
      await fetchPatients();
      setEditPatientPopup(false);
      resetPatientFields();
      setSearchTerm('');
    } else {
      setCurrentAlertError(
        Array.isArray(result.body) ? (
          <ul>
            {result.body.map((x, idx) => (
              <li style={{ textAlign: 'left' }} key={idx}>
                {x}
              </li>
            ))}
          </ul>
        ) : (
          result.body.message
        )
      );
    }
  };

  return (
    <>
      {orderRequestPopup ? (
        <OrderRequestPopup
          closePopup={() => setOrderRequestPopup(false)}
        ></OrderRequestPopup>
      ) : (
        <></>
      )}
      {deletePatientPopup ? (
        <DeletePatientPopup
          closePopup={() => setDeletePatientPopup(false)}
          confirmAction={deletePatientAction}
        ></DeletePatientPopup>
      ) : (
        <></>
      )}
      {editPatientPopup ? (
        <EditPatientModal
          open={editPatientPopup}
          handleClose={() => {
            resetPatientFields();
            setEditPatientPopup(false);
          }}
          handleSave={updatePatient}
        ></EditPatientModal>
      ) : (
        <></>
      )}
      <GridBox>
        <Row>
          {OutletHistory({
            showDeletePopup: (cpf) => {
              setCurrentSelectPatientCPF(cpf);
              setDeletePatientPopup(true);
            },
            showEditPopup: (idx) => {
              fillPatientToEdit(idx);
              setIsNewPatient(false);
            },
            newPatientForm,
          })}
        </Row>
      </GridBox>
    </>
  );
};

export const PatientsPage = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const currentUser = usePortalStore((state) => state.currentUser);

  return (
    <WideLayout
      outlet={isTabletOrMobile ? MainPanels() : MainPanels()}
    ></WideLayout>
  );
};
