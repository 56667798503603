import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { PrimaryTextColor, PrimaryTitleGray } from '../../base/colors';
import { PrimaryButton, SecondaryButton } from '../../base/Inputs';
import { LargeTile } from '../../components/gridsystem/gridsystem';
import { WideLayout } from '../../components/layout/wideLayout';
import { Panel } from '../../components/panels/panel';
import { usePortalStore } from '../../store';
import { useRechargeStore } from '../../stores/rechargeStore';
import { formatCPF } from '../../utils/utils';
import { GiHandTruck } from 'react-icons/gi';
import { ExchangeIcon } from '../../components/icons/ExchangeIcon';

const MainPanels = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const currentUser = usePortalStore((state) => state.currentUser);
  const selectedPatient = useRechargeStore((state) => state.selectedPatient);
  const rechargerActions = useRechargeStore((state) => state.rechargerActions);
  const selectedPatientIndex = useRechargeStore(
    (state) => state.selectedPatientIndex
  );
  const navigate = useNavigate();
  const operations = rechargerActions[selectedPatientIndex];

  const hasCylinderToChange =
    Object.values(operations).filter(
      (x) => x.action === 'troca' && x.newCylinder !== undefined
    ).length > 0;
  const hasCylinderToRecharge =
    Object.values(operations).filter(
      (x) => x.action === 'recarga' && x.quantity > 0
    ).length > 0;
  return (
    <>
      <LargeTile margin={'15px'}>
        <h3 style={{ color: PrimaryTitleGray }}>Confirmar Pedido</h3>
        <Panel style={{ padding: '20px' }}>
          <h4
            style={{
              fontWeight: 'bold',
              padding: '0px',
              margin: '0px 0px 15px',
            }}
          >
            {selectedPatient.name} - CPF: {formatCPF(selectedPatient.cpf)}
          </h4>
          <div style={{ overflow: 'auto', color: PrimaryTextColor }}>
            {Object.values(operations).filter(
              (x) => x.action === 'troca' && x.newCylinder !== undefined
            ).length > 0 ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  borderBottom: 'solid 1px #ccc',
                  marginBottom: '5px',
                  marginTop: '10px',
                }}
              >
                <ExchangeIcon height="30px"></ExchangeIcon>
                <div
                  style={{
                    fontSize: '16px',
                    color: '#000',
                    margin: 'auto 0px auto 5px',
                  }}
                >
                  Cilindros que serão trocado
                </div>
              </div>
            ) : (
              <></>
            )}
            <ul style={{ margin: '0px', padding: '0px' }}>
              {Object.values(operations)
                .filter(
                  (x) => x.action === 'troca' && x.newCylinder !== undefined
                )
                .map((x, idx) => (
                  <li
                    style={{
                      listStyle: 'none',
                      display: 'flex',
                      alignItems: 'center',
                      padding: '6px 15px',
                      background: '#efefef',
                      borderRadius: '10px',
                      marginBottom: '5px',
                      color: '#000',
                    }}
                    key={idx}
                  >
                    <div style={{ marginRight: '5px', fontSize: '14px' }}>
                      <span>Troca de </span>
                      <span>
                        {x.cylinder.product} -{' '}
                        {x.cylinder.size.toString().replace('.', ',')} m³
                      </span>
                      <span> para </span>
                      <span>{x.newCylinder.description}</span>
                    </div>
                  </li>
                ))}
            </ul>
            {Object.values(operations).filter(
              (x) => x.action === 'recarga' && x.quantity > 0
            ).length > 0 ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  borderBottom: 'solid 1px #ccc',
                  marginBottom: '5px',
                  marginTop: '10px',
                }}
              >
                <GiHandTruck
                  style={{
                    fontSize: '30px',
                    marginRight: '9px',
                    color: '#318300',
                  }}
                ></GiHandTruck>
                <div style={{ fontSize: '16px', color: '#000' }}>
                  Cilindros que serão recarregados
                </div>
              </div>
            ) : (
              <></>
            )}
            <ul style={{ margin: '0px', padding: '0px' }}>
              {Object.values(operations)
                .filter((x) => x.action === 'recarga' && x.quantity > 0)
                .map((x, idx) => (
                  <li
                    style={{
                      listStyle: 'none',
                      padding: '6px 15px',
                      background: '#efefef',
                      borderRadius: '10px',
                      marginBottom: '5px',
                      color: '#000',
                    }}
                    key={idx}
                  >
                    <span>{x.cylinder.product}</span>
                    <span
                      style={{ float: 'right' }}
                    >{`Quantidade: ${x.quantity}`}</span>
                  </li>
                ))}
            </ul>
          </div>
          <div
            style={{
              marginTop: '50px',
              display: 'inline-grid',
              width: '100%',
              boxSizing: 'border-box',
              gridTemplateColumns: 'repeat(auto-fit, 300px)',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <SecondaryButton
                onClick={() =>
                  navigate('/recharge/rechargePatient?keepState=true')
                }
              >
                Voltar
              </SecondaryButton>
            </div>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <SecondaryButton
                style={{ marginRight: '10px' }}
                onClick={() => navigate('/orders')}
              >
                Cancelar
              </SecondaryButton>
              <PrimaryButton
                disabled={!hasCylinderToChange && !hasCylinderToRecharge}
                onClick={() => navigate('/recharge/finishRecharge')}
              >
                Prosseguir
              </PrimaryButton>
            </div>
          </div>
        </Panel>
      </LargeTile>
    </>
  );
};

export const RechargeConfirmationPage = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  return (
    <WideLayout
      outlet={isTabletOrMobile ? MainPanels() : MainPanels()}
    ></WideLayout>
  );
};
