import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SnackbarProps } from '../../types';

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: {
    open: false,
    message: '',
    type: 'info',
  } as SnackbarProps,
  reducers: {
    setSnackbar: (_, action: PayloadAction<SnackbarProps>) => action.payload,
  },
});

export const { setSnackbar } = snackbarSlice.actions;

export const snackbarReducer = snackbarSlice.reducer;
