import { useMediaQuery } from 'react-responsive';
import { usePatientsStore } from '../../stores/patientsStore';
import { usePortalStore } from '../../store';
import { TextInput } from '../../components/common/TextInput';
import { ComboBox } from '../../components/common/Combobox';
import { Container, Row } from './styles';
import { InputAdornment } from '@mui/material';
import { InfoOutlined as InfoIcon } from '@mui/icons-material';
import { Tooltip } from '../../components/common/Tooltip';
import { MultipleSelect } from '../../components/common/MultipleSelect';
import React from 'react';

export const PatientsForm = ({ disabled = false }: { disabled?: boolean }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const currentUser = usePortalStore((state: any) => state.currentUser);
  const setCurrentAlertError = usePortalStore(
    (state: any) => state.setCurrentAlertError
  );

  const name = usePatientsStore((state) => state.name);
  const email = usePatientsStore((state) => state.email);
  const sap = usePatientsStore((state) => state.sap);
  const cpf = usePatientsStore((state) => state.cpf);
  const cep = usePatientsStore((state) => state.cep);
  const city = usePatientsStore((state) => state.city);
  const uf = usePatientsStore((state) => state.uf);
  const address = usePatientsStore((state) => state.address);
  const addressLine2 = usePatientsStore((state) => state.addressLine2);
  const cellphone = usePatientsStore((state) => state.cellphone);
  const number = usePatientsStore((state) => state.number);
  const status = usePatientsStore((state) => state.status);
  const newPat = usePatientsStore((state) => state.newPat);
  const cnpj = usePatientsStore((state) => state.cnpj);
  const additionalInformation = usePatientsStore(
    (state) => state.additionalInformation
  );
  const additionalInformationList = usePatientsStore(
    (state) => state.additionalInformationList
  );

  const setName = usePatientsStore((state) => state.setName);
  const setEmail = usePatientsStore((state) => state.setEmail);
  const setSap = usePatientsStore((state) => state.setSap);
  const setCpf = usePatientsStore((state) => state.setCpf);
  const setUF = usePatientsStore((state) => state.setUF);
  const setCEP = usePatientsStore((state) => state.setCEP);
  const setCity = usePatientsStore((state) => state.setCity);
  const setAddress = usePatientsStore((state) => state.setAddress);
  const setAddressLine2 = usePatientsStore((state) => state.setAddressLine2);
  const setNumber = usePatientsStore((state) => state.setNumber);
  const setCellphone = usePatientsStore((state) => state.setCellphone);
  const setStatus = usePatientsStore((state) => state.setStatus);
  const setCNPJ = usePatientsStore((state) => state.setCNPJ);
  const setAdditionalInformation = usePatientsStore(
    (state) => state.setAdditionalInformation
  );
  const [isDisabled, setIsDisabled] = React.useState<boolean>(false);
  const getCEP = usePatientsStore((state) => state.getCEP);
  const getAdditionalInformationList = usePatientsStore(
    (state) => state.getAdditionalInformationList
  );

  const addressByCEP = async (cepRaw: any) => {
    const cep = cepRaw.trim().replace(/\D/g, '');
    let fullAdress = null;
    if (cep.length === 8) {
      fullAdress = await getCEP(cepRaw);
      if (fullAdress.erro) {
        setCurrentAlertError(
          'CEP fornecido não está ligado a nenhum endereço. Preencha o formulário com o endereço desejado.'
        );
      } else {
        setAddress(fullAdress.logradouro);
        setCity(fullAdress.localidade);
        setUF(fullAdress.uf);
      }
    } else {
      setAddress('');
      setCity('');
      setUF('');
    }
    setCEP(cep);
  };

  React.useEffect(() => {
    getAdditionalInformationList().catch(console.error);
  }, []);
  React.useEffect(() => {
    setIsDisabled(disabled);
  }, [disabled]);
  return (
    <Container isTabletOrMobile={isTabletOrMobile}>
      <Row>
        <TextInput
          value={name}
          label="Nome Completo"
          handleChange={(e) => setName(e.target.value)}
          sx={{ flex: 1 }}
          disabled={isDisabled}
        />
        <TextInput
          value={email}
          label="Email (Opcional)"
          handleChange={(e) => setEmail(e.target.value)}
          sx={{ flex: 1 }}
          disabled={isDisabled}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip
                  title="O e-mail deve pertencer ao paciente ou a um familiar"
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <InfoIcon />
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
        {(currentUser.isAdmin || currentUser.isWhiteMartins) && (
          <TextInput
            value={sap}
            label="SAP"
            handleChange={(e) => setSap(e.target.value)}
            sx={{ flex: 0.4 }}
            disabled={isDisabled}
          />
        )}
      </Row>
      <Row>
        <TextInput
          value={cpf}
          label="CPF"
          handleChange={(e) => setCpf(e.target.value)}
          sx={{ flex: 1 }}
          disabled={isDisabled}
        />
        <TextInput
          value={cellphone}
          label="Celular (com DDD)"
          handleChange={(e) => setCellphone(e.target.value)}
          sx={{ flex: 1 }}
          disabled={isDisabled}
        />
      </Row>
      <Row>
        <TextInput
          value={cep}
          label="CEP"
          handleChange={(e) => addressByCEP(e.target.value)}
          sx={{ flex: 1 }}
          disabled={isDisabled}
        />
        <TextInput
          value={city}
          label="Cidade"
          handleChange={(e) => setCity(e.target.value)}
          sx={{ flex: 1 }}
          disabled={isDisabled}
        />
        <TextInput
          value={uf}
          label="UF"
          handleChange={(e) => setUF(e.target.value)}
          sx={{ flex: 0.4 }}
          disabled={isDisabled}
        />
      </Row>
      <Row>
        <TextInput
          value={address}
          label="Endereço"
          handleChange={(e) => setAddress(e.target.value)}
          sx={{ flex: 1 }}
          disabled={isDisabled}
        />
        <TextInput
          value={number}
          label="Número"
          handleChange={(e) => setNumber(e.target.value)}
          sx={{ flex: 0.2 }}
          disabled={isDisabled}
        />
      </Row>
      <Row>
        <TextInput
          value={addressLine2}
          label="Complemento Endereço"
          handleChange={(e) => setAddressLine2(e.target.value)}
          sx={{ flex: 1 }}
          disabled={isDisabled}
        />
        {(currentUser.isAdmin || currentUser.isWhiteMartins) && (
          <TextInput
            value={cnpj}
            label="CNPJ"
            handleChange={(e) => setCNPJ(e.target.value)}
            sx={{ flex: 1 }}
            disabled={isDisabled}
          />
        )}
        {(currentUser.isAdmin || currentUser.isWhiteMartins) && !newPat && (
          <ComboBox
            label="Status"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            items={[
              { label: 'Solicitado', value: 'Solicitado' },
              { label: 'Ativo', value: 'Ativo' },
              { label: 'Inativo', value: 'Inativo' },
            ]}
            sx={{ flex: 0.5 }}
            disabled={isDisabled}
          />
        )}
      </Row>
      <Row>
        {(currentUser.isAdmin || currentUser.isWhiteMartins) && (
          <MultipleSelect
            label="Informações adicionais"
            items={additionalInformationList}
            value={additionalInformation}
            sx={{ maxWidth: '720px' }}
            onChange={(e) => {
              setAdditionalInformation(e.target.value);
            }}
            disabled={isDisabled}
          ></MultipleSelect>
        )}
      </Row>
    </Container>
  );
};
