import styled from 'styled-components';
import { Green4 } from './colors';

export const Tab = styled.div`
  display: inline-flex;
  padding: 10px;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 14px;
`;

export const TabItem = styled.div`
  border-bottom: 3px solid #58a61e;
  padding: 9px;
  margin: 0 10px;
  &:hover {
    border-bottom: 3px solid ${(props) => Green4};
    cursor: pointer;
  }
`;
