import { styled } from '@mui/material/styles';
import { TextField as MUITextField } from '@mui/material';

export const TextField = styled(MUITextField)(({ theme }) => ({
  background: theme.palette.common.white,
  borderRadius: '8px',

  '& fieldset': {
    border: `2px solid ${theme.colors.input.border}`,
    borderRadius: '8px',
  },

  '& svg': {
    color: theme.colors.input.svg,
  },
}));
