import { Api } from '../api';
import { Alarm, NewAlarmData, UpdateAlarmData } from '../types/Alarms';

export class AlarmsDataSource {
  async getAlarmTypes() {
    const result = (await new Api().fetch('/alarms/types')) as Array<Alarm>;
    return result;
  }

  async getAllAlarms() {
    const result = (await new Api().fetch('/alarm')) as Array<Alarm>;
    return result;
  }

  async getAlarmByRequestNumber(requestNumber: string) {
    try {
      const result = (await new Api().fetch(
        `/alarm/${requestNumber}`
      )) as Alarm;
      return result;
    } catch (e: any) {
      return null;
    }
  }

  async getAlarmByOrderId(id: number) {
    return (await new Api().fetch(`/alarms/order_id/${id}`)) as Alarm;
  }

  async createAlarm(data: NewAlarmData) {
    try {
      const result = await new Api().post('/alarm', data);
      return result;
    } catch (e: any) {
      console.log(`Error: ${e.toString()}`);
      return null;
    }
  }

  async updateAlarm(data: UpdateAlarmData) {
    try {
      const result = await new Api().put('/alarm', data);
      return result;
    } catch (e: any) {
      console.log(`Error: ${e.toString()}`);
    }
  }

  async getAlarmItem(alarmId: number) {
    try {
      const result = await new Api().fetch(`/alarm/id/${alarmId}`);
      return result;
    } catch (e: any) {
      console.log(`Error: ${e.toString()}`);
    }
  }
}
