import React from 'react';
import { FormControl, SxProps, TextFieldProps, Theme } from '@mui/material';
import { TextField } from './styles';

type TextInputProps = {
  label?: string;
  value?: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  sx?: SxProps<Theme>;
} & TextFieldProps;

export const TextInput: React.FC<TextInputProps> = ({
  label,
  value,
  handleChange,
  sx,
  ...rest
}) => {
  return (
    <FormControl fullWidth size="small" sx={sx}>
      <TextField
        id={`${label}-outlined-basic`}
        label={label}
        value={value}
        onChange={handleChange}
        variant="outlined"
        size="small"
        {...rest}
      />
    </FormControl>
  );
};
