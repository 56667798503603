import { DecodedToken } from '../types/base/DecodedToken';
import { UserHelper } from './UserHelper';
import { CurrentUser } from '../types/User';

export class JWTHelper {
  static getTokenFromStorage(): string | undefined {
    const currentUser = UserHelper.getCurrentUser();
    if (currentUser) {
      return currentUser.token;
    }
  }

  static decode(token?: string): DecodedToken | undefined {
    if (token) {
      const payload = token.split('.')[1];
      return JSON.parse(atob(payload));
    }
  }

  static isExpired(token?: string): boolean {
    if (token) {
      const decodedToken = JWTHelper.decode(token);

      if (decodedToken) {
        return Date.now() >= decodedToken.exp * 1000;
      }
    }

    return true;
  }
}

export async function refreshToken(currentUser?: CurrentUser) {
  try {
    if (currentUser) {
      // TODO: Implement the refresh token logic
    }
  } catch (e) {
    console.log('Unable to refresh Token', e);
  }
}
