import { useEffect, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { Green3, PrimaryTitleGray } from '../../base/colors';
import { PrimaryButton } from '../../base/Inputs';
import { Spinner } from '../../base/Spinners';
import { WideLayout } from '../../components/layout/wideLayout';
import { Panel } from '../../components/panels/panel';
import { SimplePopup } from '../../components/popup';
import { getWindowAttendance } from '../../services/ordersService';
import { usePortalStore } from '../../store';
import { DeliveryConfiguration } from './deliveryConfiguration';
import { BiErrorCircle } from 'react-icons/bi';
import { ErrorRed } from '../../base/colors';

export const DefaultFinishOrder = ({
  selectedPatient,
  topTileRender,
  bottomTileRender,
  orderType,
  onFinish,
  fastDeliveryLabel,
  scheduleDeliveryLabel,
  hasFastDelivery,
}) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const currentUser = usePortalStore((state) => state.currentUser);
  const [windowAttendance, setWindowAttendance] = useState(null);
  const [showSavingPopup, setShowSavingPopup] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [savingOrder, setSavingOrder] = useState(false);
  const [newOrderNumber, setNewOrderNumber] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    async function loadData() {
      const wndAttendance = await getWindowAttendance(selectedPatient.city);
      setWindowAttendance(wndAttendance);
    }
    loadData();
  }, []);
  const SavingPopup = () => {
    const loading = () => {
      return (
        <div style={{ textAlign: 'center', position: 'relative' }}>
          <Spinner style={{}} />
        </div>
      );
    };

    const saveStatus = () => {
      return (
        <div style={{ textAlign: 'center', position: 'relative' }}>
          <h3>
            {Array.isArray(newOrderNumber) && newOrderNumber.length > 1
              ? 'Os números dos pedidos gerados são: '
              : 'O número do seu pedido é '}
            <span style={{ color: Green3 }}>
              {Array.isArray(newOrderNumber)
                ? newOrderNumber.join(',')
                : newOrderNumber}
            </span>
          </h3>
          <div>
            <PrimaryButton onClick={() => navigate('/orders')}>
              OK
            </PrimaryButton>
          </div>
        </div>
      );
    };

    return (
      <SimplePopup
        width={500}
        zIndex={5}
        outlet={<div>{savingOrder ? loading() : saveStatus()}</div>}
      ></SimplePopup>
    );
  };

  const ErrorPopup = () => {
    return (
      <SimplePopup
        zIndex={10000}
        width={600}
        outlet={
          <div>
            <div className="error-message">
              <h3 className="erro-text">
                <BiErrorCircle
                  style={{
                    color: ErrorRed,
                    fontWeight: '500',
                    fontSize: '32px',
                  }}
                />
                Atenção!
              </h3>
            </div>
            <div>
              <p style={{ textAlign: 'center' }}>
                Erro interno contacte o administrador do sistema
              </p>
            </div>
            <div style={{ marginTop: '20px', textAlign: 'center' }}>
              <PrimaryButton onClick={() => setShowErrorPopup(false)}>
                Ok
              </PrimaryButton>
            </div>
          </div>
        }
      ></SimplePopup>
    );
  };

  const finishOrder = async (deliveryDate, deliveryTurn) => {
    setSavingOrder(true);
    const orderNumber = await onFinish(deliveryDate, deliveryTurn);
    if (orderNumber === 'erro interno') {
      setShowErrorPopup(true);
      return;
    }

    setShowSavingPopup(true);
    if (orderNumber === null) {
      setSavingOrder(false);
      setShowSavingPopup(false);
      return;
    }

    setNewOrderNumber(orderNumber);
    setSavingOrder(false);
  };

  return (
    <WideLayout
      outlet={
        <div style={{ padding: '10px' }}>
          {showSavingPopup ? SavingPopup() : <></>}
          {showErrorPopup ? ErrorPopup() : <></>}
          <h3 style={{ color: PrimaryTitleGray, margin: '10px 15px' }}>
            Resumo do pedido
          </h3>
          <div className="default-finish-order">
            <Panel className="delivery-configuration">
              <DeliveryConfiguration
                selectedPatient={selectedPatient}
                windowAttendance={windowAttendance}
                orderType={orderType}
                onFinish={finishOrder}
                savingOrder={savingOrder}
                fastDeliveryLabel={fastDeliveryLabel}
                scheduleDeliveryLabel={scheduleDeliveryLabel}
                _hasFastDelivery={hasFastDelivery}
              ></DeliveryConfiguration>
            </Panel>
            <div className="right-panels" style={{ flex: '3' }}>
              <Panel
                className="delivery-description"
                style={{
                  marginBottom: '10px',
                  flex: '3',
                  padding: '20px',
                  boxSizing: 'border-box',
                  height:
                    typeof bottomTileRender !== 'function' ? '100%' : 'auto',
                }}
              >
                {topTileRender()}
              </Panel>
              {bottomTileRender ? (
                <Panel
                  className="delivery-details"
                  width={'100%'}
                  style={{ padding: '20px' }}
                >
                  {bottomTileRender()}
                </Panel>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      }
    ></WideLayout>
  );
};
