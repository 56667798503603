import React from 'react';
import { Panel } from '../../panels/panel';

export const TitledPanel = ({
  overflow,
  style,
  title,
  subtitle,
  action,
  outlet,
  height,
}) => {
  return (
    <Panel
      overflow={overflow}
      height={height}
      style={{ ...style, height: '100%' }}
    >
      <div
        className="panel-mobile"
        style={{ display: 'block', padding: '20px', height: '100%' }}
      >
        <div className="panelbox-header">
          <div className="panelbox">
            <div className="paneltitle">{title}</div>
            {/* <div className="panelsubtitle">{subtitle}</div> */}
          </div>
          {action ? (
            <div
              className="panelbox-action"
              style={{ textAlign: 'right', paddingBottom: '10px' }}
            >
              {action}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div style={{ marginTop: '10px' }}>{outlet}</div>
      </div>
    </Panel>
  );
};
