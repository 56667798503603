import { Api } from '../api';
import { Triage, NewTriageData } from '../types/Triage';

export class TriageDataSource {
  async getByOrderId(orderId: number) {
    const result = (await new Api().fetch(
      `/triage/orderId/${orderId}`
    )) as Triage;
    return result;
  }

  async create(data: NewTriageData) {
    try {
      const result = await new Api().post('/triage/create', data);
      return result;
    } catch (e: any) {
      console.log(`Error: ${e.toString()}`);
      return null;
    }
  }

  types = {
    escreveu: 1,
    atualizacao: 2,
  };
}
