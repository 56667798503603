import { useState } from 'react';
import {
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdOutlineChangeCircle,
} from 'react-icons/md';
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { Green2, PrimaryTitleGray } from '../../base/colors';
import { InputText, PrimaryButton, Select } from '../../base/Inputs';
import { Spinner } from '../../base/Spinners';
import { ControlledExpandableTable, Table } from '../../base/table';
import { BasicPagination } from '../../components/pagination/basic';
import { getAllGasesProducts } from '../../services/rechargeService';
import { usePortalStore } from '../../store';
import { useRechargeStore } from '../../stores/rechargeStore';
import { formatCPF } from '../../utils/utils';
import { IconButton } from '@mui/material';
import { ExchangeIcon } from '../../components/icons/ExchangeIcon';
import { isGenericCylinder } from '../../utils/utils';

const PatientsListRoot = styled.div`
  overflow: auto;
`;
const PatientList = styled.ul`
  list-style: none;
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
`;
const PatientItemList = styled.li`
  padding: 5px;
  box-sizing: border-box;
`;
const PatientItemHeader = styled.div`
  border-radius: 10px;
  position: relative;
  box-sizing: border-box;
  box-shadow: rgb(207 207 207) 0px 1px 3px;
  padding: 8px 20px;
  @media screen and (max-width: 600px) {
    padding: 10px;
  }
`;
const PatientItemHeaderTitle = styled.p`
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => PrimaryTitleGray};
`;
const PatientItemBody = styled.div`
  box-sizing: border-box;
  margin-top: 0px;
  height: 0px;
  overflow: hidden;
  transition: height 0.2s linear;
`;

const PatientInsideBody = styled.div`
  margin-top: 15px;
  box-sizing: border-box;
  max-height: 300px;
  overflow: auto;

  width: 100%;
`;

export const ListPatientsRecharge = () => {
  const currentUser = usePortalStore((state) => state.currentUser);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const setSelectedPatient = useRechargeStore(
    (state) => state.setSelectedPatient
  );
  const selectedPatient = useRechargeStore((state) => state.selectedPatient);
  const listState = useRechargeStore((state) => state.listState);
  const setListState = useRechargeStore((state) => state.setListState);
  const patients = useRechargeStore((state) => state.patients);
  const fetchPatients = useRechargeStore((state) => state.fetchPatients);
  const rows = useRechargeStore((state) => state.rows);
  const setReChargerActions = useRechargeStore(
    (state) => state.setReChargerActions
  );
  const setReChargerActionsQuantity = useRechargeStore(
    (state) => state.setReChargerActionsQuantity
  );
  const setCurrentAlertError = usePortalStore(
    (state) => state.setCurrentAlertError
  );
  const rechargerActions = useRechargeStore((state) => state.rechargerActions);
  const reset = useRechargeStore((state) => state.reset);
  const exChangeCylinders = useRechargeStore(
    (state) => state.exChangeCylinders
  );
  const setExchangeCylinders = useRechargeStore(
    (state) => state.setExchangeCylinders
  );
  const expandVisible = useRechargeStore((state) => state.expandVisible);
  const setExpandVisible = useRechargeStore((state) => state.setExpandVisible);
  const setPagination = useRechargeStore((state) => state.setPagination);
  const [isLoading, setIsLoading] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const setReChargerExchangeActions = useRechargeStore(
    (state) => state.setReChargerExchangeActions
  );
  const toggle = (idx) => {
    const newList = [...listState];
    newList[idx] = !newList[idx];
    for (var i = 0; i < newList.length; i++) {
      if (i == idx) {
        const firstCylinder = 0;
        const cylinder =
          rechargerActions[idx][firstCylinder].cylinder?.cylinder ??
          rechargerActions[idx][firstCylinder].cylinder;
        if (isGenericCylinder(cylinder)) {
          toggleTable('troca', idx, firstCylinder, cylinder);
        }
      } else {
        newList[i] = false;
      }
    }
    setListState([...newList]);
    if (patients[idx] && newList[idx]) {
      setSelectedPatient(patients[idx]);
    } else {
      setSelectedPatient(null);
    }
    Object.keys(expandVisible).forEach((pId) => {
      if (pId != idx) {
        for (var x = 0; x < expandVisible[pId].length; x++) {
          expandVisible[pId][x] = false;
          //setReChargerActions(pId, x, null, '');
        }
      }
    });
    setExpandVisible(expandVisible);
  };

  const toggleTable = (value, idx, jj, cylinder) => {
    if (!expandVisible[idx]) {
      expandVisible[idx] = [];
    }

    if (value === 'troca') {
      expandVisible[idx][2 * jj + 1] = true;
    } else {
      expandVisible[idx][2 * jj + 1] = false;
    }

    setReChargerActions(idx, jj, cylinder, value);
    setExpandVisible(expandVisible);

    if (expandVisible[idx][2 * jj + 1]) {
      (async () => {
        if (exChangeCylinders[idx] && exChangeCylinders[idx][jj]) {
          return;
        }
        const references = await getAllGasesProducts();
        if (!exChangeCylinders[idx]) {
          exChangeCylinders[idx] = {};
        }
        exChangeCylinders[idx][jj] = [...references];
        setExchangeCylinders(exChangeCylinders);
      })();
    }
  };

  const toggleTableQuantity = (value, idx, jj, cylinder) => {
    setReChargerActionsQuantity(idx, jj, cylinder, parseInt(value, 10));
  };

  const toggleOnlyTable = (value, idx, jj) => {
    if (expandVisible[idx][2 * jj + 1]) {
      expandVisible[idx][2 * jj + 1] = false;
    } else {
      expandVisible[idx][2 * jj + 1] = value === 'troca';
    }
    setExpandVisible(expandVisible);
  };
  const AvailableExchangeCylinder = ({ patientIdx, cylinderIdx }) => {
    const headers = ['', 'Produto', 'Volume'];
    const fields = ['select', 'product', 'volume'];
    const setupExchange = (value, checked) => {
      if (checked) {
        setReChargerExchangeActions(
          patientIdx,
          cylinderIdx,
          exChangeCylinders[patientIdx][cylinderIdx][parseInt(value)],
          value
        );
        // expandVisible[patientIdx][2 * cylinderIdx + 1] = false;
        // setExpandVisible(expandVisible);
      }
    };
    return (
      <div style={{ boxSizing: 'border-box' }}>
        {exChangeCylinders[patientIdx] &&
        exChangeCylinders[patientIdx][cylinderIdx] ? (
          <>
            <Table
              header={headers}
              fields={fields}
              data={exChangeCylinders[patientIdx][cylinderIdx].map(
                (x, exIds) => {
                  return {
                    select: (
                      <input
                        style={{ cursor: 'pointer' }}
                        checked={
                          rechargerActions[patientIdx] &&
                          rechargerActions[patientIdx][cylinderIdx] &&
                          parseInt(
                            rechargerActions[patientIdx][cylinderIdx]
                              .newCylinderIdx
                          ) === exIds
                        }
                        value={exIds}
                        onChange={(e) =>
                          setupExchange(e.target.value, e.target.checked)
                        }
                        type="radio"
                        name={`exchange_cylinders_${patientIdx}_${cylinderIdx}`}
                      />
                    ),
                    //type: x.type ?? '',
                    product: x.description ?? '',
                    volume: x.size ?? '',
                  };
                }
              )}
            ></Table>
          </>
        ) : (
          <></>
        )}
      </div>
    );
  };
  const doPagination = async (pagination) => {
    setIsLoading(true);
    setPagination(pagination.page, pagination.pageSize);
    await fetchPatients();
    setIsLoading(false);
  };
  const moveNext = () => {
    const hasCylinderToChange =
      Object.values(rechargerActions)
        .map((x) => Object.values(x))
        .flat()
        .filter((x) => x.action === 'troca' && x.newCylinder !== undefined)
        .length > 0;
    const hasCylinderToRecharge =
      Object.values(rechargerActions)
        .map((x) => Object.values(x))
        .flat()
        .filter(
          (x) =>
            (x.action === 'recarga' && x.quantity > 0) ||
            (x.action === 'recarga' && typeof x.quantity === 'undefined')
        ).length > 0;

    if (!hasCylinderToChange && !hasCylinderToRecharge) {
      setCurrentAlertError('Nenhuma ação selecionada para prosseguir');
      return;
    }
    navigate('/recharge/confirmRecharge');
  };
  const header = ['Produto', 'Volume', 'Quantidade', 'Ação'];

  return (
    <>
      {!isLoading ? (
        <PatientsListRoot>
          <PatientList>
            {patients.map((patient, idx) => (
              <PatientItemList key={idx}>
                <PatientItemHeader className="item-header-responsive">
                  <PatientItemHeaderTitle
                    className="item-title-responsive"
                    style={{
                      opacity: patient.cylinders.length === 0 ? '0.5' : '1',
                    }}
                  >
                    {`${patient.name} | CPF: ${formatCPF(patient.cpf)} | ${
                      patient.company
                    } | CNPJ: ${patient.cnpj}`}
                  </PatientItemHeaderTitle>
                  {patient.cylinders.length > 0 ? (
                    listState[idx] ? (
                      <MdKeyboardArrowUp
                        onClick={() => toggle(idx)}
                        style={{
                          cursor: 'pointer',
                          position: 'absolute',
                          right: '3px',
                          top: '3px',
                          color: PrimaryTitleGray,
                          fontSize: '30px',
                        }}
                      ></MdKeyboardArrowUp>
                    ) : (
                      <MdKeyboardArrowDown
                        onClick={() => toggle(idx)}
                        style={{
                          cursor: 'pointer',
                          position: 'absolute',
                          right: '3px',
                          top: '3px',
                          color: PrimaryTitleGray,
                          fontSize: '30px',
                        }}
                      ></MdKeyboardArrowDown>
                    )
                  ) : (
                    <></>
                  )}

                  <PatientItemBody
                    className="item-body-responsive"
                    style={{ height: listState[idx] ? 'auto' : '0px' }}
                  >
                    <div
                      className="custom-recharge-button-mob"
                      style={{ textAlign: 'right', marginTop: '15px' }}
                    >
                      <PrimaryButton onClick={() => moveNext()}>
                        Prosseguir
                      </PrimaryButton>
                    </div>
                    <PatientInsideBody
                      className="item-body-inside-responsive"
                      style={{ minHeight: '500px' }}
                    >
                      {expandVisible[idx] ? (
                        <>
                          <ControlledExpandableTable
                            className="item-body-inside-expandle-responsive"
                            expandVisible={expandVisible[idx]}
                            expandable
                            style={{ width: '100%' }}
                            header={header}
                            data={patient.cylinders.map((c, jj) => ({
                              product: c.product,
                              volume: (
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                  }}
                                >
                                  <div
                                    style={{
                                      margin: 'auto 0px',
                                      width: 'fit-content',
                                    }}
                                  >
                                    {c.type} {c.type !== '' ? '-' : ''}{' '}
                                    {c.size ? c.size + 'm³' : ''}
                                  </div>
                                  {rechargerActions[idx] &&
                                  rechargerActions[idx][jj] &&
                                  rechargerActions[idx][jj].newCylinder ? (
                                    <>
                                      <div
                                        style={{
                                          margin: 'auto 0px',
                                          width: 'fit-content',
                                        }}
                                      >
                                        <IconButton
                                          onClick={() =>
                                            toggleOnlyTable('troca', idx, jj, c)
                                          }
                                          color="primary"
                                        >
                                          <ExchangeIcon height="20px"></ExchangeIcon>
                                        </IconButton>
                                      </div>
                                      <div
                                        style={{
                                          margin: 'auto 0px',
                                          width: 'fit-content',
                                        }}
                                      >
                                        {rechargerActions[idx][jj].newCylinder
                                          .type
                                          ? `${rechargerActions[idx][jj].newCylinder.type} - `
                                          : ''}
                                        {
                                          rechargerActions[idx][jj].newCylinder
                                            .size
                                        }
                                        m³
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              ),
                              quantity: (
                                <InputText
                                  type="number"
                                  value={
                                    rechargerActions[idx] &&
                                    rechargerActions[idx][jj]
                                      ? rechargerActions[idx][jj].quantity
                                      : 1
                                  }
                                  onChange={(e) =>
                                    toggleTableQuantity(
                                      e.target.value,
                                      idx,
                                      jj,
                                      c
                                    )
                                  }
                                ></InputText>
                              ),
                              action: (
                                <Select
                                  value={
                                    rechargerActions[idx] &&
                                    rechargerActions[idx][jj]
                                      ? rechargerActions[idx][jj].action
                                      : 'recarga'
                                  }
                                  onChange={(e) =>
                                    toggleTable(e.target.value, idx, jj, c)
                                  }
                                >
                                  {isGenericCylinder(
                                    rechargerActions[idx][jj].cylinder
                                      ?.cylinder ??
                                      rechargerActions[idx][jj].cylinder
                                  ) ? (
                                    <option value="troca">Troca</option>
                                  ) : (
                                    <>
                                      <option value="recarga">Recarga</option>
                                      <option value="troca">Troca</option>
                                    </>
                                  )}
                                </Select>
                              ),
                              expandPanel: (
                                <AvailableExchangeCylinder
                                  patient={patient}
                                  cylinderIdx={jj}
                                  patientIdx={idx}
                                  cylinder={c}
                                ></AvailableExchangeCylinder>
                              ),
                            }))}
                          ></ControlledExpandableTable>
                          <div
                            className="custom-recharge-button-web"
                            style={{ textAlign: 'right', marginTop: '15px' }}
                          >
                            <PrimaryButton onClick={() => moveNext()}>
                              Prosseguir
                            </PrimaryButton>
                          </div>
                        </>
                      ) : (
                        <Spinner></Spinner>
                      )}
                    </PatientInsideBody>
                  </PatientItemBody>
                </PatientItemHeader>
              </PatientItemList>
            ))}
          </PatientList>
        </PatientsListRoot>
      ) : (
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            overflow: 'hidden',
            padding: '20px',
            boxSizing: 'border-box',
          }}
        >
          <Spinner></Spinner>
        </div>
      )}
      <BasicPagination
        style={{ textAlign: 'center' }}
        qtyItems={rows}
        onChange={doPagination}
      ></BasicPagination>
    </>
  );
};
