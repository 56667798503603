import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Modal } from '..';
import { ModalBody, ModalHeader } from './styles';
import { CheckCircleOutline as CheckIcon } from '@mui/icons-material';

type InfoModalProps = {
  open: boolean;
  handleClose: () => void;
  message?: string;
};

export const InfoModal: React.FC<InfoModalProps> = ({
  open,
  handleClose,
  message,
}) => {
  return (
    <Modal open={open} handleClose={handleClose} width="30%">
      <Box>
        <ModalHeader>
          <Typography variant="h4" className="info-modal-title">
            <CheckIcon />
            Sucesso!
          </Typography>
        </ModalHeader>
        <ModalBody>
          <Typography variant="h5">{message}</Typography>
        </ModalBody>
        <Box style={{ marginTop: '20px', textAlign: 'center' }}>
          <Button variant="contained" onClick={() => handleClose()}>
            Ok
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
