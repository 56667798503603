import moment from 'moment';
import { Api } from '../api';
import { ComboBoxItems } from '../components/common/Combobox';
import { MultipleSelectItems } from '../components/common/MultipleSelect';
import { Order, OrderToUpdate, OrderGetAllParams } from '../types/Order';
import { Product } from '../types/Product';
import { CacheService } from '../cache/CacheService';

type MultiSelectApi = {
  id: number;
  name: string;
};

export class OrdersDataSource {
  private path = '/orders';

  /** This method is used to cache the data in the local storage for 15 days \
  The data is stored in the local storage because it is not updated frequently */
  private cache(key: string, value: object) {
    const period = moment().utc(true).add(15, 'days').toISOString();
    CacheService.set(key, value, period, true);
  }

  async getOrderById(id: number) {
    return (await new Api().fetch(`${this.path}/id/${id}`)) as Order;
  }

  async getDeliveryOperations() {
    const cachedData = CacheService.get('order-delivery-operations', true);

    if (cachedData) {
      return cachedData as MultipleSelectItems[];
    }

    const apiResponse = (await new Api().fetch(
      `${this.path}/delivery-operations`
    )) as MultiSelectApi[];

    const response = apiResponse.map((item) => ({
      label: item.name,
      value: item.id.toString(),
    })) as MultipleSelectItems[];

    this.cache('order-delivery-operations', response);

    return response;
  }

  async getServiceEvents() {
    const cachedData = CacheService.get('order-service-events', true);

    if (cachedData) {
      return cachedData as MultipleSelectItems[];
    }

    const apiResponse = (await new Api().fetch(
      `${this.path}/service-events`
    )) as MultiSelectApi[];

    const response = apiResponse.map((item) => ({
      label: item.name,
      value: item.id.toString(),
    })) as MultipleSelectItems[];

    this.cache('order-service-events', response);

    return response;
  }

  async getRetreatReasons() {
    const cachedData = CacheService.get('order-retreat-reasons', true);

    if (cachedData) {
      return cachedData as MultipleSelectItems[];
    }

    const apiResponse = (await new Api().fetch(
      `${this.path}/retreat-reasons`
    )) as MultiSelectApi[];

    const response = apiResponse.map((item) => ({
      label: item.name,
      value: item.id.toString(),
    })) as MultipleSelectItems[];

    this.cache('order-retreat-reasons', response);

    return response;
  }

  async getStatus() {
    const cachedData = CacheService.get('order-status', true);

    if (cachedData) {
      return cachedData as ComboBoxItems[];
    }

    const apiResponse = (await new Api().fetch(
      `${this.path}/status`
    )) as MultiSelectApi[];

    const response = apiResponse.map((item) => ({
      label: item.name,
      value: item.id.toString(),
    })) as ComboBoxItems[];

    this.cache('order-status', response);

    return response;
  }

  async getStatusComplements() {
    const cachedData = CacheService.get('order-status-complements', true);

    if (cachedData) {
      return cachedData as ComboBoxItems[];
    }

    const apiResponse = (await new Api().fetch(
      `${this.path}/status-complements`
    )) as MultiSelectApi[];

    const response = apiResponse.map((item) => ({
      label: item.name,
      value: item.id.toString(),
    })) as ComboBoxItems[];

    this.cache('order-status-complements', response);

    return response;
  }

  async update(order: OrderToUpdate) {
    return await new Api().put(`${this.path}`, order);
  }

  async getAll(filters: OrderGetAllParams) {
    return await new Api().post(`${this.path}/get-all`, filters);
  }

  async getOrderProductDetails(
    request_number_temp: string
  ): Promise<Product[]> {
    return (await new Api().fetch(
      `${this.path}/details/${request_number_temp}`
    )) as Product[];
  }
}
