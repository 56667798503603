import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

type AlarmLampStyleProps = {
  color: string;
  is_flashing: string;
};

export const StyledAlarmLamp = styled(Box)<AlarmLampStyleProps>(
  ({ color, is_flashing }) => ({
    backgroundColor: color,
    width: '1em',
    boxSizing: 'border-box',
    height: '10em',

    animation: is_flashing === 'true' ? 'flasher 1.5s infinite' : 'none',

    '@keyframes flasher': {
      '50%': {
        opacity: 0,
      },
    },
  })
);
