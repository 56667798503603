import React from 'react';
import { TitledPanel } from '../../components/dashboards/titledPanel/titledPanel';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  BarElement,
  BarController,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { DarkGreen, Green3 } from '../../base/colors';
import { useMediaQuery } from 'react-responsive';
import { useKPIStore } from '../../stores/kpiStore';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  BarController,
  Title,
  Tooltip,
  Legend,
  Filler
);

const AllocatedEquipmentChart = () => {
  const categories = [
    'Oxigenoterapia',
    'Ventilação domiciliar',
    'Linha do sono',
    'Aspiradores de secreção',
    'Oxímetros',
    'Dispositivos e acessórios',
  ];
  const tooltipCustom = (tooltipItems) => {
    tooltipItems.forEach(function (tooltipItem) {
      tooltipItem.label = tooltipItem.label.replace(',', ' ');
      return tooltipItem.label;
    });
  };
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const setSelectedCategory = useKPIStore((state) => state.setSelectedCategory);
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    onClick: (a, b, c, d) => {
      setSelectedCategory(categories[b[0].index]);
    },
    scales: {
      x: {
        align: 'center',
        grid: {
          display: false,
        },
        ticks: {
          // Include a dollar sign in the ticks
          maxRotation: 0,
          minRotation: 0,
          padding: 10,
          autoSkip: false,
          font: {
            size: isTabletOrMobile ? 8 : 12,
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          beginAtZero: true,
          callback: function (value) {
            if (value % 1 === 0) {
              return value;
            }
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },

      title: {
        display: false,
      },
      filler: {
        propagate: true,
      },
      tooltip: {
        callbacks: {
          beforeTitle: tooltipCustom,
        },
      },
    },
  };
  const patients = useKPIStore((state) => state.patients);
  const allocatedEquipmentList = [
    ['Oxigenoterapia'],
    ['Ventilação', 'domiciliar'],
    ['Linha', 'do sono'],
    ['Aspiradores', 'de secreção'],
    ['Oxímetros'],
    ['Dispositivos', 'e acessórios'],
  ];
  const getData = () => {
    const values = [0, 0, 0, 0, 0, 0];
    const mapFields = {};
    categories.forEach((c, idx) => (mapFields[c] = idx));
    patients.forEach((patient) => {
      patient.equipments.forEach((eq) => {
        if (eq.status?.toLowerCase() === 'ativo') {
          values[
            mapFields[
              eq.category === 'Ventilação Domiciliar'
                ? 'Ventilação domiciliar'
                : eq.category === 'Linha do Sono'
                ? 'Linha do sono'
                : eq.category === 'Aspiradores de Secreção'
                ? 'Aspiradores de secreção'
                : eq.category === 'Dispositivos e Acessórios'
                ? 'Dispositivos e acessórios'
                : eq.category
            ]
          ] += parseInt(eq.total_balance);
        }
      });
    });
    return values;
  };

  const data = {
    labels: allocatedEquipmentList,
    datasets: [
      {
        type: 'bar',
        data: getData(),
        borderColor: DarkGreen,
        pointRadius: 0,
        borderWidth: 0,
        borderRadius: 10,
        backgroundColor: Green3 + '5f',
        fill: {
          target: 'origin',
          above: 'rgba(247, 247, 247,0.2)', // Area will be red above the origin
        },
      },
    ],
  };
  return (
    <div style={{ height: '150px', overflow: 'hidden', width: '100%' }}>
      <Line options={options} data={data} />
    </div>
  );
};
export const AllocatedEquipmentsChartKPI = ({ height }) => {
  return (
    <TitledPanel
      height={height}
      title="Produtos locados"
      outlet={AllocatedEquipmentChart()}
    ></TitledPanel>
  );
};
