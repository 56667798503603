import create from 'zustand';
import {
  deactivatePatient,
  getCEP,
  getPatients,
  savePatient,
} from '../services/patientsService';
import { PatientDataSource } from '../datasources/PatientDataSource';

export const usePatientsStore = create((set, get) => ({
  id: 0,
  name: '',
  email: '',
  sap: '',
  cpf: '',
  birthDate: '',
  responsibleName: '',
  cep: '',
  city: '',
  uf: '',
  number: '',
  phoneNumber: '',
  responsiblePhoneNumber: '',
  address: '',
  addressLine2: '',
  cnpj: '',
  cellphone: '',
  status: '',
  newPat: null,
  homeVoltage: '',
  diagnosisHypothesis: '',
  onPaymentList: null,
  patients: [],
  currentSelectPatientCPF: -1,
  fetchTotalItems: 0,
  kpiSelectedPatient: null,
  error: null,
  additionalInformation: [],
  additionalInformationList: [],
  searchTerm: '',
  setAdditionalInformation: (additionalInformation) =>
    set({ additionalInformation }),
  getAdditionalInformationList: async () => {
    const data = await new PatientDataSource().getAdditionalInformation();
    set({ additionalInformationList: data });
  },
  setId: (id) => set({ id }),
  setCurrentSelectPatientCPF: (currentSelectPatientCPF) =>
    set({ currentSelectPatientCPF }),
  setName: (name) => set({ name }),
  setEmail: (email) => set({ email }),
  setSap: (sap) => set({ sap }),
  setFetchTotalItems: (fetchTotalItems) => set({ fetchTotalItems }),
  setCpf: (cpf) => set({ cpf }),
  setBirthDate: (birthDate) => set({ birthDate }),
  setResponsibleName: (responsibleName) => set({ responsibleName }),
  setCEP: (cep) => set({ cep }),
  setCity: (city) => set({ city }),
  setNumber: (number) => set({ number }),
  setCNPJ: (cnpj) => set({ cnpj }),
  setUF: (uf) => set({ uf }),
  setPhoneNumber: (phoneNumber) => set({ phoneNumber }),
  setStatus: (status) => set({ status }),
  setNewPat: (newPat) => set({ newPat }),
  setResponsiblePhoneNumber: (responsiblePhoneNumber) =>
    set({ responsiblePhoneNumber }),
  setAddress: (address) => set({ address }),
  setAddressLine2: (addressLine2) => set({ addressLine2 }),
  setCellphone: (cellphone) => set({ cellphone }),
  setHomeVoltage: (homeVoltage) => set({ homeVoltage }),
  setDiagnosisHypothesis: (diagnosisHypothesis) => set({ diagnosisHypothesis }),
  setOnPaymentList: (onPaymentList) => set({ onPaymentList }),
  setKpiSelectedPatient: (patient) => {
    if (patient === null) {
      set({ kpiSelectedPatient: null });
    } else {
      set({ kpiSelectedPatient: { ...patient } });
    }
  },
  setSearchTerm: (searchTerm) => set({ searchTerm }),
  clearPatientsList: () => {
    set({ patients: [], fetchTotalItems: 0 });
  },
  fetchPatients: async (cnpj, filter, page, pageSize, status) => {
    const value = await getPatients(cnpj, filter, page, pageSize, status);
    set({
      patients: [...value.data],
      fetchTotalItems: value.rows,
      error: value.error,
    });
  },
  insertPatient: async () => {
    const newPatient = {
      id: get().id,
      name: get().name,
      email: get().email,
      sap: get().sap,
      cpf: get().cpf,
      cnpj: get().cnpj,
      cep: get().cep,
      city: get().city,
      uf: get().uf,
      number: get().number,
      address: get().address,
      addressLine2: get().addressLine2,
      cellphone: get().cellphone,
      status: get().status,
      on_payment_list: get().onPaymentList,
      additionalInformation: get().additionalInformation,
    };

    return await savePatient(newPatient);
  },
  deactivatePatient: async () => {
    const cpf = get()
      .currentSelectPatientCPF.replaceAll('.', '')
      .replaceAll('-', '');
    const resp = await deactivatePatient(cpf);
    return resp;
  },
  getCEP: async (cep) => {
    const resp = await getCEP(cep);
    return resp;
  },
}));
