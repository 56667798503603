import { FormHelperText } from '@mui/material';

type HelperTextProps = {
  message?: string;
};

export const HelperText: React.FC<HelperTextProps> = ({ message }) => {
  if (message)
    return (
      <FormHelperText
        sx={{
          position: 'absolute',
          bottom: -20,
          left: -10,
          fontSize: '11px',
        }}
      >
        {message}
      </FormHelperText>
    );

  return <></>;
};
