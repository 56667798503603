import 'react-image-gallery/styles/css/image-gallery.css';
import { getProductsImages } from '../../services/productService';
import { useEffect, useState } from 'react';
import { ImageSlider } from './slider';
import { getBase64Img } from '../../utils/utils';

export const ProductImage = ({ maxWidth, codes, hideNoImage }) => {
  const [images, setImages] = useState([]);
  const codesNoRep = codes.filter((el, idx) => codes.indexOf(el) === idx);
  useEffect(() => {
    (async () => {
      const imgs = await getProductsImages(codesNoRep);
      setImages(imgs.map((x) => getBase64Img(x)));
    })();
  }, [codes]);

  return (
    <ImageSlider
      hideNoImage={hideNoImage}
      maxWidth={maxWidth}
      images={images}
    ></ImageSlider>
  );
}; //
