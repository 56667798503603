import { TitledPanel } from '../../components/dashboards/titledPanel/titledPanel';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  BarElement,
  BarController,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { DarkGreen, Green3, PrimaryTitleGray } from '../../base/colors';
import { useMediaQuery } from 'react-responsive';
import { useKPIStore } from '../../stores/kpiStore';
import { RiCloseCircleFill } from 'react-icons/ri';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  BarController,
  Title,
  Tooltip,
  Legend,
  Filler
);

const AllocatedEquipmentChart = () => {
  const categories = [
    'Oxímetros',
    'Linha do aono',
    'Ventilação domiciliar',
    'Oxigenoterapia',
    'Aspiradores de secreção',
    'Dispositivos e acessórios',
  ];
  const tooltipCustom = (tooltipItems) => {
    tooltipItems.forEach(function (tooltipItem) {
      tooltipItem.label = tooltipItem.label.replace(',', ' ');
      return tooltipItem.label;
    });
  };
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const selectedCategory = useKPIStore((state) => state.selectedCategory);
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        align: 'center',
        grid: {
          display: false,
        },
        ticks: {
          // Include a dollar sign in the ticks
          maxRotation: 0,
          minRotation: 0,
          padding: 10,
          autoSkip: false,
          font: {
            size: isTabletOrMobile ? 8 : 12,
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          beginAtZero: true,
          callback: function (value) {
            if (value % 1 === 0) {
              return value;
            }
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },

      title: {
        display: false,
      },
      filler: {
        propagate: true,
      },
      tooltip: {
        callbacks: {
          beforeTitle: tooltipCustom,
        },
      },
    },
  };
  const patients = useKPIStore((state) => state.patients);
  const subCategoriesMap = {};
  let category =
    selectedCategory === 'Ventilação domiciliar'
      ? 'Ventilação Domiciliar'
      : selectedCategory === 'Linha do sono'
      ? 'Linha do Sono'
      : selectedCategory === 'Aspiradores de secreção'
      ? 'Aspiradores de Secreção'
      : selectedCategory === 'Dispositivos e acessórios'
      ? 'Dispositivos e Acessórios'
      : undefined;
  patients.forEach((patient) => {
    patient.equipments.forEach((eq) => {
      if (
        (eq.category === selectedCategory || eq.category === category) &&
        !subCategoriesMap[eq.subcategory]
      ) {
        subCategoriesMap[eq.subcategory] = 0;
      }
    });
  });
  const allocatedEquipmentList = Object.keys(subCategoriesMap).map((x) =>
    x.split(' ')
  );

  const getData = () => {
    const values = Object.keys(subCategoriesMap).map((x) => 0);
    const mapFields = {};
    Object.keys(subCategoriesMap).forEach((c, idx) => (mapFields[c] = idx));
    patients.forEach((patient) => {
      patient.equipments
        .filter(
          (eq) => eq.category === selectedCategory || eq.category === category
        )
        .forEach((eq) => {
          if (eq.status?.toLowerCase() === 'ativo') {
            values[mapFields[eq.subcategory]] += parseInt(eq.total_balance);
          }
        });
    });
    return values;
  };

  const data = {
    labels: allocatedEquipmentList,
    datasets: [
      {
        type: 'bar',
        data: getData(),
        borderColor: DarkGreen,
        pointRadius: 0,
        borderWidth: 0,
        borderRadius: 10,
        backgroundColor: Green3 + '5f',
        fill: {
          target: 'origin',
          above: 'rgba(247, 247, 247,0.2)', // Area will be red above the origin
        },
      },
    ],
  };
  return (
    <div style={{ height: '150px', overflow: 'hidden', width: '100%' }}>
      <Line options={options} data={data} />
    </div>
  );
};
const BackAction = ({ setSelectedCategory }) => {
  return (
    <div>
      <RiCloseCircleFill
        style={{ cursor: 'pointer', fontSize: '40px', color: PrimaryTitleGray }}
        onClick={() => setSelectedCategory('')}
      ></RiCloseCircleFill>
    </div>
  );
};
export const AllocatedEquipmentsChartSubcategoryKPI = ({ height }) => {
  const selectedCategory = useKPIStore((state) => state.selectedCategory);
  const setSelectedCategory = useKPIStore((state) => state.setSelectedCategory);
  return (
    <TitledPanel
      height={height}
      title={selectedCategory}
      subtitle="Equipamentos"
      outlet={AllocatedEquipmentChart()}
      action={BackAction({ setSelectedCategory })}
    ></TitledPanel>
  );
};
