import styled from 'styled-components';
import { BrighterGreen, DarkGreen } from './colors';

export const SuccessMessage = styled.div`
  border: 1px solid ${DarkGreen};
  background-color: ${BrighterGreen}3d;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  color: ${DarkGreen};
  font-weight: 500;
  line-height: 12px;
  font-size: 12px;
  border-radius: 5px;
  text-align: left;
`;

export const ErrorMessage = styled.div`
  border: 1px solid #ff0000;
  background-color: #ff070094;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  color: #ffffff;
  font-weight: 500;
  line-height: 12px;
  font-size: 12px;
  border-radius: 5px;
  text-align: left;
`;
