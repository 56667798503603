import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { CustomSelect } from '../base/customSelect';
import { TitledPanel } from '../components/dashboards/titledPanel/titledPanel';
import { WideLayout } from '../components/layout/wideLayout';
import { usePortalStore } from '../store';
import { useKPIStore } from '../stores/kpiStore';
import { AllocatedEquipmentsChartKPI } from '../usecase/kpis/allocatedEquipment';
import { AllocatedEquipmentsChartSubcategoryKPI } from '../usecase/kpis/allocatedEquipmentSubcategory';
import { AutoCompletePatients } from '../usecase/kpis/autocomplete';
import { CurrentLease } from '../usecase/kpis/currentLease';
import { ListPatientsKPI } from '../usecase/kpis/listPatientsKPI';
import { OxygenConsumption } from '../usecase/kpis/oxigenConsumption';
import { HiOutlineDocumentText } from 'react-icons/hi';
import XLSX from 'sheetjs-style';
import { useRef, useState } from 'react';
import { useCallback } from 'react';
import { ExcelTable } from '../usecase/kpis/excelTable';
import { formatMonthYear, getLast12Months } from '../utils/utils';
import { BasicPagination } from '../components/pagination/basic';
import { PrimaryButton } from '../base/Inputs';

const RootDiv = styled.div`
  display: block;
  width: 100%;
  box-sizing: border-box;
`;

const StackDiv = styled.div`
  // display:-webkit-inline-flex;
  width: 100%;
`;

const Page = ({ currentUser, isTabletOrMobile }) => {
  const selectedCategory = useKPIStore((state) => state.selectedCategory);
  const fetchPatientsToReport = useKPIStore(
    (state) => state.fetchPatientsToReport
  );
  const patients = useKPIStore((state) => state.patients);
  const setSelectedDate = useKPIStore((state) => state.setSelectedDate);
  const rows = useKPIStore((state) => state.rows);
  const fetchPatientsKpi = useKPIStore((state) => state.fetchPatientsKpi);
  const setPagination = useKPIStore((state) => state.setPagination);
  const [showTable, setShowTable] = useState(false);
  const [reportData, setReportData] = useState({
    oxygenConsumed: 0,
    currentLeasing: 0,
    patients: [],
  });
  let table = useRef(null);
  const exportFile = useCallback(() => {
    (async () => {
      setShowTable(true);
      const data = await fetchPatientsToReport();
      setReportData(data);
      setTimeout(() => {
        const elt = table.current;
        const cells = Array.from(elt.querySelectorAll('td')).map((n) => ({
          prop: n.getAttribute('prop'),
          bgColor: n.getAttribute('cell-bg-color'),
          cellBorder: n.getAttribute('cell-border'),
          cellFormat: n.getAttribute('cell-format'),
          cellType: n.getAttribute('cell-type'),
        }));
        const wb = XLSX.utils.table_to_book(elt, {
          raw: true,
          cellDates: 'd',
          dateNF: 'dd/mm/yyyy',
        });
        wb.Sheets.Sheet1['!cols'] = [
          { wch: 20 },
          { wch: 11 },
          { wch: 25 },
          { wch: 20 },
          { wch: 20 },
          { wch: 14 },
          { wch: 10 },
          { wch: 8 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
        ];
        cells.forEach((c) => {
          if (wb.Sheets.Sheet1[c.prop]) {
            let style = {
              border: c.cellBorder ? JSON.parse(c.cellBorder) : null,
              fill: {
                // bgColor:{ rgb: c.bgColor ? c.bgColor.toUpperCase() : 'FFFFFFFF' },
                fgColor: {
                  rgb: c.bgColor ? c.bgColor.toUpperCase() : 'FFFFFFFF',
                },
              },
              wpx: c.wpx ? parseInt(c.wpx, 10) : null,
            };
            if (c.cellFormat) {
              const ff = JSON.parse(c.cellFormat);
              style = { ...style, ...ff };
            }
            wb.Sheets.Sheet1[c.prop].t = c.cellType ?? 's';
            wb.Sheets.Sheet1[c.prop].s = { ...style };
          }
        });
        XLSX.writeFile(wb, `PortalHomecareReport-${Date.now()}.xlsx`);
        //setShowTable(false);
      }, 1500);
    })();
  }, [table]);
  const exportAction = () => {
    return (
      <PrimaryButton
        style={{
          float: 'right',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onClick={exportFile}
      >
        <HiOutlineDocumentText
          style={{ fontSize: '20px' }}
        ></HiOutlineDocumentText>
        <span>Exportar</span>
      </PrimaryButton>
    );
  };

  const desktop = () => {
    return (
      <StackDiv
        className="report-mobile-content"
        style={{
          width: '100%',
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
        }}
      >
        <div style={{ display: 'flex', gap: '10px' }}>
          {selectedCategory !== '' ? (
            <div
              style={{ flex: 2, position: 'relative', boxSizing: 'border-box' }}
            >
              <AllocatedEquipmentsChartSubcategoryKPI
                height={'255px'}
              ></AllocatedEquipmentsChartSubcategoryKPI>
            </div>
          ) : (
            <>
              <div style={{ flex: 1, display: 'flex' }}>
                <TitledPanel
                  height={'255px'}
                  title="Consumo mensal de oxigênio"
                  style={{ border: 'none' }}
                  outlet={<OxygenConsumption></OxygenConsumption>}
                ></TitledPanel>
              </div>
              {currentUser.canSeeFinancialDataOnReport ? (
                <div style={{ flex: 1, display: 'flex' }}>
                  <TitledPanel
                    height={'255px'}
                    title="Valor de locação atual"
                    style={{}}
                    outlet={<CurrentLease></CurrentLease>}
                  ></TitledPanel>
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
        <div
          className="report-mobile"
          style={{ flex: 2, position: 'relative', boxSizing: 'border-box' }}
        >
          <AllocatedEquipmentsChartKPI
            height={'255px'}
          ></AllocatedEquipmentsChartKPI>
        </div>
      </StackDiv>
    );
  };

  const mobile = () => {
    return (
      <>
        <StackDiv
          style={{ width: '100%', boxSizing: 'border-box', marginTop: '10px' }}
        >
          <div
            style={{ flex: 1, position: 'relative', boxSizing: 'border-box' }}
          >
            <AllocatedEquipmentsChartKPI
              height={'255px'}
            ></AllocatedEquipmentsChartKPI>
          </div>
        </StackDiv>
        {selectedCategory !== '' ? (
          <StackDiv
            style={{
              width: '100%',
              boxSizing: 'border-box',
              marginTop: '10px',
            }}
          >
            <div
              style={{ flex: 1, position: 'relative', boxSizing: 'border-box' }}
            >
              <AllocatedEquipmentsChartSubcategoryKPI
                height={'255px'}
              ></AllocatedEquipmentsChartSubcategoryKPI>
            </div>
          </StackDiv>
        ) : (
          <>
            <StackDiv
              style={{
                width: '100%',
                boxSizing: 'border-box',
                marginTop: '10px',
              }}
            >
              <TitledPanel
                height={'255px'}
                title="Consumo mensal de oxigênio"
                style={{ border: 'none' }}
                outlet={<OxygenConsumption></OxygenConsumption>}
              ></TitledPanel>
            </StackDiv>
            {currentUser.canSeeFinancialDataOnReport ? (
              <StackDiv
                style={{
                  width: '100%',
                  boxSizing: 'border-box',
                  marginTop: '10px',
                }}
              >
                <TitledPanel
                  height={'255px'}
                  title="Valor de locação atual"
                  style={{ border: 'none' }}
                  outlet={<CurrentLease></CurrentLease>}
                ></TitledPanel>
              </StackDiv>
            ) : (
              <></>
            )}
          </>
        )}
      </>
    );
  };
  const dates = getLast12Months(new Date()).map((x) => ({
    name: formatMonthYear(x.month, x.year),
    value: x,
  }));
  const onSelectDate = (obj) => {
    setSelectedDate(obj.value.month, obj.value.year);
  };
  const paginate = (e) => {
    setPagination(e.page, e.pageSize);
    fetchPatientsKpi();
  };
  return (
    <RootDiv>
      {showTable ? (
        <ExcelTable
          table={table}
          data={reportData}
          user={currentUser}
        ></ExcelTable>
      ) : (
        <></>
      )}
      <div style={{ margin: '15px', boxSizing: 'border-box' }}>
        {isTabletOrMobile ? (
          <>
            {' '}
            <div className="mobile-fix">
              <AutoCompletePatients
                style={{ width: '100%' }}
              ></AutoCompletePatients>
              <CustomSelect
                style={{ width: '100%' }}
                values={dates}
                leftIcon={'calendar'}
              ></CustomSelect>
            </div>
          </>
        ) : (
          <div className="mobile-fix">
            <AutoCompletePatients
              style={{ flex: 2, marginRight: '10px' }}
            ></AutoCompletePatients>
            <CustomSelect
              defaultValue={dates[0]}
              style={{ flex: 1 }}
              values={dates}
              onSelect={onSelectDate}
              leftIcon={'calendar'}
            ></CustomSelect>
          </div>
        )}

        {isTabletOrMobile ? mobile() : desktop()}
        <StackDiv
          style={{ width: '100%', boxSizing: 'border-box', marginTop: '20px' }}
        >
          <div
            style={{ flex: 1, position: 'relative', boxSizing: 'border-box' }}
          >
            <TitledPanel
              height={'550px'}
              title="Pacientes"
              outlet={
                <>
                  <ListPatientsKPI></ListPatientsKPI>
                  <BasicPagination
                    style={{ textAlign: 'center' }}
                    qtyItems={rows}
                    onChange={(e) => paginate(e)}
                  ></BasicPagination>
                </>
              }
              action={exportAction()}
            ></TitledPanel>
          </div>
        </StackDiv>
      </div>
    </RootDiv>
  );
};

export const KpiPage = () => {
  const currentUser = usePortalStore((state) => state.currentUser);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  return (
    <WideLayout outlet={Page({ currentUser, isTabletOrMobile })}></WideLayout>
  );
};
