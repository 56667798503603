import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
  BlueText,
  BoldTextColor,
  PrimaryBackground,
  PrimaryTextColor,
} from '../base/colors';
import { UserShouldRedefineHisPword } from '../base/errors';
import { InputText, PrimaryButton, SecondaryButton } from '../base/Inputs';
import { LoginForm, LoginImage } from '../base/LoginComponents';
import { ErrorMessage } from '../base/message';
import { ShouldVerifyEmail } from '../base/messages';
import { Spinner } from '../base/Spinners';
import { LoginService } from '../services/auth';
import { ping, verifyCaptcha } from '../services/userMgntService';
import ReCAPTCHA from 'react-google-recaptcha';
const loginService = LoginService;
const PBemVindo = styled.p`
  color: ${(props) => BoldTextColor};
  font-size: 24px;
  text-align: center;
  margin: 7px 0 0 0;
`;
const PAcessarSuaConta = styled.p`
  color: ${(props) => PrimaryTextColor};
  font-size: 20px;
  text-align: center;
  margin: 10px 0 0 0;
  padding-top: 0px;
`;

const DivPanel1 = styled.div`
  text-align: center;
  margin-top: 30px;
  position: relative;
`;

const DivPanel2 = styled.div`
  width: 90%;
  margin-left: 5%;
`;

const DivPanel3 = styled.div`
  text-align: left;
  margin-left: 3.8%;
  margin-top: 15px;
`;

const CustomErrorMessage = styled(ErrorMessage)`
  width: 86%;
  margin-left: 4%;
  margin-bottom: 10px;
`;
const Span90 = styled.span`
  width: 90%;
`;
const InputText90Top15 = styled(InputText)`
  width: 90%;
  margin-top: 15px;
`;
const InputTextWidth = styled(InputText)`
  width: ${(props) => props.width};
`;

const PWithFontSize = styled.p`
  font-size: ${(props) => props.fontSize};
`;

const DivDisplay = styled.div`
  display: ${(props) => props.display};
`;

const DivPanel4 = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 200px;
`;
const DivPanel5 = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
`;

const DivPanel6 = styled.div`
  background-color: ${(props) => PrimaryBackground};
  max-width: 90%;
  padding: 15px;
  margin-left: 5%;
  box-sizing: border-box;
  position: relative;
  border-radius: 20px;
`;
const DivPanel7 = styled.div`
  background-color: ${(props) => PrimaryBackground};
  box-sizing: border-box;
  // position: absolute;
  // top: ${(props) => (window.innerHeight > 760 ? 'calc(100vh - 760px)' : '')};
  // left: calc(50% - 200px);
  margin: 0 auto;
  width: 400px;
`;

const LabelForgotPword = styled.span`
  margin-top: 25px;
  color: ${(props) => BlueText};
  cursor: pointer;
`;

const SpanRights = styled.span`
  position: fixed;
  bottom: 0px;
  right: 0;
  padding: 7px;
`;
const LogoLogin = styled.div`
  width: 400px;
  height: 170px;
  position: relative;
  left: 50%;
  transform: translate(-50%);
  background-image: url(/icones/logo-color.png);
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 24px auto;
  @media screen and (max-width: 600px) {
    width: 300px;
    height: 52px;
  }
`;

export const LoginPage = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const isSmallLapTop = useMediaQuery({ query: '(max-width: 1669px)' });
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [keepConnected, setKeepConnected] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [message, setMessage] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [shouldRedefinePassword, setShouldRedefinePassword] = useState(false);
  const [shouldVerifyEmail, setShouldVerifyEmail] = useState(false);
  const [shouldRecoveryPassword, setRecoveryPassword] = useState(false);
  const [userForgotPassword, setUserForgotPassword] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  let navigate = useNavigate();
  const authenticate = async (recaptchaValue) => {
    setIsLogin(true);
    const result = await loginService.doAuth(
      email,
      password,
      keepConnected,
      recaptchaValue
    );
    setIsLogin(false);
    if (!result.logged) {
      setMessage(result.message);
      setShouldRedefinePassword(result.message === UserShouldRedefineHisPword);
      setShouldVerifyEmail(result.message === ShouldVerifyEmail);
      window?.grecaptcha?.reset();
    } else {
      setMessage('');
    }
  };
  const redefinePassword = async () => {
    setPassword('');
    setIsLogin(true);
    const result = await loginService.redefinePassword(
      newPassword,
      keepConnected
    );
    setIsLogin(false);
    if (!result.logged) {
      setMessage(result.message);
      setShouldRedefinePassword(result.message === UserShouldRedefineHisPword);
      setShouldVerifyEmail(result.message === ShouldVerifyEmail);
    } else {
      setMessage('');
    }
  };
  const verifyEmail = async () => {
    setIsLogin(true);
    const result = await loginService.verifyEmail(
      verificationCode,
      keepConnected
    );
    setIsLogin(false);
    if (!result.logged) {
      setMessage(result.message);
      setShouldRedefinePassword(result.message === UserShouldRedefineHisPword);
      setShouldVerifyEmail(result.message === ShouldVerifyEmail);
    } else {
      setMessage('');
    }
  };

  const startRecovery = async (value) => {
    setIsLogin(true);
    const resp = await verifyCaptcha(value);
    const response = await resp.json();
    if (resp.status !== 200) {
      setMessage(response.message);
      setIsLogin(false);
      return;
    }
    const result = await loginService.forgotPassword(email);
    setIsLogin(false);
    setMessage(result.message);
    setUserForgotPassword(false);
    setRecoveryPassword(result.sentLink);
  };

  const forgotPasswordSubmit = async () => {
    setIsLogin(true);
    const result = await loginService.recoveryPassword(
      email,
      newPassword,
      verificationCode
    );
    setIsLogin(false);
    if (!result.logged) {
      setMessage(result.message);
    } else {
      setMessage('');
    }
  };

  const forgotPassword = () => {
    return (
      <>
        <div className="logo-login">
          <LogoLogin></LogoLogin>
        </div>
        <PBemVindo>
          Bem-vindo ao <b>Portal Homecare</b>
        </PBemVindo>
        <PAcessarSuaConta>Acessar sua conta</PAcessarSuaConta>
        <DivPanel1>
          {message !== '' && message != undefined ? (
            <CustomErrorMessage>{message}</CustomErrorMessage>
          ) : (
            <></>
          )}
          <InputTextWidth
            width={'90%'}
            placeholder="E-mail"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            value={email}
          />
          <br />
          <br />
          <DivPanel2>
            <div style={{ padding: '20px' }}>
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                onChange={(value) => setRecaptchaToken(value)}
              />
            </div>
            {isLogin ? (
              <Spinner />
            ) : (
              <PrimaryButton onClick={() => startRecovery(recaptchaToken)}>
                Enviar código de recuperação
              </PrimaryButton>
            )}
          </DivPanel2>
        </DivPanel1>
      </>
    );
  };

  const forgotPasswordSubmitForm = () => {
    return (
      <>
        <div className="logo-login">
          <LogoLogin></LogoLogin>
        </div>
        <PBemVindo>
          Bem-vindo ao <b>Portal Homecare</b>
        </PBemVindo>
        <PAcessarSuaConta>Acessar sua conta</PAcessarSuaConta>
        <DivPanel1>
          {message !== '' && message != undefined ? (
            <CustomErrorMessage>{message}</CustomErrorMessage>
          ) : (
            <></>
          )}
          <Span90>{email}</Span90>
          <br />
          <InputText90Top15
            type={'password'}
            placeholder="Nova Senha"
            onChange={(e) => {
              setNewPassword(e.target.value);
            }}
            value={newPassword}
          />
          <InputTextWidth
            width={'90%'}
            placeholder="Código"
            onChange={(e) => {
              setVerificationCode(e.target.value);
            }}
            value={verificationCode}
          />
          <DivPanel3></DivPanel3>
          <br />
          <DivPanel2>
            <PrimaryButton onClick={forgotPasswordSubmit}>
              Redefinir senha
            </PrimaryButton>
          </DivPanel2>
        </DivPanel1>
      </>
    );
  };

  const onEnterTyped = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      authenticate(recaptchaToken);
    }
  };
  const onClickForgotPassword = () => {
    setMessage('');
    window.grecaptcha.reset();
    window.location.pathname = '/recovery';
  };
  const loginForm = () => (
    <>
      <div className="logo-login">
        <LogoLogin></LogoLogin>
      </div>
      <PBemVindo>
        Bem-vindo ao <b>Portal Homecare</b>
      </PBemVindo>
      <PAcessarSuaConta>Acessar sua conta</PAcessarSuaConta>
      <DivPanel1>
        {message !== '' && message != undefined ? (
          <CustomErrorMessage>{message}</CustomErrorMessage>
        ) : (
          <></>
        )}

        <InputTextWidth
          onKeyUp={(e) => onEnterTyped(e)}
          width={'90%'}
          placeholder="E-mail"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          value={email}
        />
        <InputText90Top15
          onKeyUp={(e) => onEnterTyped(e)}
          type={'password'}
          placeholder="Senha"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          value={password}
        />
        <br />
        <DivPanel3>
          <InputText
            type={'checkbox'}
            onClick={() => setKeepConnected(!keepConnected)}
          />
          <span>Manter conectado</span>
        </DivPanel3>
        <br />
        <DivPanel2>
          <div style={{ padding: '20px' }}>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
              onChange={(value) => setRecaptchaToken(value)}
            />
          </div>
          {isLogin ? (
            <Spinner />
          ) : (
            <PrimaryButton onClick={() => authenticate(recaptchaToken)}>
              Acessar
            </PrimaryButton>
          )}
          <br />
          <br />
          {/* <SecondaryButton>Acessar com sua conta White Martins</SecondaryButton> 
                    <br/>
                    <br/>*/}
          <LabelForgotPword onClick={() => onClickForgotPassword()}>
            Esqueceu sua senha?
          </LabelForgotPword>
        </DivPanel2>
      </DivPanel1>
    </>
  );

  useEffect(() => {
    if (loginService.isLogged()) {
      const user = loginService.currentUser();
      if (user.shouldSignPrivacyTerm) {
        navigate('/privacyTerm');
      } else {
        navigate('/main');
      }
    }
  });
  const redefinePasswordForm = () => {
    return (
      <>
        <div className="logo-login">
          <LogoLogin></LogoLogin>
        </div>
        <PBemVindo>
          Bem-vindo ao <b>Portal Homecare</b>
        </PBemVindo>
        <PAcessarSuaConta>Acessar sua conta</PAcessarSuaConta>
        <DivPanel1>
          {message !== '' ? (
            <CustomErrorMessage>{message}</CustomErrorMessage>
          ) : (
            <></>
          )}
          <InputTextWidth
            width={'90%'}
            placeholder="E-mail"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            value={email}
          />
          <InputText90Top15
            type={'password'}
            placeholder="Nova Senha"
            onChange={(e) => {
              setNewPassword(e.target.value);
            }}
            value={newPassword}
          />
          <DivPanel3></DivPanel3>
          <br />
          <DivPanel2>
            <PrimaryButton onClick={redefinePassword}>
              Redefinir senha
            </PrimaryButton>
          </DivPanel2>
        </DivPanel1>
      </>
    );
  };

  const verifyEmailForm = () => {
    return (
      <>
        <div className="logo-login">
          <LogoLogin></LogoLogin>
        </div>
        <PBemVindo>
          Bem-vindo ao <b>Portal Homecare</b>
        </PBemVindo>
        <PAcessarSuaConta>Acessar sua conta</PAcessarSuaConta>
        <DivPanel1>
          {message !== '' ? (
            <CustomErrorMessage>{message}</CustomErrorMessage>
          ) : (
            <></>
          )}
          <InputTextWidth
            width={'90%'}
            placeholder="E-mail"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            value={email}
          />
          <PWithFontSize fontSize={'11px'}>
            Você deverá receber um código de confirmação no seu e-mail de
            cadastro.
          </PWithFontSize>
          <InputTextWidth
            width={'90%'}
            placeholder="Código"
            onChange={(e) => {
              setVerificationCode(e.target.value);
            }}
            value={verificationCode}
          />

          <DivPanel3></DivPanel3>
          <br />
          <DivPanel2>
            <PrimaryButton onClick={verifyEmail}>Confirmar Email</PrimaryButton>
          </DivPanel2>
        </DivPanel1>
      </>
    );
  };

  const selectForm = () => {
    if (window.location.pathname.indexOf('/recovery') >= 0) {
      if (shouldRecoveryPassword) {
        return forgotPasswordSubmitForm();
      }
      return forgotPassword();
    }
    if (shouldRedefinePassword) {
      return redefinePasswordForm();
    } else if (shouldVerifyEmail) {
      return verifyEmailForm();
    } else if (userForgotPassword) {
      return forgotPassword();
    }
    return loginForm();
  };

  return (
    <DivDisplay display={'flex'}>
      <LoginImage minWidth={!isTabletOrMobile ? '650px' : '100%'} />
      {isTabletOrMobile ? (
        <DivPanel4 className="teste">
          <DivPanel6>{selectForm()}</DivPanel6>
        </DivPanel4>
      ) : (
        <LoginForm>
          <DivPanel5>
            <DivPanel7>{selectForm()}</DivPanel7>
          </DivPanel5>
        </LoginForm>
      )}
      <SpanRights style={{ fontWeight: 'bold', fontSize: '10px' }}>
        2013 - 2022 Todos os direitos reservados
      </SpanRights>
    </DivDisplay>
  );
};
