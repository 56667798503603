import { useEffect, useState } from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { useMediaQuery } from 'react-responsive';
import Toggle from 'react-toggle';
import styled from 'styled-components';
import { PrimaryTextColor, PrimaryTitleGray } from '../../base/colors';
import { GreenLabel, YellowLabel } from '../../base/labels';
import { Table } from '../../base/table';
import { usePortalStore } from '../../store';
import { useKPIStore } from '../../stores/kpiStore';
import { formatCPF } from '../../utils/utils';
import { PatientComplementIcon } from '../../components/patients/PatientClassificationIcon';

const PatientsListRoot = styled.div`
  max-height: 400px;
  overflow: auto;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;
const PatientList = styled.ul`
  list-style: none;
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
`;
const PatientItemList = styled.li`
  padding: 5px;
  box-sizing: border-box;
`;
const PatientItemHeader = styled.div`
  border-radius: 10px;
  position: relative;
  box-sizing: border-box;
  border: solid 1px #efefef;
  padding: 10px;
`;
const PatientItemHeaderTitle = styled.p`
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-weight: 700;
  color: ${(props) => PrimaryTitleGray};
`;
const PatientItemBody = styled.div`
  box-sizing: border-box;
  margin-top: 0px;
  height: 0px;
  overflow: hidden;
  transition: height 0.2s linear;
`;

export const ListPatientsKPI = () => {
  const currentUser = usePortalStore((state) => state.currentUser);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const [listState, setListState] = useState([]);
  const [showOnlyActive, setShowOnlyActive] = useState([]);
  const patients = useKPIStore((state) => state.patients);
  const fetchPatientsKpi = useKPIStore((state) => state.fetchPatientsKpi);
  const selectedDate = useKPIStore((state) => state.selectedDate);

  useEffect(() => {
    fetchPatientsKpi();
  }, [selectedDate]);
  const toggle = (idx) => {
    const newList = [...listState];
    newList[idx] = !newList[idx];
    toggleActive(idx, false);
    setListState(newList);
  };
  const toggleActive = (idx, value) => {
    const newList = [...showOnlyActive];
    if (value !== undefined) {
      newList[idx] = value;
    } else {
      newList[idx] = !newList[idx];
    }

    setShowOnlyActive(newList);
  };
  const header = [
    'Equipamentos e acessórios',
    'Categoria',
    'Subcategoria',
    'Nº série',
    'Quant.',
    'Status',
    'Data implantação',
    'Data inativação',
    'Dias cobrados',
    'Locação atual',
    'Locação mensal',
  ];
  const fields = [
    'product_description',
    'category',
    'subcategory',
    'manufacturer_ns',
    'total_balance',
    'status',
    'activation_date',
    'deactivation_date',
    'days_billed',
    'monthly_lease',
    'value',
  ];
  if (!currentUser.canSeeFinancialDataOnReport) {
    header.pop();
    header.pop();
    fields.pop();
    fields.pop();
  }
  return (
    <PatientsListRoot>
      <PatientList>
        {patients.map((patient, idx) => (
          <PatientItemList key={idx}>
            <PatientItemHeader>
              <PatientItemHeaderTitle>
                <div style={{ display: 'flex' }}>
                  <div>{`${patient.client_name} - CPF: ${formatCPF(
                    patient.cpf
                  )}`}</div>

                  {currentUser.isWhiteMartins || currentUser.isAdmin ? (
                    <div style={{ display: 'flex', marginLeft: '5px' }}>
                      {patient.additionalInformation?.map((s, idx) => (
                        <PatientComplementIcon
                          statusComplementId={s.id}
                          key={idx}
                        ></PatientComplementIcon>
                      ))}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </PatientItemHeaderTitle>
              {listState[idx] ? (
                <MdKeyboardArrowUp
                  onClick={() => toggle(idx)}
                  style={{
                    cursor: 'pointer',
                    position: 'absolute',
                    right: '3px',
                    top: '3px',
                    color: PrimaryTitleGray,
                    fontSize: '40px',
                  }}
                ></MdKeyboardArrowUp>
              ) : (
                <MdKeyboardArrowDown
                  onClick={() => toggle(idx)}
                  style={{
                    cursor: 'pointer',
                    position: 'absolute',
                    right: '3px',
                    top: '3px',
                    color: PrimaryTitleGray,
                    fontSize: '40px',
                  }}
                ></MdKeyboardArrowDown>
              )}
              <PatientItemBody
                style={{ height: listState[idx] ? '340px' : '0px' }}
              >
                {listState[idx] ? (
                  <div
                    style={
                      isTabletOrMobile
                        ? {}
                        : { position: 'absolute', right: '50px', top: '10px' }
                    }
                  >
                    <span style={{ fontSize: '14px', color: PrimaryTitleGray }}>
                      Mostrar apenas ativos
                    </span>{' '}
                    <Toggle
                      defaultChecked={false}
                      icons={false}
                      onChange={() => toggleActive(idx)}
                    />
                  </div>
                ) : (
                  <></>
                )}
                <div
                  style={{
                    marginTop: '15px',
                    maxHeight: '250px',
                    overflow: 'auto',
                  }}
                >
                  <Table
                    header={header}
                    data={(showOnlyActive[idx]
                      ? patient.equipments.filter(
                          (x) => x.status?.toLowerCase() === 'ativo'
                        )
                      : patient.equipments
                    ).map((p, idx) => {
                      const columns = [];
                      fields.forEach((f) => {
                        if (f === 'status' && p.status) {
                          columns.push(
                            p.status?.toLowerCase() === 'ativo' ? (
                              <GreenLabel
                                style={{
                                  textTransform: 'capitalize',
                                  width: '80px',
                                }}
                              >
                                {p.status}
                              </GreenLabel>
                            ) : (
                              <YellowLabel
                                style={{
                                  textTransform: 'capitalize',
                                  width: '80px',
                                }}
                              >
                                {p.status}
                              </YellowLabel>
                            )
                          );
                        } else if (f === 'value' && p[f]) {
                          columns.push(
                            p[f].toLocaleString('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            })
                          );
                        } else if (f === 'monthly_lease' && p[f]) {
                          columns.push(
                            p[f].toLocaleString('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            })
                          );
                        } else {
                          columns.push(p[f] ? p[f] : '-');
                        }
                      });
                      return columns;
                    })}
                  ></Table>
                </div>
                {currentUser.canSeeFinancialDataOnReport ? (
                  <div
                    style={{
                      width: '100%',
                      height: '80px',
                      display: '-webkit-inline-flex',
                      justifyContent: 'right',
                    }}
                  >
                    <div
                      style={{
                        width: '150px',
                        height: '80px',
                        textAlign: 'center',
                      }}
                    >
                      {!isNaN(
                        patient.equipments
                          .map((x) =>
                            !isNaN(parseFloat(x.monthly_lease))
                              ? parseFloat(x.monthly_lease)
                              : 0
                          )
                          .reduce((total, curr) => total + curr, 0)
                      ) ? (
                        <>
                          <span
                            style={{
                              fontSize: '10px',
                              color: PrimaryTextColor,
                              textTransform: 'uppercase',
                            }}
                          >
                            Total Locação Atual
                          </span>
                          <p
                            style={{
                              margin: '0px',
                              padding: '0px',
                              fontWeight: 700,
                              color: PrimaryTitleGray,
                            }}
                          >
                            {patient.equipments
                              .map((x) =>
                                !isNaN(parseFloat(x.monthly_lease))
                                  ? parseFloat(x.monthly_lease)
                                  : 0
                              )
                              .reduce((total, curr) => total + curr, 0)
                              .toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                          </p>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    {/* <div style={{width:"150px",height:"80px",textAlign:"center"}}>
                                        
                                        {!isNaN(patient.equipments.map(x => !isNaN(parseFloat(x.value)) ? parseFloat(x.value) : 0).reduce((total,curr)=>total+curr,0)) ? (
                                        <>
                                        <span style={{fontSize:"10px", color:PrimaryTextColor,textTransform:"uppercase"}}>Total Locação Mensal</span>
                                        <p style={{margin:"0px",padding:"0px",fontWeight:700,color:PrimaryTitleGray}}>{patient.equipments.map(x => !isNaN(parseFloat(x.value)) ? parseFloat(x.value) : 0).reduce((total,curr)=>total+curr,0).toLocaleString('pt-BR',{style:'currency', currency:'BRL'})}</p>
                                        </>)
                                         : <></>}
                                    </div> */}
                  </div>
                ) : (
                  <></>
                )}
              </PatientItemBody>
            </PatientItemHeader>
          </PatientItemList>
        ))}
      </PatientList>
    </PatientsListRoot>
  );
};
