import { doGet } from './httpHelper';
import { getCategories, getSegments, getSubCategories } from './productService';

export const getProductRelation = async (cpf) => {
  try {
    let url = `/productRelation?`;
    const params = [];
    if (cpf && cpf !== '') {
      params.push('cpf=' + cpf);
    }
    url = url + params.join('&');
    const resp = await doGet(url);
    return resp;
  } catch (e) {
    console.error(e);
    return {
      rows: 0,
      data: [],
      page: 0,
      pagesize: 10,
    };
  }
};

export const getBallast = async (
  cpf,
  page,
  pageSize,
  category,
  subcategory,
  segment,
  product,
  filter
) => {
  let url = `/ballast?`;
  const params = [];
  if (cpf && cpf !== '') {
    params.push('cpf=' + cpf);
  }
  if (category && category !== '') {
    params.push('category=' + category);
  }
  if (subcategory && subcategory !== '') {
    params.push('subcategory=' + subcategory);
  }
  if (segment && segment !== '') {
    params.push('segment=' + segment);
  }
  if (product && product !== '') {
    params.push('product=' + product);
  }
  if (page && page !== '') {
    params.push('page=' + page);
  }
  if (pageSize && pageSize !== '') {
    params.push('pageSize=' + pageSize);
  }
  url = url + params.join('&');
  const resp = await doGet(url);
  return resp;
};

export const getFullBallast = async (
  cpf,
  page = '0',
  pageSize = 10000,
  segment,
  filter
) => {
  const ballast = await getBallast(
    cpf,
    page,
    pageSize,
    null,
    null,
    segment,
    null,
    filter
  );
  if (ballast.data.length === 0) {
    return ballast;
  }
  const promises = await Promise.all([
    getSegments('0', 1000),
    getCategories(null, '0', 1000),
    getSubCategories(null, '0', 1000),
  ]);
  const segments = promises[0];
  const categories = promises[1];
  const subcategories = promises[2];
  ballast.data.forEach((ballast) => {
    ballast.products = ballast.products.map((p) => {
      const product = { ...p };
      product.segment = segments.data.filter(
        (x) => x.id === product.product_segment_id
      )[0];
      product.category = categories.data.filter(
        (x) => x.id === product.product_category_id
      )[0];
      product.subcategory = subcategories.data.filter(
        (x) => x.id === product.product_subcategory_id
      )[0];
      return product;
    });
  });
  return ballast;
};

export const getBallastPerCategory = async () => {
  return await doGet('/ballastPerCategory?page=0&pageSize=1000');
};
