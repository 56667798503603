import React, { useEffect, useState } from 'react';
import { TriageBox, BoldText, ItalicText, SubTitle } from './style';
import { TextInput } from '../common/TextInput';
import { Button, SvgIcon, IconButton } from '@mui/material';
import { Box } from '@mui/material';
import { NewTriageData, Triage, TriageMessage } from '../../types/Triage';
import moment from 'moment';
import { TriageDataSource } from '../../datasources/TriageDataSource';
import { CircularProgress } from '@mui/material';
import { SyncOutlined } from '@mui/icons-material';
import { UserHelper } from '../../helpers/UserHelper';

type TriageProps = {
  orderId?: number;
  open?: boolean;
  inputField?: boolean;
  autoUpdate?: boolean;
  height?: number;
  updateInterval?: number;
  showTitle?: boolean;
};
type TriageFieldProps = {
  messages: Triage | undefined;
};

type InputFieldProps = {
  orderId: number;
  onSave: () => void;
};

type MessageLineProps = {
  message: TriageMessage;
  setRef: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>;
};

type UpdaterProps = {
  lastUpdate: moment.Moment | undefined;
  showButton?: boolean;
  clickHandler: () => void;
};

const InputField: React.FC<InputFieldProps> = ({ orderId, onSave }) => {
  const [text, setText] = useState<string>('');

  const handleChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  const onClickButton = async () => {
    const dateTime: string = moment().toISOString();
    const currentUser = UserHelper.getCurrentUser();

    const data: NewTriageData = {
      operator_cognito_id: currentUser?.username,
      type_id: new TriageDataSource().types.escreveu,
      message: text,
      order_id: orderId,
      created_at: dateTime,
    };
    await new TriageDataSource().create(data);
    setText('');
    onSave();
  };

  return (
    <Box sx={{ display: 'flex', gap: '10px' }}>
      <TextInput
        label="Escreva sua mensagem..."
        handleChange={handleChangeText}
        sx={{ width: '100%' }}
        value={text}
      ></TextInput>
      <Button
        variant="contained"
        onClick={onClickButton}
        sx={{ borderRadius: '10px' }}
      >
        Enviar
      </Button>
    </Box>
  );
};

const MessageLine: React.FC<MessageLineProps> = ({ message, setRef }) => {
  const timeInformation = `${moment(message.created_at).format(
    'DD/MM'
  )} às ${moment(message.created_at).format('HH:mm')}`;

  const currentRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => setRef(currentRef.current), [currentRef]);

  return (
    <div style={{ margin: '0px 0px 10px 0px' }} ref={currentRef}>
      {timeInformation}
      <span>{' - '}</span>
      <BoldText>
        {message.operator_name ? message.operator_name : 'Sistema'}
      </BoldText>
      <span>{' - '}</span>
      <ItalicText>{message.type}</ItalicText>
      <span>{': '}</span>
      {message.message}
    </div>
  );
};

const MessageField: React.FC<TriageFieldProps> = ({ messages }) => {
  const [lastMessage, setLastMessage] = React.useState<HTMLDivElement | null>(
    null
  );

  const container = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    const box = container.current;
    box!.scroll({
      behavior: 'smooth',
      top: box!.scrollHeight - box!.clientHeight,
    });
  }, [container, lastMessage]);

  return (
    <div
      style={{
        overflowY: 'auto',
        overflowX: 'hidden',
        height: 'auto',
        margin: '0px 0px 10px 0px ',
      }}
      ref={container}
    >
      {messages ? (
        messages.map((message, idx) => (
          <MessageLine
            message={message}
            key={idx}
            setRef={setLastMessage}
          ></MessageLine>
        ))
      ) : (
        <></>
      )}
    </div>
  );
};

const Updater: React.FC<UpdaterProps> = ({
  lastUpdate,
  showButton = false,
  clickHandler,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        margin: 'auto 5px auto auto',
        width: 'fit-content',
      }}
    >
      {lastUpdate ? (
        <div
          style={{
            height: 'fit-content',
            margin: 'auto 5px',
          }}
        >
          {`Última atualização ${lastUpdate?.format(
            'DD/MM'
          )} às ${lastUpdate?.format('HH:mm:ss')}`}
        </div>
      ) : (
        <></>
      )}
      {showButton ? (
        <IconButton onClick={clickHandler} sx={{ margin: 'auto 5px' }}>
          <SvgIcon>
            <SyncOutlined color="primary"></SyncOutlined>
          </SvgIcon>
        </IconButton>
      ) : (
        <></>
      )}
    </div>
  );
};

export const TriageField: React.FC<TriageProps> = ({
  orderId,
  inputField = false,
  height = 285,
  showTitle = false,
  open = true,
}) => {
  const [messages, setMessages] = useState<Triage | undefined>();
  const [lastUpdate, setLastUpdate] = useState<moment.Moment | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const updateMessages = async () => {
    if (orderId !== undefined) {
      setIsLoading(true);
      const result = await new TriageDataSource().getByOrderId(orderId);
      setMessages(result);
      setLastUpdate(moment());
      const timeout = setTimeout(() => setIsLoading(false), 1000);
      return () => clearTimeout(timeout);
    }
  };

  useEffect(() => {
    if (open) updateMessages().catch(console.error);
  }, [open]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        {showTitle ? (
          <SubTitle
            variant="h5"
            sx={{
              display: 'box',
              height: 'fit-content',
              margin: ' auto 5px',
            }}
          >
            Triagem
          </SubTitle>
        ) : (
          <></>
        )}
        <Updater
          clickHandler={updateMessages}
          lastUpdate={lastUpdate}
          showButton={true}
        ></Updater>
        <div
          style={{
            width: '30px',
            height: '30px',
            margin: 'auto 5px',
          }}
        >
          {isLoading ? <CircularProgress size={25}></CircularProgress> : <></>}
        </div>
      </div>
      <TriageBox height={height} onOpen={updateMessages}>
        <MessageField messages={messages ? messages : undefined}></MessageField>
        {inputField ? (
          <InputField orderId={orderId!} onSave={updateMessages}></InputField>
        ) : (
          <></>
        )}
      </TriageBox>
    </>
  );
};
