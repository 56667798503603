import create from 'zustand';
import {
  getActivatePatients,
  getAllocatedEquipmentsChart,
  getMonthlyOxigenConsumed,
  getRecentOrders,
} from '../services/mainPageService';
import { formatDate, getLast12Months } from '../utils/utils';
const dd = getLast12Months(new Date(), 12);
const defaultInitialDate = new Date(
  dd[dd.length - 1].year,
  dd[dd.length - 1].month + 1,
  1
);
const defaultEndDate = new Date(dd[0].year, dd[0].month + 1, 0);
const months = [
  'Jan',
  'Fev',
  'Mar',
  'Abr',
  'Mai',
  'Jun',
  'Jul',
  'Ago',
  'Set',
  'Out',
  'Nov',
  'Dez',
];
export const useMainStore = create((set, get) => ({
  oxygenConsumption: '',
  allocatedEquipments: '',
  activePatients: '',
  consumedOxygenYearsToFilter: [defaultInitialDate, defaultEndDate],
  consumedOxygenXLabel: [
    'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez',
  ],
  consumedOxygenData: [],
  consumedOxygenThreshold: 0,
  allocatedEquipmentList: [
    ['Oxímetros'],
    ['Linha', 'do sono'],
    ['Ventilação', 'domiciliar'],
    ['Oxigenoterapia'],
    ['Aspiradores', 'de secreção'],
    ['Dispositivos', 'e acessórios'],
  ],
  allocatedEquipmentData: {},
  recentOrders: [],
  setOxygenConsumption: (value) => set({ oxygenConsumption: value }),
  setAllocatedEquipments: (value) => set({ oxygenConsumption: value }),
  setactivePatients: (value) => set({ oxygenConsumption: value }),
  setConsumedOxygenFilter: (value) => {
    set({ consumedOxygenYearsToFilter: [...value] });
    get().fetchMonthlyOxigenConsumed();
  },
  fetchActivePatients: async (cnpj) => {
    const value = await getActivatePatients(cnpj);
    set({ activePatients: value });
  },
  fetchMonthlyOxigenConsumed: async (cnpj) => {
    const value = await getMonthlyOxigenConsumed(
      cnpj,
      formatDate(get().consumedOxygenYearsToFilter[0]),
      formatDate(get().consumedOxygenYearsToFilter[1])
    );
    const sum = value.data.reduce((acc, i) => acc + i, 0);

    const end = new Date(get().consumedOxygenYearsToFilter[1].getTime());
    let temp = new Date(get().consumedOxygenYearsToFilter[0].getTime());
    const dateMap = {};
    while (temp.getTime() < end.getTime()) {
      const tmpMonth = temp.getMonth();
      dateMap[`${months[tmpMonth]}/${temp.getFullYear()}`] = 0;
      temp.setMonth(tmpMonth + 1);
    }
    Object.values(value.months).forEach((key, idx) => {
      dateMap[key] = value.data[idx] ?? 0;
    });
    value.months = Object.keys(dateMap);
    value.data = Object.values(dateMap);
    const mean = sum / value.months.length;
    set({
      oxygenConsumption: parseFloat(sum.toFixed(2)),
      consumedOxygenThreshold: mean,
      consumedOxygenData: value.data,
      consumedOxygenXLabel: value.months,
    });
  },
  fetchAllocatedEquipmentsChart: async (cnpj) => {
    const value = await getAllocatedEquipmentsChart(cnpj, null, null);
    const sum = Object.values(value).reduce((acc, i) => acc + i, 0);
    set({ allocatedEquipmentData: { ...value }, allocatedEquipments: sum });
  },
  fetchRecentOrders: async (requestType) => {
    const value = await getRecentOrders(requestType);
    set({ recentOrders: value });
  },
}));
