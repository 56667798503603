import { PublicLayout } from '../components/layout/publicLayout';
import { Panel } from '../components/panels/panel';
import { PrimaryButton } from '../base/Inputs';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signPrivacyTerm } from '../services/userMgntService';
import { LoginService } from '../services/auth';

export const PrivacyPage = () => {
  const [accepted, setAccepted] = useState(false);
  let navigate = useNavigate();
  const signPrivacyTermCallback = async () => {
    const result = await signPrivacyTerm();
    if (result.signed === true) {
      LoginService.setPrivacyTermAccepted();
      navigate('/main');
    }
  };
  return (
    <PublicLayout
      outlet={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center',
          }}
        >
          <Panel style={{ padding: '20px', width: '800px', textAlign: 'left' }}>
            <div>
              <h2>Termo de Privacidade</h2>
              <p>
                Este termo de privacidade descreve como as informações pessoais
                são coletadas, usadas e compartilhadas quando você utiliza
                nossos serviços.
              </p>

              <h3>Coleta de Informações Pessoais:</h3>
              <p>
                Coletamos informações pessoais, como nome, endereço de e-mail e
                informações de pagamento quando você se registra em nossos
                serviços ou faz uma compra. Também podemos coletar informações
                sobre seu uso dos nossos serviços, como seu histórico de
                navegação e as páginas que você visitou.
              </p>

              <h3>Uso de Informações Pessoais:</h3>
              <p>
                Usamos as informações coletadas para fornecer e melhorar nossos
                serviços, personalizar sua experiência e enviar informações
                importantes, como atualizações e promoções. Podemos compartilhar
                informações com terceiros, como provedores de serviços e
                parceiros de publicidade, para fornecer serviços e anúncios
                relevantes.
              </p>

              <h3>Proteção de Informações Pessoais:</h3>
              <p>
                Tomamos medidas de segurança adequadas para proteger suas
                informações pessoais contra perda, uso indevido e acesso não
                autorizado. Somente fornecemos acesso às informações pessoais a
                funcionários que precisam delas para realizar suas funções.
              </p>

              <h3>Seus Direitos de Privacidade:</h3>
              <p>
                Você tem o direito de acessar, corrigir e excluir suas
                informações pessoais. Também podemos permitir que você opte por
                sair de determinados tipos de comunicações de marketing. Entre
                em contato conosco para obter mais informações.
              </p>

              <h3>Atualizações deste Termo de Privacidade:</h3>
              <p>
                Podemos atualizar este termo de privacidade periodicamente para
                refletir mudanças em nossas práticas de privacidade.
                Recomendamos que você revise este termo regularmente para se
                manter informado sobre nossas práticas de privacidade mais
                recentes.
              </p>
            </div>
            <div style={{ padding: '10px', backgroundColor: '#f7f7f7' }}>
              <input
                checked={accepted}
                onChange={(e) => setAccepted(!accepted)}
                type="checkbox"
              ></input>
              <label>
                Aceito os termos e condições de uso do Portal Homecare
              </label>
            </div>
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <PrimaryButton
                disabled={!accepted}
                onClick={signPrivacyTermCallback}
                style={{ width: '120px' }}
              >
                Assinar
              </PrimaryButton>
            </div>
          </Panel>
        </div>
      }
    ></PublicLayout>
  );
};
