import { colors } from '../../../themes/core/colors';
import { Tooltip } from '../../common/Tooltip';
import { SvgIcon } from '@mui/material';
import {
  SentimentDissatisfiedOutlined,
  NoCellOutlined,
  ImportExportOutlined,
  HandymanOutlined,
  DangerousOutlined,
} from '@mui/icons-material';
import React, { ReactElement } from 'react';
import { PatientDataSource } from '../../../datasources/PatientDataSource';

const statusComplementsEnum = {
  HARSH_BEHAVIOR: 1,
  DO_NOT_CALL_OUTSIDE_BUSINESS_HOURS: 2,
  DANGEROUS_AREA: 3,
  HIGH_OXYGEN_CONSUMPTION: 4,
  HIGH_TECHNICAL_ASSISTANCE_DEMAND: 5,
};

type PatientComplementIconProps = {
  statusComplementId: number | string;
  onPaymentList: boolean;
};

export const PatientComplementIcon: React.FC<PatientComplementIconProps> = ({
  statusComplementId,
  onPaymentList = true,
}) => {
  const [text, setText] = React.useState<string | undefined>();
  const [Icon, setIcon] = React.useState<ReactElement | undefined>();

  React.useEffect(() => {
    let element: ReactElement | undefined;

    const updateIcon = async () => {
      const classifications =
        await new PatientDataSource().getAdditionalInformation();

      switch (
        typeof statusComplementId === 'string'
          ? parseInt(statusComplementId)
          : statusComplementId
      ) {
        case statusComplementsEnum.DANGEROUS_AREA:
          element = <DangerousOutlined></DangerousOutlined>;
          break;
        case statusComplementsEnum.DO_NOT_CALL_OUTSIDE_BUSINESS_HOURS:
          element = <NoCellOutlined></NoCellOutlined>;
          break;
        case statusComplementsEnum.HARSH_BEHAVIOR:
          element = (
            <SentimentDissatisfiedOutlined></SentimentDissatisfiedOutlined>
          );
          break;
        case statusComplementsEnum.HIGH_OXYGEN_CONSUMPTION:
          element = <ImportExportOutlined></ImportExportOutlined>;
          break;
        case statusComplementsEnum.HIGH_TECHNICAL_ASSISTANCE_DEMAND:
          element = <HandymanOutlined></HandymanOutlined>;
          break;
        default:
          element = <></>;
      }
      const t = classifications
        .filter((c) => {
          return c.value === statusComplementId.toString();
        })
        .map((cc) => {
          return cc.label;
        })[0];
      setText(t);
      setIcon(element);
    };
    updateIcon().catch(console.error);
  }, [statusComplementId]);

  return (
    <Tooltip title={text ? text : ''}>
      <SvgIcon
        fontSize="small"
        htmlColor={onPaymentList ? colors.text.primary : colors.text.tertiary}
      >
        {Icon}
      </SvgIcon>
    </Tooltip>
  );
};
