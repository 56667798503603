import moment from 'moment';

export class CacheService {
  static set(
    key: string,
    value: object,
    expirationDate?: string,
    local?: boolean
  ) {
    const storageKey = `portalhc-${key}`;
    const storage = local ? localStorage : sessionStorage;
    const defaultExpirationDate = moment()
      .utc(true)
      .add(30, 'minutes')
      .toISOString(); // 30 minutes

    const currentStorageData = storage.getItem(storageKey);

    if (currentStorageData) {
      const currentJsonData = JSON.parse(currentStorageData);
      const expirationDate = moment(currentJsonData.expirationDate).utc();
      const now = moment().utc(true);

      if (now.isBefore(expirationDate)) {
        return;
      }
    }

    const newStorageValue = JSON.stringify({
      value: value,
      expirationDate: expirationDate ?? defaultExpirationDate,
    });

    console.log('Caching data for:', storageKey);
    storage.setItem(storageKey, newStorageValue);
  }

  static get(key: string, local?: boolean) {
    const storageKey = `portalhc-${key}`;
    const storage = local ? localStorage : sessionStorage;
    const stringStorageData = storage.getItem(storageKey);

    if (stringStorageData) {
      console.log('Retrieving data from cache:', storageKey);
      return JSON.parse(stringStorageData)?.value;
    }
  }
}
