import { doGet } from './httpHelper';

export const getSales = async (cpf, page, pageSize, startDate, endDate) => {
  let url = `/sales?`;
  const params = [];
  if (cpf && cpf !== '') {
    params.push('cpf=' + cpf);
  }
  if (startDate && startDate !== '') {
    params.push('startDate=' + startDate);
  }
  if (endDate && endDate !== '') {
    params.push('endDate=' + endDate);
  }
  if (page && page !== '') {
    params.push('page=' + page);
  }
  if (pageSize && pageSize !== '') {
    params.push('pageSize=' + pageSize);
  }
  url = url + params.join('&');
  const resp = await doGet(url);
  return resp;
};

export const getLastSales = async (cpf, page, pageSize, startDate, endDate) => {
  let url = `/lastSales?`;
  const params = [];
  if (cpf && cpf !== '') {
    params.push('cpf=' + cpf);
  }
  if (startDate && startDate !== '') {
    params.push('startDate=' + startDate);
  }
  if (endDate && endDate !== '') {
    params.push('endDate=' + endDate);
  }
  if (page && page !== '') {
    params.push('page=' + page);
  }
  if (pageSize && pageSize !== '') {
    params.push('pageSize=' + pageSize);
  }
  url = url + params.join('&');
  const resp = await doGet(url);
  return resp;
};
