import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from '../../base/Inputs';
import { WideLayout } from '../../components/layout/wideLayout';
import { usePortalStore } from '../../store';
import { usePatientsStore } from '../../stores/patientsStore';
import { useTechnicalAssistanceStore } from '../../stores/technicalAssistanceStore';
import { PatientsList } from '../../usecase/patients/list';
import { unformatCPF } from '../../utils/utils';

const MainPanels = () => {
  const setSelectedPatient = useTechnicalAssistanceStore(
    (state) => state.setSelectedPatient
  );
  const selectedPatient = useTechnicalAssistanceStore(
    (state) => state.selectedPatient
  );
  const reset = useTechnicalAssistanceStore((state) => state.reset);
  const patients = usePatientsStore((state) => state.patients);
  const header = ['Nome', 'CPF', 'Empresa', 'CNPJ', 'Endereço'];
  const fields = ['name', 'cpf', 'company', 'cnpj', 'address'];
  const navigate = useNavigate();

  useEffect(() => {
    reset();
  }, []);

  const onRowSelected = (patient) => {
    const p = patients.filter(
      (x) => unformatCPF(x.cpf) === unformatCPF(patient[1])
    )[0];
    setSelectedPatient(p);
  };
  const continueToCategory = () => {
    if (selectedPatient !== null) {
      navigate('/technicalAssistance/assistance');
    }
  };
  return (
    <>
      <PatientsList
        newClientAction={
          <div className="titulo" style={{ justifyContent: 'end' }}>
            <PrimaryButton
              disabled={selectedPatient === null}
              onClick={continueToCategory}
            >
              Prosseguir
            </PrimaryButton>
          </div>
        }
        onlyActives={true}
        height={`${window.innerHeight - 450}px`}
        subtitle={''}
        readOnly={true}
        header={header}
        fields={fields}
        onRowSelected={onRowSelected}
      ></PatientsList>
    </>
  );
};

export const TechnicalAssistanceSelectPatientsPage = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const currentUser = usePortalStore((state) => state.currentUser);

  return (
    <WideLayout
      outlet={isTabletOrMobile ? MainPanels() : MainPanels()}
    ></WideLayout>
  );
};
