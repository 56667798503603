import { AiOutlineShoppingCart } from 'react-icons/ai';
import { BsGrid1X2 } from 'react-icons/bs';
import { MdOutlinePersonalInjury } from 'react-icons/md';
import { TbNewSection, TbReportAnalytics } from 'react-icons/tb';
import { VscSettings } from 'react-icons/vsc';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
  BackgroundColor,
  BrighterGreen,
  ShadowColor,
  TextColor,
} from '../../base/colors';
import { usePortalStore } from '../../store';

const MenuTile = styled.div`
  background-color: white;
  color: ${TextColor};
  bottom: 0px;
  top: 0px;
  transition: width 0.25s;
  box-shadow: 5px 0px 5px ${ShadowColor};
  overflow: hidden;
  ${(props) =>
    true
      ? `
float: left;
position: absolute;
z-index: 3;
`
      : ``}
`;

const MenuItemTile = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 15px;
  cursor: pointer;
  border-left: 5px solid white;
  &:hover {
    border-left: 5px solid ${BrighterGreen};
    color: ${BrighterGreen};
    font-weight: bold;
    background-color: ${BackgroundColor};
  }
`;

const IconTile = styled.div`
  margin-right: 10px;
  font-size: 20px;
`;

export const Menu = () => {
  const menuOpenState = usePortalStore((state) => state.ui.menuOpenState);
  const setMenuOpenState = usePortalStore((state) => state.setMenuOpenState);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const currentUser = usePortalStore((state) => state.currentUser);
  const navigate = useNavigate();
  const goToPage = (url) => {
    if (isTabletOrMobile) {
      setMenuOpenState(false);
    }
    navigate(url);
  };
  return (
    <MenuTile
      style={{ width: menuOpenState ? '200px' : '0px' }}
      isMobile={isTabletOrMobile}
    >
      <div className="menu-panel">
        <div style={{ padding: '15px 0', borderBottom: 'solid 1px #efefef' }}>
          <MenuItemTile
            className="new-order"
            style={{ display: 'flex', alignItems: 'center' }}
            onClick={() => goToPage('/order/newOrder')}
          >
            <IconTile>
              <TbNewSection></TbNewSection>
            </IconTile>
            <div>Novo pedido</div>
          </MenuItemTile>
        </div>

        <div>
          <MenuItemTile
            style={{ display: 'flex', alignItems: 'center' }}
            onClick={() => goToPage('/main')}
          >
            <IconTile>
              <BsGrid1X2></BsGrid1X2>
            </IconTile>
            <div>Painel</div>
          </MenuItemTile>
          <MenuItemTile
            style={{ display: 'flex', alignItems: 'center' }}
            onClick={() => goToPage('/orders')}
          >
            <IconTile>
              <AiOutlineShoppingCart></AiOutlineShoppingCart>
            </IconTile>
            <div>Pedidos</div>
          </MenuItemTile>

          <MenuItemTile
            style={{ display: 'flex', alignItems: 'center' }}
            onClick={() => goToPage('/patients')}
          >
            <IconTile>
              <MdOutlinePersonalInjury></MdOutlinePersonalInjury>
            </IconTile>
            <div>Pacientes</div>
          </MenuItemTile>
          <MenuItemTile
            style={{ display: 'flex', alignItems: 'center' }}
            onClick={() => goToPage('/report')}
          >
            <IconTile>
              <TbReportAnalytics></TbReportAnalytics>
            </IconTile>
            <div>Relatórios</div>
          </MenuItemTile>
          {currentUser.isAdmin ? (
            <MenuItemTile
              style={{ display: 'flex', alignItems: 'center' }}
              onClick={() => goToPage('/configuration')}
            >
              <IconTile>
                <VscSettings></VscSettings>
              </IconTile>
              <div>Configurações</div>
            </MenuItemTile>
          ) : (
            <></>
          )}
        </div>
        <div></div>
      </div>
    </MenuTile>
  );
};
