import styled from 'styled-components';
import { PrimaryBackground } from './colors';

export const LoginImage = styled.div`
  background-image: url(/loginImage.jpg);
  flex: 1;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  min-width: ${(props) => props.minWidth};
  @media screen and (max-width: 600px) {
    background-image: none;
    background: #fdfdfd;
  }
`;

export const LoginForm = styled.div`
  background-color: ${PrimaryBackground};
  flex: 2;
  height: 100vh;
  display: flex;
  position: relative;
`;

export const LoginFormCenter = styled.div`
  background-color: red;
  height: 45vh;
  width: 50%;
  display: flex;
`;
