import { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { WideLayout } from '../../components/layout/wideLayout';
import { usePortalStore } from '../../store';
import { useOrderFlowStore } from '../../stores/newOrderFlowStore';
import { TilesSelection } from './tilesSelection';
/**
 *
 * @returns
 */
export const OrderSelectCategory = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const currentUser = usePortalStore((state) => state.currentUser);
  const setSelectedCategory = useOrderFlowStore(
    (state) => state.setSelectedCategory
  );
  const availableCategories = useOrderFlowStore(
    (state) => state.availableCategories
  );
  const selectedArea = useOrderFlowStore((state) => state.selectedArea);
  const navigate = useNavigate();

  const onClickCategory = async (category) => {
    await setSelectedCategory(currentUser.cnpj, { ...category });
    navigate(
      `/order/selectSubCategory?category=${category['title']}&categoryId=${category['id']}`
    );
  };

  const mergeCategories = () => {
    const result = [];
    availableCategories.forEach((c) => {
      if (c === null) {
        return;
      }
      const match = selectedArea.categories.filter(
        (x) => x.id === c.description
      )[0];
      if (match) {
        c.coverImageURL = match.coverImageURL;
        //TODO fixme
        c.shortDescription = c.shortDescription ?? match.shortDescription;
        c.title = match.title;
        result.push(c);
      }
    });
    return result;
  };

  return (
    <WideLayout
      outlet={
        <TilesSelection
          onClickTile={onClickCategory}
          tiles={mergeCategories()}
        ></TilesSelection>
      }
    ></WideLayout>
  );
};
