import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../redux/store';
import { setSnackbar } from '../redux/reducers/snackbar';
import { AlertColor } from '@mui/material';

export const useSnackbar = () => {
  const snackbarState = useSelector((state: RootState) => state.snackbar);
  const dispatch: AppDispatch = useDispatch();

  const show = (message: string, type: AlertColor) => {
    dispatch(setSnackbar({ open: true, message, type }));
  };

  const hide = () => {
    dispatch(setSnackbar({ ...snackbarState, open: false }));
  };

  return {
    show,
    hide,
  };
};
