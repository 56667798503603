import { Api } from '../api';
import { PatientStatusComplement } from '../types/Patient';
import { MultipleSelectItems } from '../components/common/MultipleSelect';
import { CacheService } from '../cache/CacheService';
import moment from 'moment';

export class PatientDataSource {
  /** This method is used to cache the data in the local storage for 15 days \
  The data is stored in the local storage because it is not updated frequently */
  private cache(key: string, value: object) {
    const period = moment().utc(true).add(15, 'days').toISOString();
    CacheService.set(key, value, period, true);
  }

  async getAdditionalInformation() {
    const cachedData = CacheService.get(
      'customer-additional-information',
      true
    );

    if (cachedData) {
      return cachedData as MultipleSelectItems[];
    }

    const apiResponse = (await new Api().fetch(
      '/customer/additional-information'
    )) as Array<PatientStatusComplement>;

    const response = apiResponse.map((status) => ({
      label: status.name,
      value: status.id.toString(),
    })) as MultipleSelectItems[];

    this.cache('customer-additional-information', response);

    return response;
  }

  async getCustomerAdditionalInformation(cpf: string, cnpj: string) {
    let newCpf = null;
    let newCnpj = null;

    if (cpf && cnpj) {
      newCpf = cpf.replaceAll('.', '').replaceAll('/', '').replaceAll('-', '');
      newCnpj = cnpj
        .replaceAll('.', '')
        .replaceAll('/', '')
        .replaceAll('-', '');

      const result = (await new Api().fetch(
        `/customer/additional-information/cpf/${newCpf}/cnpj/${newCnpj}`
      )) as Array<{ id: number; name: string }>;
      return result;
    }
  }
}
