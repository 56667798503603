export const ErrorNotReceivedMessageWrongLogin =
  'Erro interno contacte o administrador do sistema';
export const UserShouldRedefineHisPword = 'Usuário precisa redefinir sua senha';
export const UserShouldConfirmHisEmail = 'Usuário precisa confirmar seu e-mail';
export const UserNotExist = 'Usuário não cadastrado';
export const UsernameOrPwordInvalid = 'Usuário ou senha inválidos!';
export const TemporaryPwordExpired =
  'Senha temporária expirada e precisa ser resetada pelo administrador.';
export const UsernameCannotBeEmpty =
  'Nome de Usuário não pode estar em branco.';
export const UPassCannotBeEmpty = 'Senha de Usuário não pode estar em branco.';
export const ConfirmationCodeCannotBeEmpty =
  'Código de confirmação não pode estar em branco.';
export const CaptchaFailed = (
  <div>
    <span>
      Falha na verificação do captcha,{' '}
      <span
        style={{ cursor: 'pointer', fontWeight: 'bolder' }}
        onClick={() => window.location.reload()}
      >
        recarregue
      </span>{' '}
      a página para tentar novamente
    </span>
  </div>
);
export const PwordPolicy = (
  <div>
    <p>Sua senha deve conter</p>
    <ul>
      <li>Mínimo de 8 Caracteres</li>
      <li>Números</li>
      <li>Caracteres Especiais</li>
      <li>Letras Maiúsculas</li>
      <li>Letras Minúsculas</li>
    </ul>
  </div>
);
