import styled from 'styled-components';

export const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 4;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
`;
