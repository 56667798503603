import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const SapNumberInputContainer = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

export const TextFieldScrollableWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '170px',
  overflowY: 'scroll',
  padding: '10px 0px',
}));

export const TextFieldAndDeleteWrapper = styled(Box)(() => ({
  display: 'flex',

  '&:not(:last-child)': {
    marginBottom: '15px',
  },
}));

export const AddIconWrapper = styled(Box)(({ theme }) => ({
  svg: {
    color: theme.colors.main,
  },
}));
