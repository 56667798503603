import { useEffect, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { PrimaryTextColor, PrimaryTitleGray } from '../../base/colors';
import { TextAreaInput } from '../../base/Inputs';
import { ImageSlider } from '../../components/image/slider';
import { getProductsImages } from '../../services/productService';
import { saveRechargerOrder } from '../../services/rechargeService';
import { usePortalStore } from '../../store';
import { useRechargeStore } from '../../stores/rechargeStore';
import { DefaultFinishOrder } from '../../usecase/orders/defaultFinishOrder';
import { getBase64Img } from '../../utils/utils';
import { GiHandTruck } from 'react-icons/gi';
import { ExchangeIcon } from '../../components/icons/ExchangeIcon';

export const FinishRechargerOrder = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const currentUser = usePortalStore((state) => state.currentUser);
  const setCurrentAlertError = usePortalStore(
    (state) => state.setCurrentAlertError
  );

  const selectedPatient = useRechargeStore((state) => state.selectedPatient);
  const rechargerActions = useRechargeStore((state) => state.rechargerActions);
  const selectedPatientIndex = useRechargeStore(
    (state) => state.selectedPatientIndex
  );
  const navigate = useNavigate();
  const operations = rechargerActions[selectedPatientIndex];
  const [techDetails, setTechDetails] = useState('');
  const [image, setImage] = useState([]);
  const finishOrder = async (deliveryDate, deliveryTurn) => {
    const orderNumber = await saveRechargerOrder(
      currentUser,
      selectedPatient.cnpj,
      selectedPatient,
      operations,
      deliveryDate,
      techDetails,
      deliveryTurn
    );
    if (orderNumber === null) {
      setCurrentAlertError(
        `Nenhuma filial encontrada para a cidade de ${selectedPatient.city}`
      );
    }
    return orderNumber;
  };

  useEffect(() => {
    (async () => {
      const img = await getProductsImages(
        Object.values(operations).map((x) => x.cylinder.id)
      );
      setImage([...img]);
    })();
  }, []);

  const topTile = () => {
    return (
      <div
        style={{
          display: isTabletOrMobile ? 'block' : 'inline-flex',
          width: '100%',
        }}
      >
        <div style={{ flex: '1' }}>
          <ImageSlider images={image.map(getBase64Img)}></ImageSlider>
        </div>
        <div
          style={{
            flex: '2',
            marginLeft: isTabletOrMobile ? '0px' : '40px',
            color: PrimaryTextColor,
          }}
        >
          {Object.values(operations).filter(
            (x) => x.action === 'troca' && x.newCylinder !== undefined
          ).length > 0 ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                borderBottom: 'solid 1px #ccc',
                marginBottom: '5px',
                marginTop: '10px',
              }}
            >
              <ExchangeIcon height="30px"></ExchangeIcon>
              <div
                style={{
                  fontSize: '16px',
                  color: '#000',
                  margin: 'auto 0px auto 9px',
                }}
              >
                Cilindros que serão trocados
              </div>
            </div>
          ) : (
            <></>
          )}
          <ul style={{ margin: '0px', padding: '0px' }}>
            {Object.values(operations)
              .filter(
                (x) => x.action === 'troca' && x.newCylinder !== undefined
              )
              .map((x, idx) => (
                <li
                  style={{
                    listStyle: 'none',
                    padding: '6px 15px',
                    background: '#efefef',
                    borderRadius: '10px',
                    marginBottom: '5px',
                    color: '#000',
                  }}
                  key={idx}
                >
                  <span>{x.newCylinder.description}</span>
                </li>
              ))}
          </ul>
          {Object.values(operations).filter(
            (x) => x.action === 'recarga' && x.quantity > 0
          ).length > 0 ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                borderBottom: 'solid 1px #ccc',
                marginBottom: '5px',
                marginTop: '10px',
              }}
            >
              <GiHandTruck
                style={{
                  fontSize: '30px',
                  marginRight: '9px',
                  color: '#318300',
                }}
              ></GiHandTruck>
              <div style={{ fontSize: '16px', color: '#000' }}>
                Cilindros que serão recarregados
              </div>
            </div>
          ) : (
            <></>
          )}
          <ul style={{ margin: '0px', padding: '0px' }}>
            {Object.values(operations)
              .filter((x) => x.action === 'recarga' && x.quantity > 0)
              .map((x, idx) => (
                <li
                  style={{
                    listStyle: 'none',
                    padding: '6px 15px',
                    background: '#efefef',
                    borderRadius: '10px',
                    marginBottom: '5px',
                    color: '#000',
                  }}
                  key={idx}
                >
                  <span>{x.cylinder.product}</span>
                  <span
                    style={{ float: 'right' }}
                  >{`Quantidade: ${x.quantity}`}</span>
                </li>
              ))}
          </ul>
        </div>
      </div>
    );
  };
  const bottomTile = () => {
    return (
      <div style={{ width: '100%' }}>
        <h4
          style={{
            color: PrimaryTitleGray,
            margin: '0px',
            marginBottom: '10px',
          }}
        >
          Informações adicionais
        </h4>
        <TextAreaInput
          onChange={(e) => setTechDetails(e.target.value)}
          style={{ minWidth: '100%', minHeight: '120px', margin: '0px' }}
        ></TextAreaInput>
      </div>
    );
  };
  return (
    <DefaultFinishOrder
      selectedPatient={selectedPatient}
      topTileRender={topTile}
      bottomTileRender={bottomTile}
      orderType={'Recarga'}
      onFinish={finishOrder}
      fastDeliveryLabel={'Recarga imediata'}
      scheduleDeliveryLabel={'Recarga agendada'}
    ></DefaultFinishOrder>
  );
};
