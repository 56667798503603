import { DarkGreen } from '../../base/colors';
import { StatePanel } from '../../components/dashboards/statePanel';
import { useMainStore } from '../../stores/mainStore';

export const AllocatedEquipments = () => {
  const allocatedEquipments = useMainStore(
    (state) => state.allocatedEquipments
  );
  return (
    <StatePanel
      color={DarkGreen}
      value={allocatedEquipments}
      icon={'/icones/equipment-icon.png'}
      label={['Produtos locados']}
    ></StatePanel>
  );
};
