import create from 'zustand';

export const useTechnicalAssistanceStore = create((set, get) => ({
  selectedPatient: null,
  selectedEquipments: {},
  equipmentList: [],
  selectedSearch: {},
  typedSearch: {},
  setSelectedPatient: (patient) => {
    set({ selectedPatient: { ...patient } });
  },

  setTypedSearch: (search) => {
    set({ typedSearch: { ...search } });
  },

  setSelectedSearch: (selectedSearch) => {
    set({ selectedSearch: JSON.parse(JSON.stringify(selectedSearch)) });
  },

  setSelectedEquipment: (equipment) => {
    set({ selectedEquipments: JSON.parse(JSON.stringify(equipment)) });
  },

  setEquipmentList: (equipmentList) => {
    set({ equipmentList: JSON.parse(JSON.stringify(equipmentList)) });
  },

  reset: () => {
    set({
      selectedPatient: null,
      typedSearch: {},
      equipmentList: [],
      selectedSearch: {},
      selectedEquipments: {},
    });
  },
}));
