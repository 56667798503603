import { useState } from 'react';
import { ReadOnlyInputText } from './Inputs';
import { BsCalendar4Range, BsSearch } from 'react-icons/bs';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { VscCalendar } from 'react-icons/vsc';
import { PrimaryTextColor, TextColor } from './colors';
import styled from 'styled-components';
import { Calendar } from 'react-calendar';
import { formatDate, getLast12Months } from '../utils/utils';
import { useMainStore } from '../stores/mainStore';

const dd = getLast12Months(new Date(), 12);
const defaultRangeBegin = new Date(
  dd[dd.length - 1].year,
  dd[dd.length - 1].month + 1,
  1
);
const defaultRangeEnd = new Date(dd[0].year, dd[0].month + 1, 0);

const CustomSelectDiv = styled.div`
  position: fixed;
  background-color: white;
  box-sizing: border-box;
  border: none;
  box-shadow: rgb(207 207 207) 0px 1px 5px;
  font-size: 14px;
  border-radius: 10px;
  overflow-y: auto;
  margin-top: 5px;
  z-index: 20000;
  color: ${(props) => props.fontColor};
`;
export const DateRange = ({
  minDetail,
  maxDate,
  maxDetail,
  height,
  style,
  onSelect,
  selectRange,
  showFixedNumberOfWeeks,
  minDate,
  checkDateAvailability,
}) => {
  const [value, setValue] = useState([]);
  const [valueS, setValueS] = useState('');
  const [errS, setErrS] = useState('');
  const [openValues, setOpenValues] = useState(false);
  const reset = () => {
    setOpenValues(false);
    setErrS('');
  };
  const selectValue = (value) => {
    if (!selectRange) {
      if (typeof onSelect === 'function') {
        setValue(value);
        setErrS('');
        setValueS(`${formatDate(value)}`);
        onSelect(value);
        setOpenValues(false);
        return;
      }
    }
    const delta = value[1].getTime() - value[0].getTime();
    const days = delta / (1000 * 3600 * 24); //dias
    if (days > 365) {
      setErrS(`O período máximo é de 12 meses`);
      setValue([]);
    } else {
      setValue([...value]);
      setErrS('');
      setValueS(`${formatDate(value[0])} - ${formatDate(value[1])}`);
      if (typeof onSelect === 'function') {
        onSelect(value);
      }
      setOpenValues(false);
    }
  };
  const getConsumedOxygenYearsToFilter = useMainStore(
    (state) => state.consumedOxygenYearsToFilter
  );
  if (
    getConsumedOxygenYearsToFilter[0] === defaultRangeBegin &&
    getConsumedOxygenYearsToFilter[1] === defaultRangeEnd
  ) {
    setValueS(
      `${formatDate(defaultRangeBegin)} - ${formatDate(defaultRangeEnd)}`
    );
  } else if (!valueS && minDetail && maxDetail) {
    setValueS(
      `${formatDate(getConsumedOxygenYearsToFilter[0])} - ${formatDate(
        getConsumedOxygenYearsToFilter[1]
      )}`
    );
  }
  return (
    <div style={{ ...style, position: 'relative', boxSizing: 'border-box' }}>
      <BsCalendar4Range
        onClick={() => setOpenValues(!openValues)}
        style={{
          position: 'absolute',
          top: '7px',
          right: '7px',
          color: PrimaryTextColor,
          fontSize: '26px',
          cursor: 'pointer',
        }}
      />
      <ReadOnlyInputText
        disabled
        value={valueS}
        style={{
          color: '#000',
          cursor: 'pointer',
          width: '100%',
          boxSizing: 'border-box',
          border: 'solid 1px #c9c9c9',
          padding: '5px',
          textIndent: '0px',
          paddingLeft: '6px',
          paddingRight: '45px',
          height: height ?? 'auto',
        }}
      ></ReadOnlyInputText>
      {openValues ? (
        <CustomSelectDiv
          className="custom-calendar"
          fontColor={PrimaryTextColor}
          onMouseLeave={() => reset()}
        >
          <div
            style={{
              display: 'inline-flex',
              padding: '10px',
              boxSizing: 'border-box',
              textAlign: 'center',
            }}
          >
            <div>
              {errS !== '' ? (
                <p style={{ fontWeight: '600', color: 'red' }}>{errS}</p>
              ) : (
                <></>
              )}
              <Calendar
                maxDetail={maxDetail}
                locale="pt"
                minDetail={minDetail ?? 'year'}
                onChange={selectValue}
                showFixedNumberOfWeeks={showFixedNumberOfWeeks ?? true}
                selectRange={selectRange ?? true}
                maxDate={maxDate}
                minDate={minDate}
                tileDisabled={checkDateAvailability}
              ></Calendar>
            </div>
          </div>
        </CustomSelectDiv>
      ) : (
        <></>
      )}
    </div>
  );
};
