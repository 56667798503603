import React from 'react';
import { Alarm } from '../types/Alarms';

type AlarmHookProps = {
  alarm: Alarm;
};

/**
 * Hook that handles Alarms requests.
 * @returns hook methods.
 */
export const useAlarms = ({ alarm }: AlarmHookProps) => {
  const [color, setColor] = React.useState<string>('transparent');
  const [isFlashing, setIsFlashing] = React.useState<boolean>(false);

  const setAlarmColor = async () => {
    if (alarm) {
      setColor(alarm.color);
      setIsFlashing(alarm.is_flashing ?? false);
    }
  };

  return {
    color,
    isFlashing,
    setAlarmColor,
  };
};
