import { formatCPF, formatDateMMddYYYY } from '../../utils/utils';

export const ExcelTable = ({ table, data, user }) => {
  const getBorderExcel = (sides, border) => {
    const obj = {};
    sides.forEach((s) => {
      obj[s] = { style: border ?? 'medium', color: { rgb: '4a4747' } };
    });
    return JSON.stringify(obj);
  };
  const getFormatExcel = (size, bold, horizontal, color) => {
    const obj = {
      font: {
        sz: size ?? '12',
        bold: bold === true,
      },
      alignment: {
        horizontal: horizontal ?? 'left',
        wrapText: true,
      },
    };
    if (color) {
      obj.font.color = {
        rgb: color,
      };
    }
    return JSON.stringify(obj);
  };

  const getExcelDate = (date) => {
    if (!date) return null;
    return `${formatDateMMddYYYY(new Date(date))} 12:00`;
  };
  return (
    <div style={{ display: 'none' }}>
      <table ref={table}>
        <tbody>
          <tr>
            <td
              prop="A1"
              cell-bg-color="084d01"
              colSpan={13}
              cell-format={getFormatExcel('14', true, 'center', 'ffffff')}
            >
              Relatório Gerencial - Portal Homecare
            </td>
          </tr>
          <tr>
            <td
              prop="A2"
              cell-bg-color="084d01"
              cell-format={getFormatExcel('11', true, 'left', 'ffffff')}
            >
              Gerado por:
            </td>
            <td
              prop="B2"
              cell-bg-color="084d01"
              cell-format={getFormatExcel('11', true, 'left', 'ffffff')}
            >
              Gerado em:
            </td>
            <td
              prop="C2"
              cell-bg-color="084d01"
              cell-format={getFormatExcel('11', true, 'left', 'ffffff')}
            >
              Mês - ano:
            </td>
            <td
              prop="D2"
              cell-bg-color="084d01"
              cell-format={getFormatExcel('11', true, 'left', 'ffffff')}
            >
              Nº de dias:
            </td>
            <td prop="E2" cell-bg-color="084d01" colSpan={9}>
              &nbsp;
            </td>
          </tr>
          <tr>
            <td
              prop="A3"
              cell-bg-color="084d01"
              cell-format={getFormatExcel('11', true, 'left', 'ffffff')}
            >{`${user.name}`}</td>
            <td
              prop="B3"
              cell-bg-color="084d01"
              cell-type="d"
              cell-format={getFormatExcel('11', true, 'left', 'ffffff')}
            >{`${formatDateMMddYYYY(new Date())} 12:00`}</td>
            <td
              prop="C3"
              cell-bg-color="084d01"
              cell-format={getFormatExcel('11', true, 'left', 'ffffff')}
            >
              {data.month} - {data.year}
            </td>
            <td
              prop="D3"
              cell-bg-color="084d01"
              cell-format={getFormatExcel('11', true, 'left', 'ffffff')}
            >
              {data.totalMonth}
            </td>
            <td
              prop="E3"
              cell-bg-color="084d01"
              cell-format={getFormatExcel('11', true, 'left', 'ffffff')}
              colSpan={9}
            ></td>
          </tr>
          <tr>
            <td prop="A4" colSpan={13}></td>
          </tr>
          <tr>
            <td prop="A5"></td>
            <td prop="B5"></td>
            <td
              prop="C5"
              cell-bg-color="e2f0d9"
              cell-border={getBorderExcel(['top', 'right', 'left'])}
            >
              &nbsp;
            </td>
            <td prop="D5"></td>
            <td prop="E5"></td>
            {user.canSeeFinancialDataOnReport ? (
              <td
                prop="F5"
                cell-bg-color="e2f0d9"
                cell-border={getBorderExcel(['top', 'left'])}
              >
                &nbsp;
              </td>
            ) : (
              <td prop="F5"></td>
            )}
            {user.canSeeFinancialDataOnReport ? (
              <td
                prop="G5"
                cell-bg-color="e2f0d9"
                cell-border={getBorderExcel(['top', 'right'])}
              >
                &nbsp;
              </td>
            ) : (
              <td prop="G5"></td>
            )}
            <td prop="H5"></td>
            <td prop="I5">&nbsp;</td>
            <td prop="J5">&nbsp;</td>
            <td prop="K5">&nbsp;</td>
            <td prop="L5">&nbsp;</td>
            <td prop="M5">&nbsp;</td>
          </tr>
          <tr>
            <td prop="A6"></td>
            <td prop="B6"></td>
            <td
              prop="C6"
              cell-bg-color="e2f0d9"
              cell-border={getBorderExcel(['right', 'left'])}
            >
              Oxigênio
            </td>
            <td prop="D6"></td>
            <td prop="E6"></td>
            {user.canSeeFinancialDataOnReport ? (
              <td
                prop="F6"
                cell-bg-color="e2f0d9"
                cell-border={getBorderExcel(['left'])}
              >
                Locação Atual
              </td>
            ) : (
              <td prop="F6"></td>
            )}
            {user.canSeeFinancialDataOnReport ? (
              <td
                prop="G6"
                cell-bg-color="e2f0d9"
                cell-border={getBorderExcel(['right'])}
              >
                &nbsp;
              </td>
            ) : (
              <td prop="G6"></td>
            )}
            <td prop="H6">&nbsp;</td>
            <td prop="I6">&nbsp;</td>
            <td prop="J6">&nbsp;</td>
            <td prop="K6">&nbsp;</td>
            <td prop="L6">&nbsp;</td>
            <td prop="M6">&nbsp;</td>
          </tr>
          <tr>
            <td prop="A7"></td>
            <td prop="B7"></td>
            <td
              prop="C7"
              cell-bg-color="e2f0d9"
              cell-border={getBorderExcel(['right', 'left'])}
              cell-format={getFormatExcel(8)}
            >
              Consumo mensal
            </td>
            <td prop="D7"></td>
            <td prop="E7"></td>
            {user.canSeeFinancialDataOnReport ? (
              <td
                prop="F7"
                cell-bg-color="e2f0d9"
                cell-border={getBorderExcel(['left'])}
                cell-format={getFormatExcel(8)}
              >
                Valor
              </td>
            ) : (
              <td prop="F7"></td>
            )}
            {user.canSeeFinancialDataOnReport ? (
              <td
                prop="G7"
                cell-bg-color="e2f0d9"
                cell-border={getBorderExcel(['right'])}
              >
                &nbsp;
              </td>
            ) : (
              <td prop="G7"></td>
            )}
            <td prop="H7"></td>
            <td prop="I7">&nbsp;</td>
            <td prop="J7">&nbsp;</td>
            <td prop="K7">&nbsp;</td>
            <td prop="L7">&nbsp;</td>
            <td prop="M7">&nbsp;</td>
          </tr>
          <tr>
            <td prop="A8"></td>
            <td prop="B8"></td>
            <td
              prop="C8"
              cell-border={getBorderExcel(['right', 'left'])}
              cell-format={getFormatExcel(12, true, 'left')}
            >{`${parseFloat(data.oxygenConsumed.toFixed(2)).toLocaleString(
              'pt-BR'
            )}m³`}</td>
            <td prop="D8"></td>
            <td prop="E8"></td>
            {user.canSeeFinancialDataOnReport ? (
              <td
                prop="F8"
                cell-border={getBorderExcel(['left'])}
                cell-format={getFormatExcel(12, true, 'left')}
              >{`${
                data.currentLeasing
                  ? data.currentLeasing.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })
                  : 'N/A'
              }`}</td>
            ) : (
              <td prop="F8"></td>
            )}
            {user.canSeeFinancialDataOnReport ? (
              <td prop="G8" cell-border={getBorderExcel(['right'])}>
                &nbsp;
              </td>
            ) : (
              <td prop="G8"></td>
            )}
            <td prop="H8"></td>
            <td prop="I8">&nbsp;</td>
            <td prop="J8">&nbsp;</td>
            <td prop="K8">&nbsp;</td>
            <td prop="L8">&nbsp;</td>
            <td prop="M8">&nbsp;</td>
          </tr>
          <tr>
            <td prop="A9"></td>
            <td prop="B9"></td>
            <td prop="C9" cell-border={getBorderExcel(['right', 'left'])}>
              &nbsp;
            </td>
            <td prop="D9"></td>
            <td prop="E9"></td>
            {user.canSeeFinancialDataOnReport ? (
              <td prop="F9" cell-border={getBorderExcel(['left'])}>
                &nbsp;
              </td>
            ) : (
              <td prop="F9"></td>
            )}
            {user.canSeeFinancialDataOnReport ? (
              <td prop="G9" cell-border={getBorderExcel(['right'])}>
                &nbsp;
              </td>
            ) : (
              <td prop="G9"></td>
            )}
            <td prop="H9"></td>
            <td prop="I9">&nbsp;</td>
            <td prop="J9">&nbsp;</td>
            <td prop="K9">&nbsp;</td>
            <td prop="L9">&nbsp;</td>
            <td prop="M9">&nbsp;</td>
          </tr>
          <tr>
            <td prop="A10"></td>
            <td prop="B10"></td>
            <td
              prop="C10"
              cell-border={getBorderExcel(['bottom', 'right', 'left'])}
            >
              &nbsp;
            </td>
            <td prop="D10"></td>
            <td prop="E10"></td>
            {user.canSeeFinancialDataOnReport ? (
              <td prop="F10" cell-border={getBorderExcel(['bottom', 'left'])}>
                &nbsp;
              </td>
            ) : (
              <td prop="F10"></td>
            )}
            {user.canSeeFinancialDataOnReport ? (
              <td prop="G10" cell-border={getBorderExcel(['bottom', 'right'])}>
                &nbsp;
              </td>
            ) : (
              <td prop="G10"></td>
            )}
            <td prop="H10"></td>
            <td prop="I10">&nbsp;</td>
            <td prop="J10">&nbsp;</td>
            <td prop="K10">&nbsp;</td>
            <td prop="L10">&nbsp;</td>
            <td prop="M10">&nbsp;</td>
          </tr>
          <tr>
            <td prop="A11"></td>
            <td prop="B11"></td>
            <td prop="C11"></td>
            <td prop="D11"></td>
            <td prop="E11"></td>
            <td prop="F11"></td>
            <td prop="G11"></td>
            <td prop="H11"></td>
            <td prop="I11">&nbsp;</td>
            <td prop="J11">&nbsp;</td>
            <td prop="K11">&nbsp;</td>
            <td prop="L11">&nbsp;</td>
            <td prop="M11">&nbsp;</td>
          </tr>
          <tr>
            <td prop="A12"></td>
            <td prop="B12"></td>
            <td prop="C12"></td>
            <td prop="D12"></td>
            <td prop="E12"></td>
            <td prop="F12"></td>
            <td prop="G12"></td>
            <td prop="H12"></td>
            <td prop="I12">&nbsp;</td>
            <td prop="J12">&nbsp;</td>
            <td prop="K12">&nbsp;</td>
            <td prop="L12">&nbsp;</td>
            <td prop="M12">&nbsp;</td>
          </tr>
          <tr>
            <td prop="A13"></td>
            <td prop="B13"></td>
            <td prop="C13"></td>
            <td prop="D13"></td>
            <td prop="E13"></td>
            <td prop="F13"></td>
            <td prop="G13"></td>
            <td prop="H13"></td>
            <td prop="I13">&nbsp;</td>
            <td prop="J13">&nbsp;</td>
            <td prop="K13">&nbsp;</td>
            <td prop="L13">&nbsp;</td>
            <td prop="M13">&nbsp;</td>
          </tr>
          <tr>
            <td prop="A14"></td>
            <td prop="B14"></td>
            <td prop="C14"></td>
            <td prop="D14"></td>
            <td prop="E14"></td>
            <td prop="F14"></td>
            <td prop="G14"></td>
            <td prop="H14"></td>
            <td prop="I14">&nbsp;</td>
            <td prop="J14">&nbsp;</td>
            <td prop="K14">&nbsp;</td>
            <td prop="L14">&nbsp;</td>
            <td prop="M14">&nbsp;</td>
          </tr>
          <tr>
            <td
              prop="A15"
              cell-border={getBorderExcel(
                ['top', 'left', 'bottom', 'right'],
                'thin'
              )}
            >
              Paciente
            </td>
            <td
              prop="B15"
              cell-border={getBorderExcel(
                ['top', 'left', 'bottom', 'right'],
                'thin'
              )}
            >
              CPF
            </td>
            <td
              prop="C15"
              cell-border={getBorderExcel(
                ['top', 'left', 'bottom', 'right'],
                'thin'
              )}
            >
              Equipamentos e acessórios
            </td>
            <td
              prop="D15"
              cell-border={getBorderExcel(
                ['top', 'left', 'bottom', 'right'],
                'thin'
              )}
            >
              Categoria
            </td>
            <td
              prop="E15"
              cell-border={getBorderExcel(
                ['top', 'left', 'bottom', 'right'],
                'thin'
              )}
            >
              Subcategoria
            </td>
            <td
              prop="F15"
              cell-border={getBorderExcel(
                ['top', 'left', 'bottom', 'right'],
                'thin'
              )}
            >
              Nº série
            </td>
            <td
              prop="G15"
              cell-border={getBorderExcel(
                ['top', 'left', 'bottom', 'right'],
                'thin'
              )}
            >
              Quantidade
            </td>
            <td
              prop="H15"
              cell-border={getBorderExcel(
                ['top', 'left', 'bottom', 'right'],
                'thin'
              )}
            >
              Status
            </td>
            <td
              prop="I15"
              cell-border={getBorderExcel(
                ['top', 'left', 'bottom', 'right'],
                'thin'
              )}
            >
              Data implantação
            </td>
            <td
              prop="J15"
              cell-border={getBorderExcel(
                ['top', 'left', 'bottom', 'right'],
                'thin'
              )}
            >
              Data inativação
            </td>
            <td
              prop="K15"
              cell-border={getBorderExcel(
                ['top', 'left', 'bottom', 'right'],
                'thin'
              )}
            >
              Dias cobrados
            </td>
            {user.canSeeFinancialDataOnReport ? (
              <td
                prop="L15"
                cell-border={getBorderExcel(
                  ['top', 'left', 'bottom', 'right'],
                  'thin'
                )}
              >
                Locação atual
              </td>
            ) : (
              <></>
            )}
            {user.canSeeFinancialDataOnReport ? (
              <td
                prop="M15"
                cell-border={getBorderExcel(
                  ['top', 'left', 'bottom', 'right'],
                  'thin'
                )}
              >
                Locação mensal
              </td>
            ) : (
              <></>
            )}
          </tr>

          {data.patients.map((patient, idx) => (
            <tr key={idx}>
              <td
                prop={`A${16 + idx}`}
                cell-bg-color={idx % 2 === 0 ? 'e2f0d9' : 'ffffff'}
                cell-border={getBorderExcel(
                  ['top', 'left', 'bottom', 'right'],
                  'thin'
                )}
              >
                {patient.client_name}
              </td>
              <td
                prop={`B${16 + idx}`}
                cell-bg-color={idx % 2 === 0 ? 'e2f0d9' : 'ffffff'}
                cell-border={getBorderExcel(
                  ['top', 'left', 'bottom', 'right'],
                  'thin'
                )}
              >
                {formatCPF(patient.cpf) ?? 'falta cpf'}
              </td>
              <td
                prop={`C${16 + idx}`}
                cell-bg-color={idx % 2 === 0 ? 'e2f0d9' : 'ffffff'}
                cell-border={getBorderExcel(
                  ['top', 'left', 'bottom', 'right'],
                  'thin'
                )}
              >
                {patient.product_description}
              </td>
              <td
                prop={`D${16 + idx}`}
                cell-bg-color={idx % 2 === 0 ? 'e2f0d9' : 'ffffff'}
                cell-border={getBorderExcel(
                  ['top', 'left', 'bottom', 'right'],
                  'thin'
                )}
              >
                {patient.category}
              </td>
              <td
                prop={`E${16 + idx}`}
                cell-bg-color={idx % 2 === 0 ? 'e2f0d9' : 'ffffff'}
                cell-border={getBorderExcel(
                  ['top', 'left', 'bottom', 'right'],
                  'thin'
                )}
              >
                {patient.subcategory}
              </td>
              <td
                prop={`F${16 + idx}`}
                cell-bg-color={idx % 2 === 0 ? 'e2f0d9' : 'ffffff'}
                cell-border={getBorderExcel(
                  ['top', 'left', 'bottom', 'right'],
                  'thin'
                )}
              >
                {patient.manufacturer_ns}
              </td>
              <td
                prop={`G${16 + idx}`}
                cell-bg-color={idx % 2 === 0 ? 'e2f0d9' : 'ffffff'}
                cell-border={getBorderExcel(
                  ['top', 'left', 'bottom', 'right'],
                  'thin'
                )}
              >
                {patient.total_balance}
              </td>
              <td
                prop={`H${16 + idx}`}
                cell-bg-color={idx % 2 === 0 ? 'e2f0d9' : 'ffffff'}
                cell-border={getBorderExcel(
                  ['top', 'left', 'bottom', 'right'],
                  'thin'
                )}
              >
                {patient.status}
              </td>
              <td
                prop={`I${16 + idx}`}
                cell-bg-color={idx % 2 === 0 ? 'e2f0d9' : 'ffffff'}
                cell-type={patient.activation_date ? 'd' : 's'}
                cell-border={getBorderExcel(
                  ['top', 'left', 'bottom', 'right'],
                  'thin'
                )}
              >
                {getExcelDate(patient.activation_date)}
              </td>
              <td
                prop={`J${16 + idx}`}
                cell-bg-color={idx % 2 === 0 ? 'e2f0d9' : 'ffffff'}
                cell-type={patient.deactivation_date ? 'd' : 's'}
                cell-border={getBorderExcel(
                  ['top', 'left', 'bottom', 'right'],
                  'thin'
                )}
              >
                {getExcelDate(patient.deactivation_date)}
              </td>
              <td
                prop={`K${16 + idx}`}
                cell-bg-color={idx % 2 === 0 ? 'e2f0d9' : 'ffffff'}
                cell-border={getBorderExcel(
                  ['top', 'left', 'bottom', 'right'],
                  'thin'
                )}
              >
                {patient.days_billed}
              </td>
              {user.canSeeFinancialDataOnReport ? (
                <td
                  prop={`L${16 + idx}`}
                  cell-bg-color={idx % 2 === 0 ? 'e2f0d9' : 'ffffff'}
                  cell-border={getBorderExcel(
                    ['top', 'left', 'bottom', 'right'],
                    'thin'
                  )}
                >
                  {patient.monthly_lease
                    ? patient.monthly_lease.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })
                    : '-'}
                </td>
              ) : (
                <></>
              )}
              {user.canSeeFinancialDataOnReport ? (
                <td
                  prop={`M${16 + idx}`}
                  cell-bg-color={idx % 2 === 0 ? 'e2f0d9' : 'ffffff'}
                  cell-border={getBorderExcel(
                    ['top', 'left', 'bottom', 'right'],
                    'thin'
                  )}
                >
                  {patient.value
                    ? patient.value.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })
                    : '-'}
                </td>
              ) : (
                <></>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
