import { doDelete, doGet, doPost, doPut, doRequest } from './httpHelper';

export const listUsers = async () => {
  const resp = await doGet('/users');
  return resp;
};

export const delUser = async (email) => {
  const resp = await doDelete(`/users?email=${email}`);
  return resp;
};

export const createUser = async (user) => {
  const resp = await doPost(`/users`, user);
  return resp.body;
};

export const editUser = async (user) => {
  const resp = await doPut(`/users`, user);
  return resp.body;
};

export const signPrivacyTerm = async (user) => {
  const resp = await doPost(`/signPrivacyTerm`, user);
  return resp;
};

export const verifyCaptcha = async (value) => {
  return await fetch(`${process.env.REACT_APP_PUBLIC_API_URL}/captcha`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ captcha: value }),
  });
};

export const ping = async () => {
  return await doGet('/ping', false);
};
