import { styled } from '@mui/material/styles';
import {
  BoxProps as MUIBoxProps,
  Box as MUIBox,
  Typography,
} from '@mui/material';
import React from 'react';

type TriageBoxProps = {
  onOpen: () => void;
} & MUIBoxProps;

export const TriageBox: React.FC<TriageBoxProps> = styled(MUIBox)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  background: '#E9E9E9',
  borderRadius: '10px',
  padding: '10px',
}));

export const BoldText = styled('span')(() => ({
  fontWeight: 700,
}));

export const ItalicText = styled('span')(() => ({
  fontStyle: 'italic',
  padding: '0px',
}));

export const SubTitle = styled(Typography)(() => ({
  marginBottom: '12px',
  fontWeight: '700',
}));
