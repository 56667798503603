import currencyFormatter from 'currency-formatter';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { useKPIStore } from '../../stores/kpiStore';

const OxyLabel = styled.p`
  text-align: center;
  width: 100%;
  margin: 0px;
`;

export const CurrentLease = () => {
  const currentRentalValue = useKPIStore((state) => state.currentRentalValue);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1366px)' });

  const calculateTotal = () => {
    if (currentRentalValue) {
      return currencyFormatter
        .format(currentRentalValue, { code: 'BRL' })
        .replace('R$', '')
        .trim();
    }

    return currencyFormatter
      .format(0, { code: 'BRL' })
      .replace('R$', '')
      .trim();
  };

  return (
    <OxyLabel>
      <span
        style={{
          fontSize: isTabletOrMobile ? '24px' : '32px',
          color: '#318300',
          fontWeight: 700,
        }}
      >
        R$
      </span>
      <span
        style={{
          fontSize: isTabletOrMobile ? '40px' : '60px',
          fontWeight: 700,
          color: '#318300',
        }}
      >
        {calculateTotal()}
      </span>
    </OxyLabel>
  );
};
