import create from 'zustand';

export const useCollectionStore = create((set, get) => ({
  selectedPatient: null,
  configuredCollections: null,
  collectionSelected: {},
  equipmentList: [],
  setSelectedPatient: (patient) => {
    set({ selectedPatient: { ...patient } });
  },
  setCollectionSelected: (collection) => {
    set({ collectionSelected: JSON.parse(JSON.stringify(collection)) });
  },
  setEquipmentList: (eqList) => {
    set({ equipmentList: [...eqList] });
  },
  setConfiguredCollection: (collection) => {
    set({ configuredCollections: JSON.parse(JSON.stringify(collection)) });
  },
  reset: () => {
    set({
      selectedPatient: null,
      configuredCollections: null,
      collectionSelected: {},
      equipmentList: [],
    });
  },
  getKeptItems: () => {
    const result = [];
    Object.keys(get().collectionSelected).forEach((parentEqId) => {
      const equi = get().equipmentList.filter(
        (x) => x.id === parseInt(parentEqId) || x.id === parentEqId
      )[0];
      result.push({
        name: equi.name,
        segment: equi.segment,
        collect: get().collectionSelected[parentEqId].parent,
        qty: equi.qty,
        id: equi.id,
      });
      Object.keys(get().collectionSelected[parentEqId]).forEach((subItemId) => {
        if (subItemId === 'parent') {
          return;
        }
        const subItem = equi.accessories.filter(
          (x) => x.id === parseInt(subItemId) || x.id === subItemId
        )[0];
        result.push({
          name: subItem.name,
          segment: subItem.segment,
          collect: get().collectionSelected[parentEqId][subItemId],
        });
      });
    });
    return result;
  },
}));
