import '../@types/theme.d.ts';
import { createTheme } from '@mui/material/styles';
import { colors } from './core/colors';
import { typography } from './core/typography';

const theme = {
  mode: 'light',
  typography: typography,
  palette: {
    primary: {
      main: colors.main,
    },
  },
  colors: colors,
  components: {
    MuiButton: {
      styleOverrides: {
        root: { minWidth: 0 },
      },
    },
  },
};

export default createTheme(theme);
