import { Green2, Green3 } from '../../base/colors';
import { StatePanel } from '../../components/dashboards/statePanel';
import { useMainStore } from '../../stores/mainStore';

export const OxygenConsumption = () => {
  const oxygenConsumption = useMainStore((state) => state.oxygenConsumption);
  return (
    <StatePanel
      className="box"
      color={Green3}
      value={oxygenConsumption}
      unit={'m³'}
      icon={'/icones/cilindroO2.png'}
      label={['Consumo de oxigênio']}
      showNote={true}
    ></StatePanel>
  );
};
