import { useEffect, useState } from 'react';
import { InputText } from '../../base/Inputs';
import { BsSearch } from 'react-icons/bs';
import { RiCloseCircleFill } from 'react-icons/ri';
import { PrimaryTextColor, TextColor } from '../../base/colors';
import { usePortalStore } from '../../store';
import styled from 'styled-components';
import { getPatients } from '../../services/patientsService';
import { useKPIStore } from '../../stores/kpiStore';
import { formatCPF } from '../../utils/utils';

const AutoCompleteDiv = styled.div`
  position: absolute;
  background-color: white;
  box-sizing: border-box;
  border: solid 1px #efefef;
  width: 100%;
  font-size: 14px;
  border-radius: 10px;
  overflow-y: auto;
  max-height: 300px;
  margin-top: 5px;
  z-index: 2;
`;

const AutoCompleteItem = styled.li`
  box-sizing: border-box;
  padding: 10px;
  color: ${(props) => props.fontColor};
  &:hover {
    background-color: ${(props) => props.bgColor};
    color: white;
    cursor: pointer;
  }
`;

export const AutoCompletePatients = ({ style }) => {
  const currentUser = usePortalStore((state) => state.currentUser);
  const [patients, setPatients] = useState([]);
  const [value, setValue] = useState('');
  const fetchPatientsKpi = useKPIStore((state) => state.fetchPatientsKpi);
  const setCPF = useKPIStore((state) => state.setCPF);
  const selectedCPF = useKPIStore((state) => state.cpf);
  let timeout = 0;
  useEffect(() => {
    setCPF(null);
  }, []);
  const onChange = (value) => {
    setValue(value);
    clearTimeout(timeout);
    timeout = setTimeout(async () => {
      if (value === '') {
        setPatients([]);
      } else {
        const patients = await getPatients(
          currentUser.cnpj,
          value,
          0,
          20,
          'Ativo'
        );
        const patientList = patients.data.filter(
          (p) => p.onPaymentList === true
        );
        setPatients([...patientList]);
      }
    }, 500);
  };
  const reset = () => {
    setPatients([]);
  };
  const selectPatient = (patient) => {
    setCPF(patient.cpf);
    setValue(`${patient.name} - ${formatCPF(patient.cpf)}`);
    setPatients([]);
    fetchPatientsKpi();
  };
  const removeSelectedPatient = () => {
    setCPF(null);
    setValue('');
    fetchPatientsKpi();
  };
  return (
    <div
      style={{
        ...style,
        position: 'relative',
        boxSizing: 'border-box',
        marginBottom: '10px',
      }}
    >
      <BsSearch
        style={{ position: 'absolute', top: '12px', left: '12px' }}
      ></BsSearch>
      {selectedCPF !== null ? (
        <RiCloseCircleFill
          onClick={removeSelectedPatient}
          style={{
            position: 'absolute',
            top: '7px',
            right: '7px',
            color: PrimaryTextColor,
            fontSize: '26px',
            cursor: 'pointer',
          }}
        ></RiCloseCircleFill>
      ) : (
        <></>
      )}
      <InputText
        placeholder="Pesquisar paciente por nome ou CPF"
        onChange={(e) => onChange(e.target.value)}
        value={value}
        style={{
          color: PrimaryTextColor,
          width: '100%',
          boxSizing: 'border-box',
          border: 'solid 1px #c9c9c9',
          padding: '5px',
          textIndent: '0px',
          paddingLeft: '35px',
          paddingRight: '45px',
        }}
      ></InputText>
      {patients.length > 0 ? (
        <AutoCompleteDiv onMouseLeave={reset}>
          <ul style={{ listStyle: 'none', padding: '0px', margin: '0px' }}>
            {patients.map((p, idx) => (
              <AutoCompleteItem
                fontColor={PrimaryTextColor}
                onClick={() => selectPatient(p)}
                bgColor={TextColor}
                key={idx}
              >{`${p.name} - ${formatCPF(p.cpf)}`}</AutoCompleteItem>
            ))}
          </ul>
        </AutoCompleteDiv>
      ) : (
        <></>
      )}
    </div>
  );
};
