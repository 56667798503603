import create from 'zustand';
import { getSubCategories } from '../services/productService';
import { getQuestions } from '../services/questionsService';
import { getProductsByCodes } from '../services/productService';

export const useOrderFlowStore = create((set, get) => ({
  areas: [
    {
      coverImageURL: '/area/implantacao.jpg',
      Categoria: 'Implantação',
      disable: false,
      shortDescription:
        'Solicitação para implantação de equipamentos e cilindros e/ou reposição de descartáveis',
      categories: [
        {
          imagePathBase: '/categoria/implantacao/oxigenoterapia/',
          coverImageURL: '/categoria/implantacao/oxigenoterapia/main.jpg',
          id: 'Oxigenoterapia',
          title: 'Oxigenoterapia',
          shortDescription:
            'Locação de equipamentos para tratamento com oxigênio',
        },
        {
          coverImageURL: '/categoria/implantacao/linhaDoSono/main.jpg',
          imagePathBase: '/categoria/implantacao/linhaDoSono/',
          id: 'Ventilação domiciliar/Linha do sono',
          title: 'Linha do sono',
          shortDescription:
            'Equipamentos e acessórios para tratamento do distúrbio do sono',
        },
        {
          coverImageURL: '/categoria/implantacao/linhaDoSono/main.jpg',
          imagePathBase: '/categoria/implantacao/linhaDoSono/',
          id: 'Linha do Sono',
          title: 'Linha do sono',
          shortDescription:
            'Locação de equipamentos para tratamento do distúrbio do sono',
        },
        {
          coverImageURL: '/categoria/implantacao/ventilacaoDomiciliar/main.jpg',
          imagePathBase: '/categoria/implantacao/ventilacaoDomiciliar/',
          id: 'Ventilação Domiciliar',
          title: 'Ventilação domiciliar',
          shortDescription:
            'Locação de equipamentos para terapia respiratórias invasivas e não invasivas',
        },
        {
          coverImageURL: '/categoria/implantacao/oximetros/main.jpg',

          id: 'Oxímetros',
          title: 'Oxímetros',
          shortDescription:
            'Locação de equipamentos que monitoram a saturação de oxigênio e a frequência cardíaca',
        },
        {
          coverImageURL: '/categoria/implantacao/aspiradorDeSecrecao/main.jpg',

          id: 'Aspiradores de Secreção',
          title: 'Aspiradores de secreção',
          shortDescription:
            'Locação de equipamentos que auxiliam a aspiração de secreção de um paciente',
        },
        {
          coverImageURL:
            '/categoria/implantacao/dispositivosAcessorios/main.jpg',

          id: 'Dispositivos e Acessórios de equipamentos Médicos',
          title: 'Descartáveis para equipamentos médicos',
          shortDescription: 'Solicitação de reposição dos descartáveis',
        },
      ],
    },
    {
      coverImageURL: '/area/assistenciaTecnica.jpg',
      Categoria: 'Assistência técnica',
      disable: false,
      shortDescription:
        'Solicitação para substituição de equipamento por falha, dano ou avaria',
      categories: [],
    },
    {
      coverImageURL: '/area/recarga.jpg',
      Categoria: 'Recarga',
      disable: false,
      shortDescription:
        'Solicitação para recarga do gás de cilindro já instalado',
      categories: [],
    },
    {
      coverImageURL: '/area/recolhimento.jpg',
      Categoria: 'Recolhimento',
      disable: false,
      shortDescription:
        'Solicitação de recolhimento parcial ou total de equipamentos e/ou  cilindros instalados',
      categories: [],
    },
    {
      coverImageURL: '/area/venda.jpg',
      Categoria: 'Venda',
      disable: true,
      shortDescription: 'Em desenvolvimento',
      categories: [],
    },
    {
      coverImageURL: '/area/servico.jpg',
      Categoria: 'Serviço',
      disable: true,
      shortDescription: 'Em desenvolvimento',
      categories: [],
    },
  ],
  selectedArea: null,
  selectedPatient: null,
  currentPatientBranch: null,
  productPriorityMap: null,
  selectedSubcategories: null,
  selectedCategory: null,
  selectedSubCategory: null,
  configuredEquipment: null,
  availableCategories: [],
  questions: [],
  fullListProd: null,
  setConfiguredEquipment: (configureEquipment) => {
    set({
      configuredEquipment: JSON.parse(JSON.stringify(configureEquipment)),
    });
  },

  setAvailableCategories: (categories) => {
    set({ availableCategories: JSON.parse(JSON.stringify(categories)) });
  },

  setSelectedArea: (selectedArea) => {
    set({ selectedArea });
  },

  setSelectedPatient: (patient) => {
    set({ selectedPatient: { ...patient } });
  },
  setPatientBranch: (branch) => {
    set({ currentPatientBranch: { ...branch } });
  },
  setProductPriority: (productPriorityMap) => {
    set({ productPriorityMap: { ...productPriorityMap } });
  },
  setSelectedCategory: async (cnpj, category) => {
    const subcategories = await getSubCategories(category.id);
    set({
      selectedSubcategories: [...[subcategories]],
      selectedCategory: { ...category },
    });
  },
  setSelectedSubcategory: async (selectedSubCategory, selectedModel) => {
    const sc = { ...selectedSubCategory, selectedModel };
    let questions = [];
    // const branchId = get().currentPatientBranch.id;
    // const procQuestions = {};
    let prods = [];
    if (selectedSubCategory.sap_code) {
      //selectedSubCategory.sap_code = 90001431;
      questions = await getQuestions(selectedSubCategory.sap_code);
      const prodIds = new Set(
        questions
          .map((x) => x.options)
          .flat()
          .map((x) => x.answers)
          .flat()
          .map((x) => x.product_id)
          .flat()
      );
      const prodsList = await getProductsByCodes(Array.from(prodIds));
      prodsList.forEach((p) => {
        prods[p.sap_code] = p;
      });
      //console.log('Produtos utilizados nas questões: ',prods.map(x => ({id:x.sap_code, branches:x.product_branches})))
      /*const prodsToKeep = new Set(prods.filter(x => x.product_branches.filter(y => y.branch_id === branchId).length > 0).map(x => x.sap_code));
            console.log('produtos habilitados para a filial ',branchId, prodsToKeep)
            questions.forEach(q => {
                q.options = q.options.filter(o => {
                    return o.answers.filter(x => x.product_id.filter(y => prodsToKeep.has(y.toString()) || y === 99999999).length > 0).length > 0
                })
                if (q.options.length > 0) {
                    // q.options.forEach(o => {
                    //     o.answers = o.answers.filter(x => x.product_id.filter(y => prodsToKeep.has(y.toString())|| y === 99999999).length > 0)
                    // })
                    procQuestions.push(q)
                }else{
                    console.log('removida a questão',q)
                }
            })*/
    }
    set({
      questions: [...questions],
      fullListProd: { ...prods },
      selectedSubCategory: JSON.parse(JSON.stringify(sc)),
    });
  },
  reset: () => {
    set({
      selectedPatient: null,
      selectedSubcategories: null,
      selectedCategory: null,
      selectedSubCategory: null,
      configuredEquipment: null,
      currentPatientBranch: null,
      accessories: [],
      fullListProd: null,
      productPriorityMap: null,
      questions: [],
    });
  },
}));
