import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

export const ImageSlider = ({ style, images, maxHeight, hideNoImage }) => {
  return (
    <div
      className="image-slider"
      style={{
        ...style,
        display: 'block',
        textAlign: 'center',
        minWidth: '250px',
        maxWidth: '250px',
        margin: '0 auto',
      }}
    >
      {(images ?? []).length > 1 ? (
        <ImageGallery
          showFullscreenButton={false}
          showPlayButton={false}
          showThumbnails={false}
          items={(images ?? []).map((x) => ({ original: x, thumbnail: x }))}
        />
      ) : (images ?? []).length === 1 ? (
        <img
          style={{
            maxHeight: maxHeight ?? '250px',
            objectFit: 'contain',
            maxWidth: '250px',
          }}
          src={images[0]}
        />
      ) : hideNoImage === true ? (
        <></>
      ) : (
        <img
          style={{ maxHeight: '200px' }}
          src="/icones/placeholder-image.png"
        />
      )}
    </div>
  );
}; //
