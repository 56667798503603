import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const ModalHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: theme.spacing(2),
  padding: theme.spacing(2),
  background: theme.colors.success.background,

  '& .info-modal-title': {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    gap: theme.spacing(0.5),
    color: theme.colors.success.text,
  },
}));

export const ModalBody = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: theme.spacing(2),
}));
