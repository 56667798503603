import { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { WideLayout } from '../../components/layout/wideLayout';
import { usePortalStore } from '../../store';
import { useCollectionStore } from '../../stores/collectionStore';
import { useOrderFlowStore } from '../../stores/newOrderFlowStore';
import { useRechargeStore } from '../../stores/rechargeStore';
import { useTechnicalAssistanceStore } from '../../stores/technicalAssistanceStore';
import { TilesSelection } from './tilesSelection';
/**
 *
 * @returns
 */
export const OrderSelectArea = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const currentUser = usePortalStore((state) => state.currentUser);
  const areas = useOrderFlowStore((state) => state.areas);
  const setSelectedArea = useOrderFlowStore((state) => state.setSelectedArea);
  const resetImpl = useOrderFlowStore((state) => state.reset);

  const resetAssistance = useTechnicalAssistanceStore((state) => state.reset);
  const resetCollection = useCollectionStore((state) => state.reset);
  const resetRecharge = useRechargeStore((state) => state.reset);
  const navigate = useNavigate();
  const onClickArea = (area) => {
    setSelectedArea({ ...area });
    if (area.Categoria === 'Implantação') {
      resetImpl();
      navigate('/order/selectPatient');
    } else if (area.Categoria === 'Assistência técnica') {
      resetAssistance();
      navigate('/technicalAssistance/assistanceToPatient');
    } else if (area.Categoria === 'Recolhimento') {
      resetCollection();
      navigate('/collectEquipments/collectFromPatient');
    } else if (area.Categoria === 'Recarga') {
      resetRecharge();
      navigate('/recharge/rechargePatient');
    }
  };
  return (
    <WideLayout
      outlet={
        <TilesSelection
          onClickTile={onClickArea}
          tiles={areas}
        ></TilesSelection>
      }
    ></WideLayout>
  );
};
