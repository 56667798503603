import create from 'zustand';
import { fetchPatientsWithCylinders } from '../services/rechargeService';
import { isGenericCylinder } from '../utils/utils';

export const useRechargeStore = create((set, get) => ({
  selectedPatient: null,
  patients: [],
  rechargerActions: {},
  exChangeCylinders: {},
  expandVisible: {},
  onlyWithCylinders: false,
  pageSize: 10,
  page: '0',
  rows: 0,
  selectedPatientIndex: -1,
  listState: [],
  setListState: (l) => {
    set({ listState: [...l] });
  },
  setPagination: (page, pageSize) => {
    set({ page, pageSize });
  },
  setOnlyWithCylinder: (value) => {
    set({ onlyWithCylinders: value });
  },
  fetchPatients: async (filter) => {
    const patients = await fetchPatientsWithCylinders(
      filter,
      get().onlyWithCylinders,
      get().page,
      get().pageSize,
      'Ativo'
    );
    const obj = {};
    const dd = {};
    patients.data?.forEach((p, idx) => {
      obj[idx] = Array.from({ length: p.cylinders.length * 2 }).map(
        (x) => false
      );
      p.cylinders.forEach((cylinder, idxCylinder) => {
        if (!dd[idx]) {
          dd[idx] = {};
        }
        if (!dd[idx][idxCylinder]) {
          dd[idx][idxCylinder] = {};
        }
        dd[idx][idxCylinder].cylinder = { ...cylinder };
        dd[idx][idxCylinder].action = isGenericCylinder(cylinder)
          ? 'troca'
          : '-';
      });
    });
    set({
      rows: patients.rows,
      patients: [...patients.data],
      rechargerActions: JSON.parse(JSON.stringify(dd)),
      expandVisible: JSON.parse(JSON.stringify(obj)),
    });
    return patients;
  },

  setExchangeCylinders: (obj) => {
    set({ exChangeCylinders: JSON.parse(JSON.stringify(obj)) });
  },
  setExpandVisible: (obj) => {
    set({ expandVisible: JSON.parse(JSON.stringify(obj)) });
  },

  setSelectedPatient: (patient) => {
    if (patient === null) {
      set({ selectedPatient: null });
    } else {
      set({ selectedPatient: { ...patient } });
    }
  },
  setReChargerActions: (idx, idxCylinder, cylinder, action) => {
    const dd = get().rechargerActions;
    if (!dd[idx]) {
      dd[idx] = {};
    }
    if (!dd[idx][idxCylinder] || action !== 'troca') {
      dd[idx][idxCylinder] = {};
    }
    dd[idx][idxCylinder].cylinder = { ...cylinder };
    dd[idx][idxCylinder].action = action;
    set({
      rechargerActions: JSON.parse(JSON.stringify(dd)),
      selectedPatientIndex: idx,
    });
  },
  setReChargerActionsQuantity: (idx, idxCylinder, cylinder, quantity) => {
    const dd = get().rechargerActions;
    if (!dd[idx]) {
      dd[idx] = {};
    }
    if (!dd[idx][idxCylinder]) {
      dd[idx][idxCylinder] = {};
    }
    dd[idx][idxCylinder].cylinder = { ...cylinder };
    dd[idx][idxCylinder].quantity = quantity;
    if (!dd[idx][idxCylinder].action || dd[idx][idxCylinder].action === '-') {
      dd[idx][idxCylinder].action = 'recarga';
    }
    set({
      rechargerActions: JSON.parse(JSON.stringify(dd)),
      selectedPatientIndex: idx,
    });
  },
  setReChargerExchangeActions: (
    idx,
    idxCylinder,
    newCylinder,
    newCylinderIdx
  ) => {
    const dd = get().rechargerActions;
    if (!dd[idx]) {
      return;
    }

    dd[idx][idxCylinder].newCylinder = { ...newCylinder };
    dd[idx][idxCylinder].newCylinderIdx = newCylinderIdx;
    set({ rechargerActions: JSON.parse(JSON.stringify(dd)) });
  },
  reset: () => {
    const clone = JSON.parse(JSON.stringify(get().expandVisible));
    Object.keys(clone).forEach((k) => {
      clone[k] = false;
    });
    set({
      listState: [],
      selectedPatient: null,
      rechargerActions: {},
      exChangeCylinders: {},
      expandVisible: clone,
      onlyWithCylinders: false,
      patients: [],
    });
  },
}));
