import { useMediaQuery } from 'react-responsive';
import { Panel } from '../../components/panels/panel';

export const TilesSelection = ({ tiles, onClickTile }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  return (
    <div
      style={{
        textAlign: '-webkit-center',
        width: '100%',
        boxSizing: 'border-box',
        height: '100%',
      }}
    >
      <div
        style={{
          padding: '0px',
          boxSizing: 'border-box',
          width: isTabletOrMobile ? '100%' : '100%',
          padding: '0 15px',
          height: '100%',
        }}
      >
        <ul className="tiles-content">
          {tiles.map((tile, idx) => (
            <li key={idx} onClick={() => onClickTile(tile)}>
              <Panel
                width={'100%'}
                style={{
                  boxSizing: 'border-box',
                  maxWidth: '360px',
                  maxHeight: '300px',
                  cursor: tile.disable ? '' : 'pointer',
                  height: '100%',
                  opacity: tile.disable ? 0.3 : 1,
                }}
              >
                <div
                  style={{
                    backgroundImage: `url(${tile.coverImageURL})`,
                    width: '100%',
                    height: '180px',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top center',
                    backgroundSize: 'cover',
                  }}
                ></div>
                <div
                  style={{
                    padding: '15px',
                    boxSizing: 'border-box',
                    textAlign: 'left',
                  }}
                >
                  <h3 style={{ color: 'black', margin: '0px' }}>
                    {tile['Categoria'] ?? tile.title}
                  </h3>
                  <div className="equi-tiles-description">
                    {tile.shortDescription}
                  </div>
                </div>
              </Panel>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
