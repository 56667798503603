import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

type ContainerStyleProps = {
  isTabletOrMobile: boolean;
};

export const Row = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '8px',
}));

export const Container = styled(Box)<ContainerStyleProps>(
  ({ isTabletOrMobile }) => ({
    display: 'flex',
    flexFlow: isTabletOrMobile ? 'column' : 'wrap',
    gap: '13px',
  })
);
