import { useEffect, useState } from 'react';
import { BsSearch } from 'react-icons/bs';
import { RiCloseCircleFill } from 'react-icons/ri';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { PrimaryTextColor, TextColor } from '../../base/colors';
import { InputText } from '../../base/Inputs';
import { getPatients } from '../../services/patientsService';
import { usePortalStore } from '../../store';
import { useRechargeStore } from '../../stores/rechargeStore';
import { formatCPF } from '../../utils/utils';

const AutoCompleteDiv = styled.div`
  position: absolute;
  background-color: white;
  box-sizing: border-box;
  border: solid 1px #efefef;

  width: 100%;
  font-size: 14px;
  border-radius: 10px;
  overflow-y: auto;
  max-height: 300px;
  margin-top: 5px;
  z-index: 2;
`;

const AutoCompleteItem = styled.li`
  box-sizing: border-box;
  padding: 10px;
  color: ${(props) => props.fontColor};
  &:hover {
    background-color: ${(props) => props.bgColor};
    color: white;
    cursor: pointer;
  }
`;

export const AutoCompleteRechargePatients = ({ style }) => {
  const currentUser = usePortalStore((state) => state.currentUser);
  const [patients, setPatients] = useState([]);
  const [value, setValue] = useState('');
  const setSelectedPatient = useRechargeStore(
    (state) => state.setSelectedPatient
  );
  const fetchPatients = useRechargeStore((state) => state.fetchPatients);
  const resetStore = useRechargeStore((state) => state.reset);
  const selectedPatient = useRechargeStore((state) => state.selectedPatient);
  let [searchParams, setSearchParams] = useSearchParams();
  let timeout = 0;
  useEffect(() => {
    const keepState = searchParams.get('keepState');
    if (!keepState) {
      setSelectedPatient(null);
    }
  }, []);
  const onChange = (value) => {
    clearTimeout(timeout);
    setValue(value);
    timeout = setTimeout(async () => {
      if (value.length >= 3) {
        const patients = await getPatients(null, value, 0, 20, 'Ativo');
        const red = patients.data.reduce((acc,idx)=>{
          const exist = acc.find(patient => patient.name === idx.name && patient.cpf === idx.cpf)
          if(!exist){
              acc.push(idx)
          }
          return acc
      },[])
      patients.data = red
        setPatients(patients.data);
      } else {
        setPatients([]);
      }
    }, 1000);
  };
  const reset = () => {
    setPatients([]);
  };
  const selectPatient = (patient) => {
    resetStore();
    setSelectedPatient(patient);
    setValue(`${patient.name} - ${formatCPF(patient.cpf)}`);
    setPatients([]);
    fetchPatients(patient.cpf);
  };
  const removeSelectedPatient = () => {
    // resetStore();
    setSelectedPatient(null);
    setValue('');
    fetchPatients(null);
  };
  return (
    <div style={{ ...style, position: 'relative', boxSizing: 'border-box' }}>
      <BsSearch
        style={{ position: 'absolute', top: '12px', left: '12px' }}
      ></BsSearch>
      {selectedPatient !== null && value !== '' ? (
        <RiCloseCircleFill
          onClick={removeSelectedPatient}
          style={{
            position: 'absolute',
            top: '7px',
            right: '7px',
            color: PrimaryTextColor,
            fontSize: '26px',
            cursor: 'pointer',
          }}
        ></RiCloseCircleFill>
      ) : (
        <></>
      )}
      <InputText
        placeholder="Pesquisar paciente por nome ou CPF"
        onChange={(e) => onChange(e.target.value)}
        value={value}
        style={{
          color: PrimaryTextColor,
          width: '100%',
          boxSizing: 'border-box',
          border: 'solid 1px #c9c9c9',
          padding: '5px',
          textIndent: '0px',
          paddingLeft: '35px',
          paddingRight: '45px',
        }}
      ></InputText>
      {patients.length > 0 ? (
        <AutoCompleteDiv onMouseLeave={reset}>
          <ul style={{ listStyle: 'none', padding: '0px', margin: '0px' }}>
            {patients.map((p, idx) => (
              <AutoCompleteItem
                fontColor={PrimaryTextColor}
                onClick={() => selectPatient(p)}
                bgColor={TextColor}
                key={idx}
              >{`${p.name} - ${formatCPF(p.cpf)}`}</AutoCompleteItem>
            ))}
          </ul>
        </AutoCompleteDiv>
      ) : (
        <></>
      )}
    </div>
  );
};
