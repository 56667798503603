export class OrderEnums {
  enums = {
    statusComplement: {
      INCORRECT_ADDRESS: 1,
      PRODUCT_UNAVAILABLE: 2,
      DELIVERED_WITH_ORIENTATIONS: 3,
      SECURITY_PROBLEMS: 4,
      CANCELLED: 5,
      EXTERNAL_EQUIPMENTS: 6,
      NOBODY_AT_HOME: 7,
      ROAD_CLOSED: 8,
      PRODUCT_CHANGED: 9,
      NOT_AUTHORIZED: 10,
      CHANGED: 11,
      PARTIALLY_DONE: 12,
    },
    status: {
      UNDER_ANALYSIS: 1,
      CONFIRMED: 2,
      CONCLUDED: 3,
      UNSUCCESSFUL: 4,
    },
    deliveryOperations: {
      ABSENCE_OR_PARTIAL_RECEIPT: 1,
      OPERATIONAL_PENDENCY: 2,
      EMERGENCY: 3,
    },
    serviceEvents: {
      UNPRODUCTIVE_VISIT: 1,
      DELIVERY_WITHOUT_PATIENT_REQUEST: 2,
      DIVERGENCE_IN_ORDER_EXECUTION: 3,
      PGK_OPERATION_FAILURE: 4,
      HOMECARE_OPERATION_FAILURE: 5,
    },
    retreatReasons: {
      END_OF_USE: 1,
      HOSPITALIZATION: 2,
      PAYMENT_DUE_TO_DEATH: 3,
      SUPPLIER_CHANGE: 4,
    },
    types: {
      RETREAT: 'Recolhimento',
      RECHARGE: 'Recarga',
      IMPLANTATION: 'Implantação',
      TECHNICAL_ASSISTANCE: 'Assistência Técnica',
      SALE: 'Venda',
    },
  };

  status = {
    isUnderAnalysis: (status_id: string) => {
      return status_id === this.enums.status.UNDER_ANALYSIS.toString();
    },
    isConfirmed: (status_id: string) => {
      return status_id === this.enums.status.CONFIRMED.toString();
    },
    isConcluded: (status_id: string) => {
      return status_id === this.enums.status.CONCLUDED.toString();
    },
    isUnsuccessful: (status_id: string) => {
      return status_id === this.enums.status.UNSUCCESSFUL.toString();
    },
  };

  types = {
    isImplementation: (orderType: string) => {
      return orderType === this.enums.types.IMPLANTATION;
    },
    isRetreat: (orderType: string) => {
      return orderType === this.enums.types.RETREAT;
    },
    isTechnicalAssistance: (orderType: string) => {
      return orderType === this.enums.types.TECHNICAL_ASSISTANCE;
    },
    isRecharge: (orderType: string) => {
      return orderType === this.enums.types.RECHARGE;
    },
    isSale: (orderType: string) => {
      return orderType === this.enums.types.SALE;
    },
  };
}
