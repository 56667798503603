import React from 'react';
import { Modal as MUIModal } from '@mui/material';
import { ModalBody } from './styles';
import { BoxProps, useMediaQuery, useTheme } from '@mui/material';

type ModalProps = {
  open: boolean;
  handleClose?: () => void;
  width?: string;
} & BoxProps;

export const Modal: React.FC<ModalProps> = ({
  open,
  handleClose,
  children,
  width,
  ...rest
}) => {
  const theme = useTheme();
  const isOnMobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <MUIModal keepMounted open={open} onClose={handleClose}>
      <ModalBody
        boxShadow={5}
        mobile={isOnMobileDevice ? 'true' : undefined}
        width={width}
        {...rest}
      >
        {children}
      </ModalBody>
    </MUIModal>
  );
};
