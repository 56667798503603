import produce from 'immer';
import create from 'zustand';

export const ORDER_FLOW_STEPS = {
  SELECT_AREA: 1,
  SELECT_PATIENT: 2,
  SELECT_CATEGORY: 3,
  SELECT_SUBCATEGORY: 4,
  CONFIGURE_EQUIPMENT: 5,
  FINISH_ORDER: 6,
  SELECT_PATIENT_ASSISTANCE: 7,
  EQUIPMENT_ASSISTANCE: 8,
  FINISH_ASSISTANCE_ORDER: 9,
  SELECT_PATIENT_COLLECTION: 10,
  COLLECT_EQUIPMENT: 11,
  FINISH_COLLECT_ORDER: 12,
  SELECT_PATIENT_RECHARGE: 13,
  FINISH_RECHARGE_ORDER: 14,
};

export const usePortalStore = create((set, get) => ({
  currentAlertError: '',
  selectedCategory: '',
  selectedCategoryId: -1,
  breadcrumb: [
    { link: '/orders', label: 'Pedidos' },
    { link: '/order/newOrder', label: 'Inclusão de pedido' },
  ],
  currentStep: ORDER_FLOW_STEPS.SELECT_AREA,
  currentUser: {
    name: '',
    cnpj: '',
    email: '',
    companyName: '',
    isSpecialist: false,
    isGeneralist: false,
    isManager: false,
    isWhiteMartins: false,
    isAdmin: false,
  },
  ui: {
    menuOpenState: window.innerWidth <= 1224 ? false : true,
  },
  setCurrentUser: (user: any) =>
    set(
      produce((state) => {
        state.currentUser.name = user.name;
        state.currentUser.cnpj = user.cnpj;
        state.currentUser.email = user.email;
        state.currentUser.companyName = user.companyName;
        state.currentUser.isSpecialist = user.isSpecialist === '1';
        state.currentUser.shouldSignPrivacyTerm = user.shouldSignPrivacyTerm;
        state.currentUser.isGeneralist = user.isGeneralist === '1';
        state.currentUser.isManager = user.isManager === '1';
        state.currentUser.isWhiteMartins = user.isWhiteMartins === '1';
        state.currentUser.isAdmin = user.isAdmin === '1';
        state.currentUser.canSeeFinancialDataOnReport =
          user.isAdmin === '1' || user.isManager === '1';
        state.currentUser.canSeeFullOrdersTable =
          user.isAdmin === '1' || user.isWhiteMartins === '1';
        state.currentUser.canChooseEquipmentSubCateogry =
          user.isGeneralist === '0' || user.isGeneralist === undefined;
      })
    ),

  setCurrentAlertError: (err: any) => {
    set({ currentAlertError: err });
  },

  setMenuOpenState: (menuState: any) =>
    set(
      produce((state) => {
        state.ui.menuOpenState = menuState;
      })
    ),
  setSelectedCategory: (category: any, categoryId: any) => {
    set({ selectedCategory: category, selectedCategoryId: categoryId });
  },
  setBreadcrumb: (step: any) => {
    switch (step) {
      case ORDER_FLOW_STEPS.SELECT_AREA:
        set({
          breadcrumb: [
            { link: '/orders', label: 'Pedidos' },
            { link: '/order/newOrder', label: 'Inclusão de pedido' },
          ],
          currentStep: step,
        });
        break;
      case ORDER_FLOW_STEPS.SELECT_PATIENT:
        set({
          breadcrumb: [
            { link: '/orders', label: 'Pedidos' },
            { link: '/order/newOrder', label: 'Inclusão de pedido' },
            { link: '/order/selectPatient', label: 'Paciente' },
          ],
          currentStep: step,
        });
        break;
      case ORDER_FLOW_STEPS.SELECT_CATEGORY:
        set({
          breadcrumb: [
            { link: '/orders', label: 'Pedidos' },
            { link: '/order/newOrder', label: 'Inclusão de pedido' },
            { link: '/order/selectPatient', label: 'Paciente' },
            { link: '/order/selectCategory', label: 'Categoria' },
          ],
          currentStep: step,
        });
        break;
      case ORDER_FLOW_STEPS.SELECT_SUBCATEGORY:
        set({
          breadcrumb: [
            { link: '/orders', label: 'Pedidos' },
            { link: '/order/newOrder', label: 'Inclusão de pedido' },
            { link: '/order/selectPatient', label: 'Paciente' },
            { link: '/order/selectCategory', label: 'Categoria' },
            {
              link: `/order/selectSubCategory?category=${
                (get() as any).selectedCategory
              }&categoryId=${(get() as any).selectedCategoryId}`,
              label: (get() as any).selectedCategory,
            },
          ],
          currentStep: step,
        });
        break;
      case ORDER_FLOW_STEPS.CONFIGURE_EQUIPMENT:
        set({
          breadcrumb: [
            { link: '/orders', label: 'Pedidos' },
            { link: '/order/newOrder', label: 'Inclusão de pedido' },
            { link: '/order/selectPatient', label: 'Paciente' },
            { link: '/order/selectCategory', label: 'Categoria' },
            {
              link: `/order/selectSubCategory?category=${
                (get() as any).selectedCategory
              }&categoryId=${(get() as any).selectedCategoryId}`,
              label: (get() as any).selectedCategory,
            },
            { link: '/order/configureEquipment', label: 'Gerando seu pedido' },
          ],
          currentStep: step,
        });
        break;
      case ORDER_FLOW_STEPS.FINISH_ORDER:
        set({
          breadcrumb: [
            { link: '/orders', label: 'Pedidos' },
            { link: '/order/newOrder', label: 'Inclusão de pedido' },
            { link: '/order/selectPatient', label: 'Paciente' },
            { link: '/order/selectCategory', label: 'Categoria' },
            {
              link: `/order/selectSubCategory?category=${
                (get() as any).selectedCategory
              }&categoryId=${(get() as any).selectedCategoryId}`,
              label: (get() as any).selectedCategory,
            },
            { link: '/order/configureEquipment', label: 'Gerando seu pedido' },
            { link: '/order/finish', label: 'Resumo do pedido' },
          ],
          currentStep: step,
        });
        break;
      case ORDER_FLOW_STEPS.SELECT_PATIENT_ASSISTANCE:
        set({
          breadcrumb: [
            { link: '/orders', label: 'Pedidos' },
            { link: '/order/newOrder', label: 'Inclusão de pedido' },
            {
              link: '/technicalAssistance/assistanceToPatient?keepState=true',
              label: 'Paciente',
            },
          ],
          currentStep: step,
        });
        break;
      case ORDER_FLOW_STEPS.EQUIPMENT_ASSISTANCE:
        set({
          breadcrumb: [
            { link: '/orders', label: 'Pedidos' },
            { link: '/order/newOrder', label: 'Inclusão de pedido' },
            {
              link: '/technicalAssistance/assistanceToPatient?keepState=true',
              label: 'Paciente',
            },
            {
              link: '/technicalAssistance/assistance',
              label: 'Assistência técnica',
            },
          ],
          currentStep: step,
        });
        break;
      case ORDER_FLOW_STEPS.FINISH_ASSISTANCE_ORDER:
        set({
          breadcrumb: [
            { link: '/orders', label: 'Pedidos' },
            { link: '/order/newOrder', label: 'Inclusão de pedido' },
            {
              link: '/technicalAssistance/assistanceToPatient?keepState=true',
              label: 'Paciente',
            },
            {
              link: '/technicalAssistance/assistance',
              label: 'Assistência técnica',
            },
            {
              link: '/technicalAssistance/finishAssistanceOrder',
              label: 'Resumo do pedido',
            },
          ],
          currentStep: step,
        });
        break;
      case ORDER_FLOW_STEPS.SELECT_PATIENT_COLLECTION:
        set({
          breadcrumb: [
            { link: '/orders', label: 'Pedidos' },
            { link: '/order/newOrder', label: 'Inclusão de pedido' },
            {
              link: '/collectEquipments/collectFromPatient',
              label: 'Paciente',
            },
          ],
          currentStep: step,
        });
        break;
      case ORDER_FLOW_STEPS.COLLECT_EQUIPMENT:
        set({
          breadcrumb: [
            { link: '/orders', label: 'Pedidos' },
            { link: '/order/newOrder', label: 'Inclusão de pedido' },
            {
              link: '/collectEquipments/collectFromPatient',
              label: 'Paciente',
            },
            {
              link: '/collectEquipments/collectEquipments',
              label: 'Recolhimento',
            },
          ],
          currentStep: step,
        });
        break;
      case ORDER_FLOW_STEPS.FINISH_COLLECT_ORDER:
        set({
          breadcrumb: [
            { link: '/orders', label: 'Pedidos' },
            { link: '/order/newOrder', label: 'Inclusão de pedido' },
            {
              link: '/collectEquipments/collectFromPatient',
              label: 'Paciente',
            },
            {
              link: '/collectEquipments/collectEquipments',
              label: 'Recolhimento',
            },
            {
              link: '/collectEquipments/finishCollectEquipments',
              label: 'Resumo do pedido',
            },
          ],
          currentStep: step,
        });
        break;
      case ORDER_FLOW_STEPS.SELECT_PATIENT_RECHARGE:
        set({
          breadcrumb: [
            { link: '/orders', label: 'Pedidos' },
            { link: '/order/newOrder', label: 'Inclusão de pedido' },
            {
              link: '/recharge/rechargePatient?keepState=true',
              label: 'Recarga',
            },
          ],
          currentStep: step,
        });
        break;
      case ORDER_FLOW_STEPS.FINISH_RECHARGE_ORDER:
        set({
          breadcrumb: [
            { link: '/orders', label: 'Pedidos' },
            { link: '/order/newOrder', label: 'Inclusão de pedido' },
            {
              link: '/recharge/rechargePatient?keepState=true',
              label: 'Recarga',
            },
            { link: '/recharge/finishRecharge', label: 'Resumo do pedido' },
          ],
          currentStep: step,
        });
        break;
    }
  },
}));
