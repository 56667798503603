import { useEffect, useState } from 'react';
import { BsFilePdf } from 'react-icons/bs';
import styled from 'styled-components';
import { PrimaryTitleGray } from '../base/colors';
import { Spinner } from '../base/Spinners';
import { PublicLayout } from '../components/layout/publicLayout';
import { Panel } from '../components/panels/panel';
import { getDocument } from '../services/documentService';
import {
  downloadPDF,
  getLastFromSplit,
  getPdfDownloadUrl,
} from '../utils/utils';

const Cell = styled.td`
  padding: 5px;
`;

export const SharedLinkPage = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  useEffect(() => {
    (async () => {
      const resp = await getDocument(window.location.search);
      if (resp.message) {
        setLoading(false);
        setNotFound(true);
        return;
      }
      setData(resp);
      setLoading(false);
    })();
  }, []);

  const renderShared = () => {
    return loading ? (
      <Spinner></Spinner>
    ) : (
      <div style={{ textAlign: 'left' }}>
        {data.files.length > 0 ? <h3>Arquivos</h3> : <></>}
        {data.files.map((file, idx) => (
          <div key={idx}>
            <a
              onClick={() =>
                downloadPDF(getPdfDownloadUrl(file.product_code, file.guide))
              }
            >
              <BsFilePdf
                style={{ fontSize: '28px', cursor: 'pointer', color: 'red' }}
              ></BsFilePdf>
              {getLastFromSplit(file.guide, '_')}
            </a>
          </div>
        ))}
        {data.search.map((d, idx) =>
          (d.solutions ?? []).length > 0 ? (
            <div key={idx}>
              <h3>{d.name}</h3>
              <table>
                <thead>
                  <Cell style={{ fontWeight: '700' }}>Problema</Cell>
                  <Cell style={{ fontWeight: '700' }}>Causa</Cell>
                  <Cell style={{ fontWeight: '700' }}>Solução</Cell>
                </thead>
                <tbody>
                  {(d.solutions ?? []).map((solution, idx2) => (
                    <tr key={idx2}>
                      <Cell>{solution.issue}</Cell>
                      <Cell>{solution.cause}</Cell>
                      <Cell>{solution.howToProceed}</Cell>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <></>
          )
        )}
      </div>
    );
  };
  return (
    <PublicLayout
      outlet={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center',
            height: '400px',
          }}
        >
          <Panel
            style={{ padding: '20px', width: '800px', textAlign: 'center' }}
          >
            <h2 style={{ color: PrimaryTitleGray }}>Portal Homecare</h2>
            {notFound ? (
              <p>Nenhum compartilhamento encontrado.</p>
            ) : (
              renderShared()
            )}
          </Panel>
        </div>
      }
    ></PublicLayout>
  );
};
