import {
  CaptchaFailed,
  ConfirmationCodeCannotBeEmpty,
  PwordPolicy,
  TemporaryPwordExpired,
  UPassCannotBeEmpty,
  UsernameCannotBeEmpty,
  UsernameOrPwordInvalid,
  UserNotExist,
  UserShouldConfirmHisEmail,
  UserShouldRedefineHisPword,
} from '../base/errors';

export default class LoginErrorHandler {
  handle(error) {
    if (error.toString().indexOf('Password does not conform to policy:') >= 0) {
      return PwordPolicy;
    }
    switch (error.toString()) {
      case 'PasswordResetRequiredException: Password reset required for the user':
        return UserShouldRedefineHisPword;
      case 'InvalidParameterException: Cannot reset password for the user as there is no registered/verified email or phone_number':
        return UserShouldConfirmHisEmail;
      case 'UserNotFoundException: User does not exist.':
        return UserNotExist;
      case 'NotAuthorizedException: Incorrect username or password.':
        return UsernameOrPwordInvalid;
      case 'NotAuthorizedException: Temporary password has expired and must be reset by an administrator.':
        return TemporaryPwordExpired;
      case 'AuthError: Username cannot be empty':
        return UsernameCannotBeEmpty;
      case 'InvalidParameterException: Custom auth lambda trigger is not configured for the user pool.':
        return UPassCannotBeEmpty;
      case 'UserNotFoundException: Username/client id combination not found.':
        return UserNotExist;
      case 'AuthError: Confirmation code cannot be empty':
        return ConfirmationCodeCannotBeEmpty;
      case 'InvalidPasswordException: Password does not conform to policy: Password not long enough':
        return PwordPolicy;
      case 'UserLambdaValidationException: PreAuthentication failed with error Recaptcha verification failed.':
        return CaptchaFailed;
    }
  }
}
