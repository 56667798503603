import Amplify, { Auth } from 'aws-amplify';
import {
  ErrorNotReceivedMessageWrongLogin,
  UserShouldRedefineHisPword,
} from '../base/errors';
import { RecoveryCodeWasSent, ShouldVerifyEmail } from '../base/messages';
import LoginErrorHandler from './handleLoginErrors';
import { UserHelper } from '../helpers/UserHelper';
import { JWTHelper, refreshToken } from '../helpers/JWTHelper';

export const LoginService = new (class LoginService {
  user = null;
  constructor() {
    Amplify.configure({
      // OPTIONAL - if your API requires authentication
      Auth: {
        IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        region: process.env.REACT_APP_AMPLIFY_REGION,
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEBCLIENT_ID,
      },
      API: {
        endpoints: [
          {
            name: 'portalApi',
            endpoint: process.env.REACT_APP_API_URL_DOMAIN,
            custom_header: async () => {
              return {
                Authorization: `Bearer ${(await Auth.currentSession())
                  .getIdToken()
                  .getJwtToken()}`,
              };
            },
          },
        ],
      },
    });
  }
  async doLogin(email, password, recaptchaValue) {
    try {
      this.user = await Auth.signIn(email, password, {
        captcha: recaptchaValue,
      });
      if (this.user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        return {
          status: {
            code: 400,
            message: UserShouldRedefineHisPword,
          },
        };
      }
      this.user = await Auth.currentAuthenticatedUser({ bypassCache: true });
      if (!this.user.attributes.email_verified) {
        Auth.verifyCurrentUserAttribute('email');
        return {
          status: {
            code: 400,
            message: ShouldVerifyEmail,
          },
        };
      }
      return {
        status: {
          code: 200,
        },
        user: {
          username: this.user.username,
          name: this.user.attributes.name,
          cnpj: this.user.attributes['custom:cnpj'],
          isSpecialist: this.user.attributes['custom:isSpecialist'],
          shouldSignPrivacyTerm:
            this.user.attributes['custom:privacyTermSigned'] !== '1',
          isGeneralist: this.user.attributes['custom:isGeneralist'],
          isManager: this.user.attributes['custom:isManager'],
          isWhiteMartins: this.user.attributes['custom:isWhiteMartins'],
          isAdmin: this.user.attributes['custom:isAdmin'],
          token: this.user.signInUserSession.idToken.jwtToken,
          refreshToken: this.user.signInUserSession.refreshToken.token,
          companyName: this.user.attributes['custom:companyName'],
          email: this.user.attributes.email,
        },
      };
    } catch (error) {
      const errorMessage = new LoginErrorHandler().handle(error);
      return {
        status: {
          code: 400,
          message: errorMessage,
        },
      };
    }
  }

  async doAuth(email, password, keepConnected, recaptchaValue) {
    const resp = await this.doLogin(email, password, recaptchaValue);
    if (resp?.status?.code === 200) {
      let storage = sessionStorage;
      if (keepConnected) {
        storage = localStorage;
      }
      localStorage.setItem('keepConnected', keepConnected.toString());
      storage.setItem('portalHomeCareAuth', JSON.stringify(resp.user));
      return {
        logged: true,
        user: resp.user,
      };
    }
    return {
      logged: false,
      message: resp?.status?.message || ErrorNotReceivedMessageWrongLogin,
    };
  }

  async redefinePassword(newPassword, keepConnected) {
    try {
      this.user = await Auth.completeNewPassword(
        this.user, // the Cognito User Object
        newPassword // the new password
      );
      if (
        this.user?.challengeParam?.userAttributes?.email_verified !==
          undefined &&
        this.user?.challengeParam?.userAttributes?.email_verified.toString() ===
          'false'
      ) {
        Auth.verifyCurrentUserAttribute('email');
        return {
          logged: false,
          message: ShouldVerifyEmail,
        };
      }
      let storage = sessionStorage;
      if (keepConnected) {
        storage = localStorage;
      }

      const loggedUser = {
        logged: true,
        user: {
          name: this.user.attributes.name,
          cnpj: this.user.attributes['custom:cnpj'],
          isSpecialist: this.user.attributes['custom:isSpecialist'],
          shouldSignPrivacyTerm:
            this.user.attributes['custom:privacyTermSigned'] !== '1',
          isGeneralist: this.user.attributes['custom:isGeneralist'],
          isManager: this.user.attributes['custom:isManager'],
          isWhiteMartins: this.user.attributes['custom:isWhiteMartins'],
          isAdmin: this.user.attributes['custom:isAdmin'],
          token: this.user.signInUserSession.idToken.jwtToken,
          refreshToken: this.user.signInUserSession.refreshToken.token,
          companyName: this.user.attributes['custom:companyName'],
          email: this.user.attributes.email,
        },
      };
      storage.setItem('portalHomeCareAuth', JSON.stringify(loggedUser.user));
      return loggedUser;
    } catch (error) {
      return {
        logged: false,
        message: new LoginErrorHandler().handle(error),
      };
    }
  }

  async verifyEmail(verificationCode, keepConnected) {
    try {
      await Auth.verifyCurrentUserAttributeSubmit('email', verificationCode);
      const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
      const loggedUser = {
        logged: true,
        user: {
          name: user.attributes.name,
          cnpj: user.attributes['custom:cnpj'],
          isSpecialist: this.user.attributes['custom:isSpecialist'],
          shouldSignPrivacyTerm:
            this.user.attributes['custom:privacyTermSigned'] !== '1',
          isGeneralist: this.user.attributes['custom:isGeneralist'],
          isManager: this.user.attributes['custom:isManager'],
          isWhiteMartins: this.user.attributes['custom:isWhiteMartins'],
          isAdmin: this.user.attributes['custom:isAdmin'],
          token: this.user.signInUserSession.idToken.jwtToken,
          refreshToken: this.user.signInUserSession.refreshToken.token,
          companyName: user.attributes['custom:companyName'],
          email: user.attributes.email,
        },
      };
      let storage = sessionStorage;
      if (keepConnected) {
        storage = localStorage;
      }
      storage.setItem('portalHomeCareAuth', JSON.stringify(loggedUser.user));
      return loggedUser;
    } catch (error) {
      return {
        logged: false,
        message: new LoginErrorHandler().handle(error),
      };
    }
  }
  async checkSession(currentUser){
    try{
      const session = await Auth.currentSession()
      let storage = localStorage
      currentUser.token = session.idToken.jwtToken
      storage.setItem('portalHomeCareAuth', JSON.stringify(currentUser));
      return true
    }catch (err){
      console.log(err)
      return false
    }
  }
  isLogged() {
    const currentUser = UserHelper.getCurrentUser();
    const isTokenExpired = JWTHelper.isExpired(currentUser?.token);
    const keepConnected = localStorage.getItem('keepConnected') === 'true';

    if (!isTokenExpired) {
      return true;
    }

    if (keepConnected) {
      return this.checkSession(currentUser)
    }

    return false;
  }
  currentUser() {
    const auth = JSON.parse(
      sessionStorage.getItem('portalHomeCareAuth') || '{}'
    );

    if (auth.name) {
      return auth;
    }
    const authLocalStorage = JSON.parse(
      localStorage.getItem('portalHomeCareAuth') || '{}'
    );
    if (authLocalStorage.name) {
      return authLocalStorage;
    }
    return {
      logged: false,
      name: '',
      cnpj: '',
      email: '',
      companyName: '',
    };
  }
  setPrivacyTermAccepted() {
    const auth = JSON.parse(
      sessionStorage.getItem('portalHomeCareAuth') || '{}'
    );

    if (auth.name) {
      auth.shouldSignPrivacyTerm = false;
      sessionStorage.setItem('portalHomeCareAuth', JSON.stringify(auth));
      return auth;
    }
    const authLocalStorage = JSON.parse(
      localStorage.getItem('portalHomeCareAuth') || '{}'
    );
    if (authLocalStorage.name) {
      authLocalStorage.shouldSignPrivacyTerm = false;
      localStorage.setItem(
        'portalHomeCareAuth',
        JSON.stringify(authLocalStorage)
      );
      return authLocalStorage;
    }
    return {
      logged: false,
      name: '',
      cnpj: '',
      email: '',
      companyName: '',
    };
  }
  async forgotPassword(email) {
    try {
      await Auth.forgotPassword(email);
      return {
        logged: false,
        sentLink: true,
        message: RecoveryCodeWasSent(email),
      };
    } catch (error) {
      return {
        logged: false,
        message: new LoginErrorHandler().handle(error),
      };
    }
  }

  async recoveryPassword(email, newPassword, verificationCode) {
    try {
      await Auth.forgotPasswordSubmit(email, verificationCode, newPassword);
      alert('Sua senha foi alterada com sucesso');
      this.cleanAndRefresh();
    } catch (error) {
      const errorMessage = new LoginErrorHandler().handle(error);
      return {
        logged: false,
        message: errorMessage,
      };
    }
  }
  cleanAndRefresh() {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = window.location.origin;
  }
  async signOut() {
    try {
      await Auth.signOut();
      this.cleanAndRefresh();
      localStorage.clear();
      sessionStorage.clear();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }
})();
