import { styled } from '@mui/material/styles';
import { Select as MUISelect } from '@mui/material';

export const Select = styled(MUISelect)(({ theme }) => ({
  background: theme.palette.common.white,

  '& fieldset': {
    border: `2px solid ${theme.colors.input.border}`,
    borderRadius: '8px',
  },

  '& svg': {
    color: theme.colors.input.svg,
  },

  '&.Mui-disabled': {
    color: theme.colors.input.disabled + ' !important',

    '& fieldset': {
      borderColor: `${theme.colors.input.disabled} !important`,
    },

    '& svg': {
      color: theme.colors.input.disabled,
    },
  },
}));
