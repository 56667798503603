import styled from 'styled-components';

export const GridBox = styled.div`
  display: block;
`;

export const TinyTile = styled.div`
  flex: 1;
  box-sizing: border-box;
  margin: ${(props) => props.margin};
`;

export const Row = styled.div`
  display: flex;
  // flex-wrap: wrap;
`;

export const SmallTile = styled.div`
  flex: 1;
  box-sizing: border-box;
  margin: ${(props) => props.margin};
`;

export const MediumTile = styled.div`
  flex: 1;
  box-sizing: border-box;
  margin: ${(props) => props.margin};
`;

export const LargeTile = styled.div`
  flex: 1;
  box-sizing: border-box;
  margin: ${(props) => props.margin};
`;
